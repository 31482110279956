import styled from 'styled-components';
import { Typography } from '../../theme';

export const Modal = styled.div`
  .modal-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .description {
      ${Typography.body_large}
      line-height: 22px;
      text-align: center;
      margin: 18px;
    }
  }

  .button-modal {
    display: flex;
    gap: 20px;
  }
`;
