import styled from 'styled-components';
import { Spacings, Colors, Typography } from '../../theme';

export const Pagination = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
`;

export const PaginationSize = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const SelectPageSize = styled.select`
  width: 4rem;
  height: 3rem;
  padding: 4px;
  background: white;
  color: ${Colors.neutral_600};
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px ${Colors.neutral_300};

  option {
    background: white;
  }
`;
