import styled from 'styled-components';
import { InfraButton } from '@infralabs/design-system';
import {
  Spacings,
  Colors,
  Shadows,
  Typography as Ty,
  Typography,
} from '../../../../../theme';

export const Container = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
  margin-top: ${Spacings.spacing_32};
  .input-label {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    line-height: 1.125rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding-right: 0.125rem;
    color: #000000;
    padding: 0.188rem;
  }
  .input-error {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
    color: #e11d2b;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: ${Spacings.spacing_14};
    font-family: ${Ty.h1.fontFamily};
    padding-right: ${Spacings.spacing_2};
    color: ${Colors.shade_100};
    margin-bottom: ${Spacings.spacing_5};
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

export const Button = styled(InfraButton)`
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;

export const Modal = styled.div`
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
    }
    .description {
      ${Typography.body_large}
      line-height: 22px;
      text-align: center;
      width: 180px;
      margin: 30px;
    }
  }

  .button-modal {
    display: flex;
    gap: 20px;
  }
`;

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
  margin: ${Spacings.spacing_32} 0 ${Spacings.spacing_32};
  p {
    margin: 0;
  }

  .header {
    margin: 0 0 33px 0;
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      ${Typography.h2}
      color: ${Colors.neutral_600};
      margin: 0;
    }
    .back-page {
      cursor: pointer;
      display: flex;
      align-items: center;
      .text {
        margin: 0 0 0 9px;
        ${Typography.body_small}
        color: ${Colors.information_100};
        font-weight: 700;
      }
    }
  }
  .buttons_subtitles {
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .inputs-page {
    .input-label {
      margin-bottom: 0.25rem;
      font-size: 1rem;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      line-height: 1.125rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      padding-right: 0.125rem;
      color: #000000;
      padding: 0.188rem;
    }
    .input-error {
      font-size: 0.75rem;
      line-height: 1.125rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
      color: #e11d2b;
    }
  }
  .table {
    margin: 10px 0 0 0;
  }
  .container_adictional_config {
    margin: 30px 0 0 0;
  }
`;
