import { useEffect, useState } from 'react';
import {
  InfraStepper,
  InfraGrid,
  InfraCol,
  InfraButton,
  InfraTextField,
  InfraSearchTextField,
  InfraIcons,
} from '@infralabs/design-system';
import { useHistory } from 'react-router-dom';
import * as style from './style';
import { Colors } from '../../theme';
import OrderingByCategory from './OrderingByCategory';
import OrderingByBrand from './OrderingByBrand';

export default function skuOrderingDetails({ locationUrl = '' }) {
  const [step, setStep] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    if (locationUrl) {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  const steppers = [
    {
      label: 'Ordenação por Categoria',
      icon: 'CirclesThreePlus',
    },
    {
      label: 'Ordenação por Marca',
      icon: 'StarHalf',
    },
  ];

  const onSelectedStepper = (index: number) => {
    setStep(index);
  };

  const backTolastPage = () => {
    history.goBack();
  };

  const renderContentSteps = () => {
    switch (step) {
      case 0: {
        return <OrderingByCategory />;
      }
      case 1: {
        return <OrderingByBrand />;
      }
    }
  };

  return (
    <style.Wrapper>
      <InfraGrid className="header">
        <InfraCol xxl="12" xl="12">
          <div>
            <h1 className="title">Detalhes de Ordenação de SKU</h1>
            <div className="back-page" onClick={backTolastPage}>
              <InfraIcons
                name="ArrowUUpLeft"
                weight="bold"
                color={Colors.information_100}
                size={16}
              />
              <p className="text">Voltar</p>
            </div>
          </div>
        </InfraCol>
      </InfraGrid>
      <InfraGrid>
        <InfraCol xxl="12" xl="12" className="content">
          <InfraStepper
            activeIndex={step}
            model={steppers}
            onSelected={onSelectedStepper}
          />
          <div className="content-steps">{renderContentSteps()}</div>
        </InfraCol>
      </InfraGrid>
    </style.Wrapper>
  );
}
