import styled from 'styled-components';
import { InfraButton } from '@infralabs/design-system';
import { Spacings, Shadows, Colors, Typography as Ty } from '../../../theme';

export const Container = styled.main`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2.5rem 0rem;
`;

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
`;

export const Button = styled(InfraButton)`
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;
