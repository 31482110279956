import {
  InfraButton,
  InfraCol,
  InfraGrid,
  InfraIcons,
  InfraModal,
  InfraSelect,
  InfraTable,
  InfraTableColumn,
  InfraTextField,
  InfraTextarea,
  InfraTypography,
  InfraUpload,
} from '@infralabs/design-system';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { kebabcaseForFileName } from '../../../../../../Helper/kebabcase';
import {
  deleteBannerMediaPageUploadFileService,
  postBannerMediaPageUploadFileService,
} from '../../../../../../Services/Uploads';
import Accordion, {
  AddicionalSettingsTableDataProps,
} from '../../../../../../components/AdditionalSettings';
import { usePage } from '../../../../../../hooks/usePage';
import { Colors } from '../../../../../../theme';
import * as S from './style';

import { CommonAccordion } from '../../../../../../components';

import { ButtonsMedia } from './ButtonsMedia';

type FileProps = {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

type UploadedFileProps = {
  name: string;
  progress: number;
  type: string;
  uploaded: boolean;
  error: boolean;
  showClose: boolean;
  fileId?: string;
};

export type InfoResponseRequestFileProps = {
  fileId: string;
  canonical: string;
  context: string;
  uri: string;
  metadata: {
    cacheControl: string;
    contentLanguage: string;
    contentType: string;
  };
  createdAt: string;
  updatedAt: string;
};

type modalProps = {
  type: 'success' | 'error';
  status: boolean;
  message: string;
};

type ButtonsMediaType = {
  text: string;
  href: string;
  target: string;
};

export type DataMedia = {
  imageUrl: string | null;
  href: string | null;
  target: string | null;
  videoUrl: string | null;
  title: string | null;
  text: string | null;
  content: string | null;
  configs: AddicionalSettingsTableDataProps[];
  buttons: ButtonsMediaType[] | null;
  fileImage: UploadedFileProps[];
  fileVideo: UploadedFileProps[];
  order?: number;
};

interface AddBannerMediaParams {
  order: string;
  mediaIndex: string;
}

export function AddBannerMedia() {
  const { order, mediaIndex } = useParams<AddBannerMediaParams>();
  const history = useHistory();
  const location = useLocation();
  const { bannerState, setBannerState, payload, setPayload, position } =
    usePage();

  const [additionalConfigurationTable, setAdditionalConfigurationTable] =
    useState([]);

  const [fileImage, setFileImage] = useState<FileProps[]>([]);
  const [uploadedFileListImage, setUploadedFileListImage] = useState<
    UploadedFileProps[]
  >(() => {
    const media = bannerState.medias[mediaIndex];
    if (media) {
      if (media.imageUrl) {
        const fileId = new URL(media.imageUrl).searchParams.get('id');
        return [
          {
            name: media.imageUrl,
            progress: 100,
            type: 'image',
            uploaded: true,
            error: false,
            showClose: true,
            fileId,
          },
        ];
      }
    }
    return [];
  });
  const [infoImage, setInfoImage] = useState<InfoResponseRequestFileProps>();
  const [loadingStatusImage, setLoadingStatusImage] = useState(false);
  const [disabledUploadImage, setDisabledUploadImage] = useState(false);
  const [buttonStatusImage, setButtonStatusImage] = useState(false);
  const [fileVideo, setFileVideo] = useState<FileProps[]>([]);
  const [uploadedFileListVideo, setUploadedFileListVideo] = useState<
    UploadedFileProps[]
  >(() => {
    const { indexEdit } = location.state;
    const media = bannerState.medias[mediaIndex];
    if (media) {
      if (media.videoUrl) {
        const fileId = new URL(media.videoUrl).searchParams.get('id');
        return [
          {
            name: media.videoUrl,
            progress: 100,
            type: 'video',
            uploaded: true,
            error: false,
            showClose: true,
            fileId,
          },
        ];
      }
    }
    return [];
  });
  const [infoVideo, setInfoVideo] = useState<InfoResponseRequestFileProps>();
  const [loadingStatusVideo, setLoadingStatusVideo] = useState(false);
  const [disabledUploadVideo, setDisabledUploadVideo] = useState(false);
  const [buttonStatusVideo, setButtonStatusVideo] = useState(false);
  const [showModalSuccessAndError, setShowModalSuccessAndError] =
    useState<modalProps>({
      type: 'success',
      status: false,
      message: '',
    });

  const [showModalButtonsMidia, setShowModalButtonsMidia] = useState(false);
  const [buttonsMediaList, setButtonsMediaList] = useState([]);
  const [buttonDataEdit, setButtonDataEdit] = useState();
  const [buttonEditIndex, setButtonEditIndex] = useState<number>();

  const bodyRequest: Blob | any = {
    canonical: '',
    context: 'content',
    contentType: '',
    contentLanguage: 'en-us',
    cacheControl: 'no-cache',
    file: '',
  };

  const mockItemsSelect = [
    {
      label: '_self',
      value: '_self',
    },
    {
      label: '_blank',
      value: '_blank',
    },
    {
      label: '_parent',
      value: '_parent',
    },
    {
      label: '_top',
      value: '_top',
    },
  ];

  const formRef = useRef(null);

  const validations = Yup.object({
    title: Yup.string()
      .max(
        50,
        'O tamanho máximo do Título, deve ser de 50 caracteres, incluindo espaços.',
      )
      .required('O Título deve ser especificado.'),
    href: Yup.string().max(
      500,
      'O tamanho máximo do Link, deve ser de 500 caracteres, incluindo espaços.',
    ),
    target: Yup.string().when('href', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().required('O Target da mídia deve ser especificado.'),
    }),
    text: Yup.string().max(
      500,
      'O tamanho máximo do Texto Livre, deve ser de 500 caracteres, incluindo espaços',
    ),
  });

  const validationMoreOneFieldFilledWithTitle = ({
    imageUrl,
    videoUrl,
    text,
    title,
    content,
  }) => {
    if (title) {
      const array = [];
      array.push(imageUrl, videoUrl, text, content);
      const findValue = array.find(value => value !== null);
      if (!findValue) {
        return 'Favor preencher pelo menos um entre os 4 campos da mídia: Vídeo, Imagem, Texto Livre, HTML.';
      }
    }
    return false;
  };

  useEffect(() => {
    if (bannerState.medias[mediaIndex] !== undefined) {
      const { buttons } = bannerState.medias[mediaIndex];

      if (buttons?.length > 0) {
        setButtonsMediaList(buttons);
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      title: '',
      href: '',
      target: '',
      text: '',
      content: '',
    },
    validationSchema: validations,
    onSubmit: ({ title, content, href, target, text }) => {
      const imageFileAndInfo =
        uploadedFileListImage?.length > 0
          ? [
              {
                ...uploadedFileListImage[0],
                fileId: infoImage?.fileId
                  ? infoImage?.fileId
                  : uploadedFileListImage[0].fileId,
              },
            ]
          : [];
      const videoFileAndInfo =
        uploadedFileListVideo?.length > 0
          ? [
              {
                ...uploadedFileListVideo[0],
                fileId: infoVideo?.fileId
                  ? infoVideo?.fileId
                  : uploadedFileListVideo[0].fileId,
              },
            ]
          : [];

      const data: DataMedia = {
        imageUrl: infoImage && infoImage.uri ? infoImage.uri : null,
        href: href || null,
        target,
        videoUrl: infoVideo && infoVideo.uri ? infoVideo.uri : null,
        title,
        text: text || null,
        content: content || null,
        configs:
          additionalConfigurationTable.length > 0
            ? additionalConfigurationTable
            : [],
        buttons: buttonsMediaList,
        fileImage: imageFileAndInfo,
        fileVideo: videoFileAndInfo,
      };

      if (data.href === null) data.target = null;

      const hasMoreOneFieldFilledWithTitle =
        validationMoreOneFieldFilledWithTitle(data);
      delete data.fileImage;
      delete data.fileVideo;
      if (hasMoreOneFieldFilledWithTitle) {
        handleModalSuccessAndError({
          type: 'error',
          status: true,
          message: hasMoreOneFieldFilledWithTitle,
        });
      } else {
        formik.resetForm({
          values: { title: '', content: '', href: '', target: '', text: '' },
        });

        if (location.state) {
          const { indexEdit, isEdit, ...state } = location.state;
          const tableBanners = bannerState.medias;
          if (tableBanners.length > 0 && isEdit) {
            const addWithOrder = {
              ...data,
              order: tableBanners[indexEdit].order,
            };
            tableBanners[indexEdit] = addWithOrder;

            setBannerState({
              name: bannerState.name,
              bannerType: bannerState.bannerType,
              medias: tableBanners,
              configs: bannerState.configs,
              dateStart: bannerState.dateStart,
              dateEnd: bannerState.dateEnd,
            });
            history.goBack();
          } else {
            const { medias } = bannerState;
            data.order = 0;
            medias.push(data);
            setBannerState({
              name: bannerState.name,
              bannerType: bannerState.bannerType,
              medias,
              configs: bannerState.configs,
              dateStart: bannerState.dateStart,
              dateEnd: bannerState.dateEnd,
            });
            history.goBack();
          }
        } else {
          history.goBack();
        }
      }
    },
  });

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const backToLastPage = () => {
    if (location.state) {
      const { isEdit, tableBanners, ...state } = location.state;

      if (tableBanners.length > 0 && isEdit) {
        history.goBack();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const setInputStyle = (value: string, type: string) => {
    if (
      (value?.length >= 50 && type === 'title') ||
      (value?.length === 0 && type === 'title' && formik.touched.title)
    ) {
      return 'error';
    }
    if (value?.length >= 500 && type === 'href') {
      return 'error';
    }
    return 'normal';
  };

  const handleDeleteUploadFile = (type: string) => {
    if (type.toUpperCase() === 'IMAGE') {
      fetchDelete(type, false);
    } else {
      fetchDelete(type, false);
    }
  };

  const disabledButtonsAndLoadingToFalse = (type: string) => {
    const typeUpperCase = type.toUpperCase();

    typeUpperCase === 'IMAGE'
      ? setLoadingStatusImage(false)
      : setLoadingStatusVideo(false);
    typeUpperCase === 'IMAGE'
      ? setButtonStatusImage(false)
      : setButtonStatusVideo(false);
  };

  const appendOnFormDateValues = (type: string) => {
    const typeUpperCase = type.toUpperCase();
    const formData = new FormData();
    bodyRequest.contentType =
      typeUpperCase === 'IMAGE' ? fileImage[0].type : fileVideo[0].type;
    bodyRequest.file = typeUpperCase === 'IMAGE' ? fileImage[0] : fileVideo[0];

    fileImage[0]
      ? formData.append('canonical', kebabcaseForFileName(fileImage[0].name))
      : formData.append('canonical', kebabcaseForFileName(fileVideo[0].name));
    formData.append('context', bodyRequest.context);
    formData.append('contentType', bodyRequest.contentType);
    formData.append('contentLanguage', bodyRequest.contentLanguage);
    formData.append('cacheControl', bodyRequest.cacheControl);
    formData.append('file', bodyRequest.file);

    return formData;
  };

  const handleModalSuccessAndError = ({
    type,
    message,
    status,
  }: modalProps) => {
    setShowModalSuccessAndError({
      type,
      status,
      message,
    });
  };

  const fetchPost = async (type: string) => {
    if (type.toUpperCase() === 'IMAGE') {
      setLoadingStatusImage(true);
      const dataToRequest = appendOnFormDateValues(type);
      try {
        const { data, statusCode, message } =
          await postBannerMediaPageUploadFileService(dataToRequest);
        if (statusCode && statusCode !== 201) {
          disabledButtonsAndLoadingToFalse('image');
          handleModalSuccessAndError({
            type: 'error',
            status: true,
            message: `Error: ${message}.\n Tente anexar novamente o arquivo imagem.`,
          });
        } else {
          disabledButtonsAndLoadingToFalse('image');
          setInfoImage(data);
          setDisabledUploadImage(true);
          handleModalSuccessAndError({
            type: 'success',
            status: true,
            message: 'Imagem anexada com sucesso!!!',
          });
        }
      } catch (error) {
        disabledButtonsAndLoadingToFalse('image');
        setUploadedFileListImage([]);
      }
    } else {
      setLoadingStatusVideo(true);
      const dataRequest = appendOnFormDateValues(type);
      try {
        const { data, statusCode, message } =
          await postBannerMediaPageUploadFileService(dataRequest);

        if (statusCode && statusCode !== 201) {
          disabledButtonsAndLoadingToFalse('video');
          handleModalSuccessAndError({
            type: 'error',
            status: true,
            message: `Error: ${message}.\nTente anexar novamente o arquivo vídeo.`,
          });
        } else {
          disabledButtonsAndLoadingToFalse('video');
          setInfoVideo(data);
          setDisabledUploadVideo(true);
          handleModalSuccessAndError({
            type: 'success',
            status: true,
            message: 'Vídeo anexado com sucesso!!!',
          });
        }
      } catch (error) {
        disabledButtonsAndLoadingToFalse('video');
        setUploadedFileListVideo([]);
      }
    }
  };

  const handleUpdatePayload = (type: 'imageUrl' | 'videoUrl') => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;

    switch (position) {
      case 'header':
        if (order && mediaIndex) {
          sectionsHeader[+order].banners[0].medias[+mediaIndex][type] = null;
        }
        break;
      case 'body':
        if (order && mediaIndex) {
          sectionsBody[+order].banners[0].medias[+mediaIndex][type] = null;
        }
        break;
      case 'footer':
        if (order && mediaIndex) {
          sectionsFooter[+order].banners[0].medias[+mediaIndex][type] = null;
        }
        break;

      default:
        break;
    }

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });
  };

  const fetchDelete = (type: string, fileWasRejected: boolean) => {
    if (type.toUpperCase() === 'IMAGE') {
      setLoadingStatusImage(true);
      setUploadedFileListImage([]);
      setDisabledUploadImage(false);
      if (infoImage || uploadedFileListImage[0].fileId)
        deleteBannerMediaPageUploadFileService(
          infoImage?.fileId
            ? infoImage?.fileId
            : uploadedFileListImage[0].fileId,
        );
      setInfoImage(undefined);
      fileWasRejected
        ? setLoadingStatusImage(false)
        : disabledButtonsAndLoadingToFalse('image');

      handleUpdatePayload('imageUrl');
    }

    if (type.toUpperCase() === 'VIDEO') {
      setLoadingStatusVideo(true);
      setUploadedFileListVideo([]);
      setDisabledUploadVideo(false);
      if (infoVideo || uploadedFileListVideo[0].fileId)
        deleteBannerMediaPageUploadFileService(
          infoVideo?.fileId
            ? infoVideo?.fileId
            : uploadedFileListVideo[0].fileId,
        );
      setInfoVideo(undefined);
      fileWasRejected
        ? setLoadingStatusVideo(false)
        : disabledButtonsAndLoadingToFalse('video');

      handleUpdatePayload('videoUrl');
    }
  };

  const handleSendFiles = (type: string, methodRequest: string) => {
    if (
      type.toUpperCase() === 'IMAGE' &&
      methodRequest.toUpperCase() === 'POST'
    ) {
      fetchPost(type);
    }

    if (
      type.toUpperCase() === 'VIDEO' &&
      methodRequest.toUpperCase() === 'POST'
    ) {
      fetchPost(type);
    }
  };

  const uploadFileImage = (filesLoaded: FileProps[]) => {
    if (!infoImage) {
      setFileImage(filesLoaded);
      const uploadedList: UploadedFileProps = {
        name: filesLoaded[0].name,
        progress: 100,
        type: filesLoaded[0].type,
        uploaded: true,
        error: false,
        showClose: true,
      };
      setUploadedFileListImage([uploadedList]);
      setButtonStatusImage(false);
      setDisabledUploadImage(false);
    } else {
      handleModalSuccessAndError({
        type: 'error',
        status: true,
        message: 'Você já anexou uma imagem, remova-a para anexar outra.',
      });
    }
  };

  const uploadFileVideo = (filesLoaded: FileProps[]) => {
    if (!infoVideo) {
      setFileVideo(filesLoaded);
      const uploadedList: UploadedFileProps = {
        name: filesLoaded[0].name,
        progress: 100,
        type: filesLoaded[0].type,
        uploaded: true,
        error: false,
        showClose: true,
      };
      setUploadedFileListVideo([uploadedList]);
      setButtonStatusVideo(true);
      setDisabledUploadVideo(false);
    } else {
      handleModalSuccessAndError({
        type: 'error',
        status: true,
        message: 'Você já anexou um video, remova-o para anexar outro.',
      });
    }
  };

  const filesRejected = (type: string) => {
    if (type.toUpperCase() === 'IMAGE') {
      setButtonStatusImage(true);
      if (infoImage) {
        fetchDelete(type, true);
      } else {
        setInfoImage(undefined);
        setUploadedFileListImage([]);
      }
    }

    if (type.toUpperCase() === 'VIDEO') {
      setButtonStatusVideo(true);
      if (infoVideo) {
        fetchDelete(type, true);
      } else {
        setInfoVideo(undefined);
        setUploadedFileListVideo([]);
      }
    }
  };

  const setFormikValue = (value: string, input: string) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  useEffect(() => {
    if (formik.values.href?.length === 0) {
      formik.values.target = '';
    }
  }, [formik.values.href, formik.values.target]);

  useEffect(() => {
    if (location?.state) {
      // take values from state
      const { indexEdit, isEdit } = location.state;
      const tableBanners = bannerState.medias;

      if (tableBanners.length > 0 && isEdit) {
        // add values came from banner on formik
        formik.values.text = tableBanners[indexEdit].text || '';
        formik.values.content = tableBanners[indexEdit].content || '';
        formik.values.title = tableBanners[indexEdit].title || '';
        formik.values.target = tableBanners[indexEdit].target || '';
        formik.values.href = tableBanners[indexEdit].href || '';

        // check if the image is already uploaded
        if (tableBanners[indexEdit].fileImage)
          setUploadedFileListImage(tableBanners[indexEdit].fileImage);

        if (tableBanners[indexEdit].imageUrl)
          setInfoImage({
            ...infoImage,
            uri: tableBanners[indexEdit].imageUrl,
          });

        // check if the video is already uploaded
        if (tableBanners[indexEdit].fileVideo)
          setUploadedFileListVideo(tableBanners[indexEdit].fileVideo);

        if (tableBanners[indexEdit].videoUrl)
          setInfoVideo({ ...infoVideo, uri: tableBanners[indexEdit].videoUrl });

        // check if the configs is already
        if (tableBanners[indexEdit].configs)
          setAdditionalConfigurationTable(tableBanners[indexEdit].configs);
      }
    }
  }, []);

  const buttonEdit = value => {
    const index = buttonsMediaList.findIndex(
      (data: AddicionalSettingsTableDataProps) => data === value,
    );
    if (index >= 0) {
      setButtonEditIndex(index);
    }
    setButtonDataEdit(value);
    setShowModalButtonsMidia(true);
  };

  const renderEditIcon = (value: {
    href: string;
    target: string;
    text: string;
  }) => {
    return (
      <div style={{ padding: '2px' }} onClick={() => buttonEdit(value)}>
        <InfraIcons name="PencilSimple" size={14} />
      </div>
    );
  };

  const addButton = () => {
    setShowModalButtonsMidia(true);
    setButtonEditIndex(undefined);
  };

  const renderDeleteIcon = (value: {
    href: string;
    target: string;
    text: string;
  }) => {
    return (
      <InfraIcons name="Trash" size={15} onClick={() => deleteButton(value)} />
    );
  };

  const deleteButton = value => {
    const index = buttonsMediaList.findIndex(
      (data: AddicionalSettingsTableDataProps) => data === value,
    );
    if (index >= 0) {
      const newButtons = buttonsMediaList.filter((item, i) => i !== index);

      setButtonsMediaList(newButtons);
    }
  };

  return (
    <>
      <S.Modal>
        <InfraModal
          theme="light"
          show={
            showModalSuccessAndError.status &&
            showModalSuccessAndError.type === 'success'
          }
          onClose={() =>
            handleModalSuccessAndError({
              type: 'success',
              status: false,
              message: showModalSuccessAndError.message,
            })
          }
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">{showModalSuccessAndError.message}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() =>
              handleModalSuccessAndError({
                type: 'success',
                status: false,
                message: showModalSuccessAndError.message,
              })
            }
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={
            showModalSuccessAndError.status &&
            showModalSuccessAndError.type === 'error'
          }
          onClose={() =>
            handleModalSuccessAndError({
              type: 'error',
              status: false,
              message: showModalSuccessAndError.message,
            })
          }
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{showModalSuccessAndError.message}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() =>
              handleModalSuccessAndError({
                type: 'error',
                status: false,
                message: showModalSuccessAndError.message,
              })
            }
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>

      <ButtonsMedia
        showModalButtonsMidia={showModalButtonsMidia}
        setShowModalButtonsMidia={setShowModalButtonsMidia}
        buttonsMediaList={buttonsMediaList}
        setButtonsMediaList={setButtonsMediaList}
        buttonDataEdit={buttonDataEdit}
        editIndex={buttonEditIndex}
      />

      <S.Header>
        <InfraTypography variant="h2" weight="bold">
          Cadastro de Mídia
        </InfraTypography>

        <S.ButtonBack>
          <S.Icon name="ArrowUUpLeft" size={16} onClick={backToLastPage} />
          <p onClick={backToLastPage}>Voltar</p>
        </S.ButtonBack>
      </S.Header>

      <S.Wrapper>
        <InfraGrid style={{ paddingInline: 0 }}>
          <InfraCol xxl="12" xl="12" lg="8" md="8" xs="4">
            <form onSubmit={save} ref={formRef}>
              <InfraGrid>
                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <InfraTextField
                    placeholder="Seu texto"
                    name="title"
                    label="Título"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputStyle={setInputStyle(formik.values.title, 'title')}
                    message={formik.errors.title}
                  />
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <InfraTextField
                    placeholder="Seu texto"
                    name="href"
                    label="Link"
                    value={formik.values.href}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputStyle={setInputStyle(formik.values.href, 'href')}
                    message={formik.errors.href}
                  />
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <div className="label-input">
                    <label>Target</label>
                    <InfraSelect
                      name="target"
                      placeholder={formik.values.target || 'Selecione'}
                      items={mockItemsSelect}
                      handlerClick={event =>
                        setFormikValue(event.value, 'target')
                      }
                      disable={formik.values.href?.length === 0}
                    />
                    <span className="error-target">{formik.errors.target}</span>
                  </div>
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <S.Button
                    size="large"
                    type="submit"
                    icon="Check"
                    iconRight
                    fullWidth
                    disabled={
                      !(
                        Object.keys(formik.errors).length === 0 &&
                        formik.errors.constructor === Object
                      ) ||
                      loadingStatusImage ||
                      loadingStatusVideo ||
                      buttonStatusImage ||
                      buttonStatusVideo
                    }
                  >
                    Salvar
                  </S.Button>
                </InfraCol>

                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="2">
                  <div className="label-input">
                    <label>Texto Livre</label>
                    <InfraTextarea
                      placeholder="Digite seu texto aqui..."
                      name="text"
                      value={formik.values.text}
                      onChange={formik.handleChange}
                      message={formik.errors.text}
                      setMessageError={!!formik.errors.text}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </InfraCol>
                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="2">
                  <div className="label-input">
                    <label>HTML</label>
                    <InfraTextarea
                      placeholder="Digite seu HTML aqui..."
                      name="content"
                      value={formik.values.content}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      setMessageError={!!formik.errors.content}
                      message={formik.errors.content}
                    />
                  </div>
                </InfraCol>
              </InfraGrid>
            </form>
          </InfraCol>

          <InfraCol xxl="12" xl="12" lg="8" md="8" xs="4">
            <InfraGrid>
              <InfraCol xxl="6" xl="4" lg="4" md="4" xs="2">
                <InfraUpload
                  direction="horizontal"
                  uploadMessageDefault="Upload da Imagem"
                  uploadMessageSuccess="Solte seu arquivo aqui"
                  uploadMessageReject="Arquivo não suportado"
                  uploadedList={uploadedFileListImage}
                  uploadMessageRejectMaxFiles="Excedeu a quantidade máxima de arquivos."
                  uploadMessageRejectTypeInvalid="Tipo de arquivo inválido"
                  description="Apenas 1 arquivo no tamanho máximo de 10MB e nos formatos JPG, JPEG, GIF, APNG, PNG, AVIF, SVG, WEBP."
                  filesTypesAccept="image/jpeg, image/png, image/gif, image/avif, image/svg+xml, image/webp"
                  onUpload={uploadFileImage}
                  handleDeleteUploadledFiles={() =>
                    handleDeleteUploadFile('image')
                  }
                  onClickSendFiles={() => handleSendFiles('image', 'post')}
                  loading={loadingStatusImage}
                  setDisabled={() => filesRejected('image')}
                  disabled={
                    disabledUploadImage || uploadedFileListImage[0]?.fileId
                  }
                  // Number in bytes of MB
                  maxSize={10000000}
                  uploadMessageRejectMaxSize="Tamanho máximo do arquivo de 10MB excedido."
                  progressColor={
                    infoImage || uploadedFileListImage[0]?.fileId
                      ? 'success'
                      : 'information'
                  }
                />
              </InfraCol>
              <InfraCol xxl="6" xl="4" lg="4" md="4" xs="2">
                <InfraUpload
                  direction="horizontal"
                  uploadMessageDefault="Upload do Vídeo"
                  uploadMessageSuccess="Solte seu arquivo aqui"
                  uploadMessageReject="Arquivo não suportado"
                  uploadedList={uploadedFileListVideo}
                  uploadMessageRejectMaxFiles="Excedeu a quantidade máxima de arquivos."
                  uploadMessageRejectTypeInvalid="Tipo de arquivo inválido"
                  description="Apenas 1 arquivo no tamanho máximo de 90MB e nos formatos WEBM, MP4, MPEG."
                  filesTypesAccept="video/mp4, video/webm, video/mpeg"
                  onUpload={file => uploadFileVideo(file)}
                  handleDeleteUploadledFiles={() =>
                    handleDeleteUploadFile('video')
                  }
                  onClickSendFiles={() => handleSendFiles('video', 'post')}
                  loading={loadingStatusVideo}
                  setDisabled={() => filesRejected('video')}
                  disabled={
                    disabledUploadVideo || uploadedFileListVideo[0]?.fileId
                  }
                  // Number in bytes of MB
                  maxSize={90000000}
                  uploadMessageRejectMaxSize="Tamanho máximo do arquivo de 90MB excedido."
                  progressColor={
                    infoVideo || uploadedFileListVideo[0]?.fileId
                      ? 'success'
                      : 'information'
                  }
                />
              </InfraCol>
            </InfraGrid>
          </InfraCol>
        </InfraGrid>
      </S.Wrapper>

      <div style={{ marginTop: 32 }} />
      <Accordion
        title="Configurações Adicionais"
        tableData={additionalConfigurationTable}
        setTableData={setAdditionalConfigurationTable}
        hasTitleComponentWithIconPlus="Cadastrar nova configuração"
      />

      <CommonAccordion
        isActive
        title="Botões"
        headerRight={
          <InfraButton
            icon="Plus"
            iconRight
            size="small"
            outline
            style={{ border: 0 }}
            onClick={() => addButton()}
          >
            Cadastrar novo botão
          </InfraButton>
        }
      >
        {buttonsMediaList.length > 0 ? (
          <InfraTable value={buttonsMediaList}>
            <InfraTableColumn align="left" field="text" header="Texto" />
            <InfraTableColumn align="left" field="href" header="Link" />
            <InfraTableColumn
              field="edit"
              header=""
              body={renderEditIcon}
              padding="checkbox"
            />
            <InfraTableColumn
              field="action"
              header=""
              body={renderDeleteIcon}
              padding="checkbox"
            />
          </InfraTable>
        ) : (
          <p className="not-data">
            <InfraIcons name="Info" size={16} className="info-icon" />
            Não há dados! Por favor, adicione configuração clicando no
            <InfraIcons name="Plus" size={15} className="info-icon" />
          </p>
        )}
      </CommonAccordion>
    </>
  );
}
