import { InfraTreeView } from '@infralabs/design-system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigationContext } from '../../../../Contexts/NavigationItemContext';
import ModalDelete, { ModalAlertHandle } from '../../components/modalAlert';
import { CommonAlertMessage } from '../style';
import {
  fixOrderByArrayOrder,
  mapObject2TreeViewData,
  remapTreeViewData2Object,
} from './treeView.helpers';

export function TreeView({ navigationId }: { navigationId: string }) {
  const [{ items }, dispatch] = useNavigationContext();
  const modalRef = useRef<ModalAlertHandle>(null);
  const history = useHistory();
  const treeData = mapObject2TreeViewData(items);
  const [height, setHeight] = useState(500);

  const save = treeDataSave => {
    dispatch({
      type: 'setItems',
      payload: remapTreeViewData2Object(fixOrderByArrayOrder(treeDataSave)),
    });
  };

  if (!treeData?.length) {
    return (
      <CommonAlertMessage>
        Insira ao menos 1 item para poder salvar este componente de navegação
      </CommonAlertMessage>
    );
  }

  const updateTreeViewHeight = useCallback(() => {
    const treeViewElement = document.querySelector('.tree-view-scroll div');
    if (treeViewElement) {
      setHeight(treeViewElement.scrollHeight + 20);
    }
  }, [items]);

  useEffect(() => {
    setTimeout(updateTreeViewHeight, 10);
  }, [updateTreeViewHeight]);

  return (
    <>
      <InfraTreeView
        className="tree-view-scroll"
        showEditButton
        showRemoveButton
        treeData={treeData}
        onEditNode={({ node: { data } }) => {
          history.push(`/navigations/${navigationId}/${data?.id}`);
        }}
        onRemoveNode={treeDataSave => {
          modalRef.current.setOperations({
            onConfirm: async () => {
              save(treeDataSave);
              modalRef.current.closeModal();
            },
          });
          modalRef.current.openModal({ icon: 'info' });
        }}
        onChange={save}
        height={height}
      />
      <ModalDelete ref={modalRef} showConfirmButton showCancelButton>
        <span className="title">Você realmente deseja excluir os dados?</span>
      </ModalDelete>
    </>
  );
}
