import { API } from '../api';

export type Collection = {
  collectionId: string;
  name: string;
  active: boolean;
  dynamic: boolean;
  collectionType: {
    collectionTypeId: string;
    description: string;
  };
  skus: string[];
  settings: {
    key: string;
    value: string;
  }[];
};

export type CollectionResponse = {
  data: { collection: Collection[] };
  meta: any;
};

export const getAllCollections = async () => {
  return API.get<CollectionResponse>(`/collections`, {
    params: { status: true, pageSize: 1000 },
  });
};

export const getAllCollectionsByName = async (name: string) => {
  return API.get<CollectionResponse>(`/collections`, {
    params: { name },
  });
};
