import { InfraIcons } from '@infralabs/design-system';
import { useHistory } from 'react-router-dom';
import ModalAlertDialog from '../../../../components/ModalAlertDialog';
import { Element, Position } from '../../../../Contexts/PageContext';
import { usePage } from '../../../../hooks/usePage';
import { SectionOption } from '../types';
import { TableValue } from './types';

export function getPositionTitle(positionName: Position) {
  switch (positionName) {
    case 'header':
      return 'Cabeçalho';
    case 'body':
      return 'Corpo';
    case 'footer':
      return 'Rodapé';

    default:
      return '';
  }
}

export function getElementTitle(elementName: Element) {
  switch (elementName) {
    case 'banner':
      return 'Banner';
    case 'content':
      return 'Conteúdo HTML';
    case 'navigation':
      return 'Componente de Navegação';
    case 'shelf':
      return 'Prateleira';
    case 'tipbar':
      return 'Tipbar';
    case 'generic-component':
      return 'Componente Genérico';

    default:
      return '';
  }
}

export const buttonActionEdit = (params: TableValue) => {
  const { payload, setElement, setPosition, setBannerState, setTipbarState } =
    usePage();
  const history = useHistory();
  const {
    element,
    elementDescription,
    elementTitle,
    positionTitle,
    position,
    order,
    ...pageElement
  } = params;
  return (
    <InfraIcons
      name="Pencil"
      size={15}
      onClick={() => {
        setElement(element as Element);
        setPosition(position);
        let index: number;
        switch (element) {
          case 'navigation':
            index = payload[position].sections.findIndex(
              section =>
                section.navigations[0]?.navigationId ===
                pageElement.navigationId,
            );
            break;
          case 'banner':
            index = payload[position].sections.findIndex(
              (section: SectionOption) => {
                if (section.banners.length > 0)
                  return section.banners[order]?.name === pageElement.name;
              },
            );
            const {
              bannerType,
              configs,
              medias,
              name,
              dateStart: bannerDateStart,
              dateEnd: bannerDateEnd,
            } = payload[position].sections[index].banners[order];
            setBannerState({
              bannerType,
              configs,
              medias,
              name,
              dateStart: bannerDateStart || null,
              dateEnd: bannerDateEnd || null,
            });
            break;
          case 'tipbar':
            index = payload[position].sections.findIndex(
              (section: SectionOption) => {
                if (section.tipbars.length > 0)
                  return section.tipbars[order]?.title === pageElement.title;
              },
            );

            const {
              tipbarType,
              configs: tipbarConfigs,
              items,
              title,
              dateStart: tipbarDateStart,
              dateEnd: tipbarDateEnd,
            } = payload[position].sections[index].tipbars[order];
            setTipbarState({
              tipbarType,
              configs: tipbarConfigs,
              items,
              title,
              dateStart: tipbarDateStart || null,
              dateEnd: tipbarDateEnd || null,
            });
            break;
          case 'content':
            index = payload[position].sections.findIndex(
              (section: SectionOption) =>
                section.content === elementDescription,
            );
            break;
          case 'shelf':
            index = payload[position].sections.findIndex(section => {
              return (
                section.shelves[0]?.collectionId === pageElement.collectionId
              );
            });
            break;

          case 'generic-component':
            index = payload[position].sections.findIndex(
              section => section.components[0]?.title === pageElement.title,
            );
            break;

          default:
            index = 0;
            break;
        }

        history.push(`/pages/edit-element/${index}`);
      }}
    />
  );
};

export const buttonActionDelete = (params: TableValue) => {
  const { payload, setPayload } = usePage();
  const {
    element,
    elementDescription,
    elementTitle,
    positionTitle,
    position,
    ...pageElement
  } = params;

  function handleDeleteItem(item: TableValue) {
    const { sections } = payload[position];
    let filteredSections = sections;
    if (item.element === 'content') {
      filteredSections = sections.filter(
        section => section.content !== item.elementDescription,
      );
    }
    if (item.element === 'banner') {
      filteredSections = sections.filter(
        section => section.banners[0]?.name !== pageElement.name,
      );
    }
    if (item.element === 'navigation') {
      filteredSections = sections.filter(
        section =>
          section.navigations[0]?.navigationId !== pageElement.navigationId,
      );
    }
    if (item.element === 'generic-component') {
      filteredSections = sections.filter(
        section => section.components[0]?.title !== pageElement.title,
      );
    }
    if (item.element === 'tipbar') {
      filteredSections = sections.filter(
        section => section.tipbars[0]?.title !== pageElement.title,
      );
    }
    if (item.element === 'shelf') {
      filteredSections = sections.filter(
        section =>
          section.shelves[0]?.collectionId !== pageElement.collectionId &&
          section.shelves[0]?.name !== pageElement.name,
      );
    }
    if (filteredSections.length > 0) {
      filteredSections = filteredSections.map((section, i) => ({
        ...section,
        order: i,
      }));
    } else {
      filteredSections = [
        {
          content: null,
          components: [],
          order: 0,
          banners: [],
          shelves: [],
          tipbars: [],
          navigations: [],
        },
      ];
    }
    setPayload({
      ...payload,
      [position]: { sections: filteredSections },
    });
  }

  return (
    <>
      <InfraIcons
        name="Trash"
        size={15}
        onClick={() => handleDeleteItem(params)}
      />
      {/* <ModalAlertDialog
        message="Tem certeza que deseja excluir o elemento?"
        show={showModal}
        onButtonClick={}
        onClose={() => setShowModal(false)}
        buttonTitle="excluir"
      /> */}
    </>
  );
};
