import React, { createContext, ReactNode, useState } from 'react';
import { pageInitialValues } from '../Pages/Page/CreatePage/options';
import { PageRequestType } from '../Pages/Page/CreatePage/types';

export type Element =
  | ''
  | 'banner'
  | 'content'
  | 'navigation'
  | 'tipbar'
  | 'shelf'
  | 'generic-component';
export type Position = '' | 'header' | 'body' | 'footer';
export type BannerStateType = {
  name: string;
  bannerType: string;
  medias: any[];
  configs: any[];
  dateStart: string | null;
  dateEnd: string | null;
};

export type TipbarStateType = {
  title: string;
  tipbarType: string;
  items: any[];
  configs: any[];
  dateStart: string | null;
  dateEnd: string | null;
};

export interface PageContextData {
  pageId: string;
  setPageId: (pageId: string) => void;
  element: Element;
  setElement: (element: Element) => void;
  position: Position;
  setPosition: (position: Position) => void;
  payload: PageRequestType;
  setPayload: (payload: PageRequestType) => void;
  bannerState: BannerStateType;
  setBannerState: React.Dispatch<React.SetStateAction<BannerStateType>>;
  tipbarState: TipbarStateType;
  setTipbarState: React.Dispatch<React.SetStateAction<TipbarStateType>>;
  clear: () => void;
}

interface PageContextProviderProps {
  children: ReactNode;
}

export const PageContext = createContext<PageContextData>(
  {} as PageContextData,
);

export function PageContextProvider({ children }: PageContextProviderProps) {
  const [pageId, dispatchPageId] = useState<string>(() => {
    return localStorage.getItem('@infra-page:pageId');
  });
  const [element, dispatchElement] = useState<Element>(() => {
    return localStorage.getItem('@infra-page:element') as Element;
  });
  const [position, dispatchPosition] = useState<Position>(() => {
    const lsPosition = localStorage.getItem('@infra-page:position');
    if (lsPosition) return lsPosition as Position;
  });
  const [payload, dispatchPayload] = useState<PageRequestType>(() => {
    const lsPayload = localStorage.getItem('@infra-page:payload');
    if (lsPayload) return JSON.parse(lsPayload);
    return pageInitialValues;
  });

  const [bannerState, setBannerState] = useState<BannerStateType>({
    name: '',
    bannerType: '',
    medias: [],
    configs: [],
    dateStart: null,
    dateEnd: null,
  });

  const [tipbarState, setTipbarState] = useState<TipbarStateType>({
    title: '',
    tipbarType: '',
    items: [],
    configs: [],
    dateStart: null,
    dateEnd: null,
  });

  function setPageId(pageId: string) {
    dispatchPageId(pageId);
    localStorage.setItem('@infra-page:pageId', pageId);
  }

  function setElement(element: Element) {
    dispatchElement(element);
    localStorage.setItem('@infra-page:element', element);
  }

  function setPosition(position: Position) {
    dispatchPosition(position);
    localStorage.setItem('@infra-page:position', position);
  }

  function setPayload(payload: PageRequestType) {
    let { header } = payload;
    let { body } = payload;
    let { footer } = payload;
    const emptySection = {
      content: null,
      banners: [],
      components: [],
      navigations: [],
      shelves: [],
      tipbars: [],
      order: 0,
    };
    header.sections.forEach((section, i) => {
      if (section.shelves.length > 0) {
        section.shelves.forEach((shelf, j) => {
          if (shelf.collection) {
            const { collection } = shelf;
            header.sections[i].shelves[j].collectionId =
              collection.collectionId;
            delete header.sections[i].shelves[j].collection;
          }
        });
      }
      if (section.tipbars.length > 0) {
        section.tipbars.forEach((tipbar, j) => {
          if (!tipbar.dateStart) header.sections[i].tipbars[j].dateStart = null;
          if (!tipbar.dateEnd) header.sections[i].tipbars[j].dateEnd = null;
        });
      }
      if (section.banners.length > 0) {
        section.banners.forEach((banner, j) => {
          if (!banner.dateStart) header.sections[i].banners[j].dateStart = null;
          if (!banner.dateEnd) header.sections[i].banners[j].dateEnd = null;
        });
      }
    });
    body.sections.forEach((section, i) => {
      if (section.shelves.length > 0) {
        section.shelves.forEach((shelf, j) => {
          if (shelf.collection) {
            const { collection } = shelf;
            body.sections[i].shelves[j].collectionId = collection.collectionId;
            delete body.sections[i].shelves[j].collection;
          }
        });
      }
      if (section.tipbars.length > 0) {
        section.tipbars.forEach((tipbar, j) => {
          if (!tipbar.dateStart) body.sections[i].tipbars[j].dateStart = null;
          if (!tipbar.dateEnd) body.sections[i].tipbars[j].dateEnd = null;
        });
      }
      if (section.banners.length > 0) {
        section.banners.forEach((banner, j) => {
          if (!banner.dateStart) body.sections[i].banners[j].dateStart = null;
          if (!banner.dateEnd) body.sections[i].banners[j].dateEnd = null;
        });
      }
    });
    footer.sections.forEach((section, i) => {
      if (section.shelves.length > 0) {
        section.shelves.forEach((shelf, j) => {
          if (shelf.collection) {
            const { collection } = shelf;
            footer.sections[i].shelves[j].collectionId =
              collection.collectionId;
            delete footer.sections[i].shelves[j].collection;
          }
        });
      }
      if (section.tipbars.length > 0) {
        section.tipbars.forEach((tipbar, j) => {
          if (!tipbar.dateStart) footer.sections[i].tipbars[j].dateStart = null;
          if (!tipbar.dateEnd) footer.sections[i].tipbars[j].dateEnd = null;
        });
      }
      if (section.banners.length > 0) {
        section.banners.forEach((banner, j) => {
          if (!banner.dateStart) footer.sections[i].banners[j].dateStart = null;
          if (!banner.dateEnd) footer.sections[i].banners[j].dateEnd = null;
        });
      }
    });
    if (header.sections.length === 0) header = { sections: [emptySection] };
    if (body.sections.length === 0) body = { sections: [emptySection] };
    if (footer.sections.length === 0) footer = { sections: [emptySection] };

    dispatchPayload({ ...payload, header, body, footer });
    const lsPayload = JSON.stringify({ ...payload, header, body, footer });
    localStorage.setItem('@infra-page:payload', lsPayload);
  }

  function clear() {
    localStorage.removeItem('@infra-page:payload');
    localStorage.removeItem('@infra-page:pageId');
    localStorage.removeItem('@infra-page:element');
    localStorage.removeItem('@infra-page:position');
    dispatchElement('');
    dispatchPageId(null);
    dispatchPosition('');
    setBannerState({
      name: '',
      bannerType: '',
      medias: [],
      configs: [],
      dateStart: null,
      dateEnd: null,
    });
    setTipbarState({
      title: '',
      tipbarType: '',
      items: [],
      configs: [],
      dateStart: null,
      dateEnd: null,
    });
    dispatchPayload({
      title: '',
      canonical: '',
      brandId: '',
      skus: [],
      categoryId: '',
      skuId: '',
      sellerId: '',
      collectionId: '',
      header: {
        sections: [
          {
            content: null,
            components: [],
            order: 0,
            banners: [],
            shelves: [],
            tipbars: [],
            navigations: [],
          },
        ],
      },
      body: {
        sections: [
          {
            content: null,
            components: [],
            order: 0,
            banners: [],
            shelves: [],
            tipbars: [],
            navigations: [],
          },
        ],
      },
      footer: {
        sections: [
          {
            content: null,
            components: [],
            order: 0,
            banners: [],
            shelves: [],
            tipbars: [],
            navigations: [],
          },
        ],
      },
      seo: {
        header: '',
        description: '',
        content: '',
      },
      configs: [],
    });
  }

  return (
    <PageContext.Provider
      value={{
        pageId,
        element,
        position,
        payload,
        setPageId,
        setElement,
        setPosition,
        setPayload,
        bannerState,
        setBannerState,
        tipbarState,
        setTipbarState,
        clear,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}
