const kebabcase = (str: string) =>
  (str || '')
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .join('-')
    .toLowerCase();

export const kebabcaseForFileName = (str: string) => {
  return kebabcase((str || '').substr(0, str.lastIndexOf('.'))).replace(
    /^-+|-+(?=-|$)/g,
    '',
  );
};

export default kebabcase;
