import {
  useState,
  Dispatch,
  SetStateAction,
  ChangeEvent,
  FormEvent,
} from 'react';
import {
  InfraTable,
  InfraTableColumn,
  InfraModal,
  InfraTextField,
  InfraButton,
  InfraIcons,
} from '@infralabs/design-system';
import * as S from './style';

export type AddicionalSettingsTableDataProps = {
  key: string;
  value: string;
};

function Accordion({
  title,
  tableData,
  setTableData,
  hasTitleComponentWithIconPlus,
}: {
  title: string;
  tableData: AddicionalSettingsTableDataProps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTableData: Dispatch<SetStateAction<any>>;
  hasTitleComponentWithIconPlus?: string;
}) {
  const [titleModal, setTitleModal] = useState('Configuração Adicional');
  const [isActive, setIsActive] = useState(false);
  const [showModalAddOrEdit, setShowModalAddOrEdit] = useState(false);
  const [showModalConfirmationDelete, setShowModalConfirmationDelete] =
    useState({
      status: false,
      rowData: {} as AddicionalSettingsTableDataProps,
    });
  const [modalKey, setModalKey] = useState('');
  const [modalValue, setModalValue] = useState('');
  const [modalEmptyError, setModalEmptyError] = useState({
    typeKey: 'normal',
    typeValue: 'normal',
    messageKey: 'Preencha o campo chave',
    messageValue: 'Preencha o campo valor',
  });
  const [editModal, setEditModal] = useState(false);
  const [rowDataEdit, setRowDataEdit] =
    useState<AddicionalSettingsTableDataProps>();

  const openModal = e => {
    e.preventDefault();
    setTitleModal('Configuração Adicional');
    setModalKey('');
    setModalValue('');
    setShowModalAddOrEdit(true);
  };

  const closeModal = () => {
    setShowModalAddOrEdit(false);
    setEditModal(false);
  };

  const handleCreateNewDataTable = () => {
    if (modalKey === '') {
      setModalEmptyError({ ...modalEmptyError, typeKey: 'error' });
    } else if (modalValue === '') {
      setModalEmptyError({ ...modalEmptyError, typeValue: 'error' });
    } else {
      setTableData([...tableData, { key: modalKey, value: modalValue }]);
      setShowModalAddOrEdit(false);
      setIsActive(true);
    }
  };

  const handleEdit = (rowData: AddicionalSettingsTableDataProps) => {
    setTitleModal('Editar Configuração Adicional');
    setEditModal(true);
    setModalKey(rowData.key);
    setModalValue(rowData.value);
    setRowDataEdit(rowData);
    setShowModalAddOrEdit(true);
  };

  const buttonActionEdit = (rowData: AddicionalSettingsTableDataProps) => {
    return (
      <InfraIcons name="Pencil" size={15} onClick={() => handleEdit(rowData)} />
    );
  };

  const editTableData = () => {
    if (modalKey === '') {
      setModalEmptyError({ ...modalEmptyError, typeKey: 'error' });
    } else if (modalValue === '') {
      setModalEmptyError({ ...modalEmptyError, typeValue: 'error' });
    } else {
      const index = tableData.findIndex(
        (data: AddicionalSettingsTableDataProps) => data === rowDataEdit,
      );
      const data = [...tableData];
      data[index].key = modalKey;
      data[index].value = modalValue;
      setTableData(data);
      setModalKey('');
      setModalValue('');
      setShowModalAddOrEdit(false);
      setEditModal(false);
    }
  };

  const buttonActionDelete = (rowData: AddicionalSettingsTableDataProps) => {
    return (
      <InfraIcons
        name="Trash"
        size={15}
        onClick={() =>
          setShowModalConfirmationDelete({ rowData, status: true })
        }
      />
    );
  };

  const handleDelete = (rowData: AddicionalSettingsTableDataProps) => {
    const deleteRowFromTable = tableData.filter(data => data !== rowData);
    setTableData(deleteRowFromTable);
    setShowModalConfirmationDelete({
      rowData: {} as AddicionalSettingsTableDataProps,
      status: false,
    });
  };

  const handleSubmitModal = (values: FormEvent<HTMLInputElement>) => {
    values.preventDefault();
    if (editModal) {
      editTableData();
    } else {
      handleCreateNewDataTable();
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.Heading>
          <S.Title>{title}</S.Title>
          <S.HeadingContent plusWithTitle={hasTitleComponentWithIconPlus}>
            {hasTitleComponentWithIconPlus ? (
              <InfraButton
                icon="Plus"
                iconRight
                size="small"
                outline
                style={{ border: 0 }}
                onClick={openModal}
              >
                {hasTitleComponentWithIconPlus}
              </InfraButton>
            ) : (
              <InfraIcons
                name="Plus"
                size={15}
                onClick={openModal}
                className="plus-icon"
              />
            )}

            {isActive ? (
              <InfraIcons
                name="CaretUp"
                size={16}
                onClick={() => setIsActive(!isActive)}
                className="caret-icons"
              />
            ) : (
              <InfraIcons
                name="CaretDown"
                size={16}
                onClick={() => setIsActive(!isActive)}
                className="caret-icons"
              />
            )}
          </S.HeadingContent>
        </S.Heading>
        {isActive && (
          <S.BodyAccordion>
            {tableData.length >= 1 ? (
              <InfraTable value={tableData}>
                <InfraTableColumn align="left" field="key" header="Chave" />
                <InfraTableColumn align="left" field="value" header="Valor" />
                <InfraTableColumn
                  field="action"
                  header=""
                  body={buttonActionEdit}
                  padding="checkbox"
                />
                <InfraTableColumn
                  field="action"
                  header=""
                  body={buttonActionDelete}
                  padding="checkbox"
                />
              </InfraTable>
            ) : (
              <p className="not-data">
                <InfraIcons name="Info" size={16} className="info-icon" />
                Não há dados! Por favor, adicione configuração clicando no “
                <InfraIcons name="Plus" size={15} className="info-icon" />”
              </p>
            )}
          </S.BodyAccordion>
        )}
      </S.Wrapper>

      {showModalAddOrEdit && (
        <InfraModal
          title={titleModal}
          show={showModalAddOrEdit}
          onClose={() => closeModal()}
        >
          <InfraTextField
            placeholder="Seu texto"
            height="medium"
            label="Chave"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setModalKey(e.target.value);
              setModalEmptyError({ ...modalEmptyError, typeKey: 'normal' });
            }}
            inputStyle={modalEmptyError.typeKey}
            value={modalKey}
            message={
              modalEmptyError.typeKey === 'error'
                ? modalEmptyError.messageKey
                : ''
            }
          />
          <S.Divider />
          <InfraTextField
            placeholder="Seu texto"
            height="medium"
            label="Valor"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setModalValue(e.target.value);
              setModalEmptyError({ ...modalEmptyError, typeValue: 'normal' });
            }}
            inputStyle={modalEmptyError.typeValue}
            value={modalValue}
            message={
              modalEmptyError.typeValue === 'error'
                ? modalEmptyError.messageValue
                : ''
            }
          />

          <InfraButton
            style={{ background: '#414f62', marginTop: '1rem' }}
            fullWidth
            icon="admin"
            iconRigth
            outline={false}
            onClick={handleSubmitModal}
          >
            Salvar
          </InfraButton>
        </InfraModal>
      )}
      {showModalConfirmationDelete.status && (
        <S.ContainerModalConfirmationDelete>
          <InfraModal
            theme="light"
            show={showModalConfirmationDelete.status}
            onClose={() =>
              setShowModalConfirmationDelete({
                rowData: showModalConfirmationDelete.rowData,
                status: false,
              })
            }
            width="278"
          >
            <div className="icon-warning">
              <InfraIcons name="WarningCircle" size={54} color="#1b1bff" />
            </div>
            <span className="title">
              Você realmente deseja excluir os dados?
            </span>
            <S.ContainerButtonsModalDelete>
              <InfraButton
                fullWidth
                size="small"
                outline
                onClick={() =>
                  setShowModalConfirmationDelete({
                    rowData: showModalConfirmationDelete.rowData,
                    status: false,
                  })
                }
                className="button-cancel"
              >
                Cancelar
              </InfraButton>
              <InfraButton
                fullWidth
                size="small"
                onClick={() =>
                  handleDelete(showModalConfirmationDelete.rowData)
                }
                className="button-ok"
              >
                Sim
              </InfraButton>
            </S.ContainerButtonsModalDelete>
          </InfraModal>
        </S.ContainerModalConfirmationDelete>
      )}
    </>
  );
}

export default Accordion;
