import {
  InfraButton,
  InfraIcons,
  InfraModal,
  InfraCircularProgress,
} from '@infralabs/design-system';
import { useImperativeHandle, forwardRef, useState, ReactNode } from 'react';
import { Colors } from '../../../theme';
import {
  ContainerModalConfirmationDelete,
  ContainerButtonsModalDelete,
  WaitMessage,
  AlertMessage,
} from './style';
import {
  ModalAlertProps,
  ModalAlertHandle as ModalAlertHandle_,
  PropsOnOpenModal,
  Icon,
  OnOpen,
} from './types';

const ModalIconTypes = {
  success: {
    name: 'CheckCircle',
    size: 54,
    color: Colors.success_100,
    weight: 'fill',
  },
  error: {
    name: 'XCircle',
    size: 54,
    color: Colors.error_100,
    weight: 'fill',
  },
  info: {
    name: 'WarningCircle',
    size: 54,
    color: '#1b1bff',
    weight: 'fill',
  },
};

const getIconConfigByType = (type: OnOpen['icon']) => {
  return ModalIconTypes.hasOwnProperty(type)
    ? ModalIconTypes[type]
    : ModalIconTypes.info;
};

const ModalDelete = forwardRef<ModalAlertHandle_, ModalAlertProps>(
  (props, ref) => {
    const {
      showCancelButton,
      showConfirmButton,
      textCancelButton,
      textConfirmButton,
      children,
    } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [iconProps, setIconProps] = useState<Icon>(
      getIconConfigByType('info'),
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [content, setContent] = useState<ReactNode>(children);
    const [operations, setOperations] = useState<PropsOnOpenModal | null>();

    useImperativeHandle(ref, () => ({
      setOperations: (operation: PropsOnOpenModal) => {
        setOperations(operation);
      },
      setContent: (content: ReactNode) => {
        if (typeof content === 'string') {
          setContent(<AlertMessage>{content}</AlertMessage>);
          return;
        }
        setContent(content);
      },
      openModal: (props = {}) => {
        const { icon } = props;
        if (icon) {
          setIconProps(getIconConfigByType(icon));
        }
        setVisible(true);
        if (operations?.onOpen) {
          operations.onOpen();
        }
      },
      setLoading: value => {
        setLoading(value);
      },
      closeModal: () => {
        setVisible(false);
        if (operations?.onClose) {
          operations.onClose();
        }
      },
    }));

    return (
      <ContainerModalConfirmationDelete show={visible}>
        <InfraModal
          theme="light"
          show={visible}
          onClose={() => setVisible(false)}
          width="278"
        >
          {loading ? (
            <WaitMessage>
              <div className="icon-warning">
                <InfraCircularProgress value={54} variant="indeterminate" />
              </div>
              <p>Aguarde...</p>
            </WaitMessage>
          ) : (
            <>
              <div className="icon-warning">
                <InfraIcons {...iconProps} />
              </div>
              {content}
              <ContainerButtonsModalDelete>
                {showCancelButton ? (
                  <InfraButton
                    fullWidth
                    size="small"
                    outline
                    onClick={() => {
                      setVisible(false);

                      if (operations?.onCancel) {
                        operations.onCancel();
                      }
                    }}
                    className="button-cancel"
                  >
                    {textCancelButton || 'Cancelar'}
                  </InfraButton>
                ) : null}
                {showConfirmButton ? (
                  <InfraButton
                    fullWidth
                    size="small"
                    onClick={() => {
                      if (operations?.onConfirm) {
                        operations.onConfirm();
                      } else {
                        setVisible(false);
                      }
                    }}
                    className="button-ok"
                  >
                    {textConfirmButton || 'Sim'}
                  </InfraButton>
                ) : null}
              </ContainerButtonsModalDelete>
            </>
          )}
        </InfraModal>
      </ContainerModalConfirmationDelete>
    );
  },
);

export type ModalAlertHandle = ModalAlertHandle_;

export default ModalDelete;
