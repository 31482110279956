import styled from 'styled-components';

import {
  InfraTypography,
  InfraButton,
  InfraIcons,
} from '@infralabs/design-system';

import { Spacings, Shadows, Colors, Typography as Ty } from '../../theme';

export const Modal = styled.div`
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
    }
    .description {
      ${Ty.body_large}
      line-height: 22px;
      text-align: center;
      width: 180px;
      margin: 30px;
    }
  }

  .button-modal {
    display: flex;
    gap: 20px;
  }
`;

export const Header = styled.div`
  margin-bottom: ${Spacings.spacing_32};
`;

export const Typography = styled(InfraTypography)`
  color: ${Colors.neutral_600} !important;
  font-weight: bold !important;
`;

export const ButtonBack = styled.div`
  display: flex;
  align-items: center;

  > p {
    ${Ty.body_small}
    cursor: pointer;
    color: ${Colors.information_100};
    font-weight: 700;
    margin-bottom: ${Spacings.spacing_0};
  }
`;

export const Icon = styled(InfraIcons)`
  margin-right: ${Spacings.spacing_4};
  cursor: pointer;
  color: ${Colors.information_100};
`;

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  box-shadow: ${Shadows.shadow_md};
  padding: ${Spacings.spacing_40} 0;

  .label-input {
    display: flex;
    flex-direction: column;

    label {
      font-size: ${Spacings.spacing_16};
      font-family: ${Ty.h1.fontFamily};
      padding-right: ${Spacings.spacing_2};
      color: ${Colors.shade_100};
      margin-bottom: ${Spacings.spacing_5};
    }
  }

  .error-target {
    font-size: ${Spacings.spacing_12};
    line-height: ${Spacings.spacing_18};
    font-family: ${Ty.h1.fontFamily};
    color: ${Colors.error_300};
  }
`;

export const Button = styled(InfraButton)`
  margin-top: ${Spacings.spacing_28};
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;
