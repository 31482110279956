import { useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  InfraModal,
  InfraCol,
  InfraGrid,
  InfraTextField,
  InfraIcons,
  InfraButton,
  InfraTextarea,
  InfraUpload,
} from '@infralabs/design-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InfraSelect } from '../../../../../components/design-system';
import Accordion from '../../../../../components/AdditionalSettings';

import { typeOptions } from './options';
import { Colors } from '../../../../../theme';
import * as S from './style';
import { usePage } from '../../../../../hooks/usePage';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';
import { kebabcaseForFileName } from '../../../../../Helper/kebabcase';
import { postBannerMediaPageUploadFileService } from '../../../../../Services/Uploads';

const genericComponentValidation = Yup.object().shape({
  title: Yup.string()
    .max(
      50,
      'O tamanho máximo do título do componente genérico, deve ser de 50 caracteres, incluindo espaços.',
    )
    .required('O Título do componente genérico deve ser especificado.'),
  text: Yup.string().max(
    50,
    'O tamanho máximo do texto do componente genérico, deve ser de 50 caracteres, incluindo espaços.',
  ),
  type: Yup.string().required(
    'O Tipo do componente genérico deve ser especificado.',
  ),
});

const createNewFileFromUrl = (url: string) => {
  const fileName = url.split('/').pop();

  const file = new File([fileName], url, {
    type: `image/jpg`,
  });

  // @ts-ignore
  file.uploaded = false;
  // @ts-ignore
  file.progress = 0;
  return file;
};

export const AddGenericComponent = () => {
  const { order } = useParams<AddNewPageElementParams>();
  const formRef = useRef(null);
  const history = useHistory();
  const { payload, setPayload, position, setElement, setPosition } = usePage();

  const [showModalError, setShowModalError] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageModalError, setMessageModalError] = useState('');

  const [additionalConfigurationTable, setAdditionalConfigurationTable] =
    useState(payload[position]?.sections[order]?.components[0]?.configs || []);

  const displayImage =
    payload[position]?.sections[order]?.components[0]?.displayImage;
  const fileDetailsObj = displayImage
    ? createNewFileFromUrl(displayImage)
    : null;

  const [filetoUpload, setFileToUpload] = useState<{
    file: File;
    formData: FormData;
  }>(fileDetailsObj ? { file: fileDetailsObj, formData: null } : null);

  const formik = useFormik({
    initialValues: {
      title:
        position && order
          ? payload[position]?.sections[order]?.components[0]?.title
          : '',
      type:
        position && order
          ? payload[position]?.sections[order]?.components[0]?.type
          : '',
      text:
        position && order
          ? payload[position]?.sections[order]?.components[0]?.text
          : '',
      content:
        position && order
          ? payload[position]?.sections[order]?.components[0]?.content
          : '',
      displayImage: null,
    },
    validationSchema: genericComponentValidation,
    onSubmit: async values => {
      try {
        const sectionsHeader = payload.header.sections;
        const sectionsBody = payload.body.sections;
        const sectionsFooter = payload.footer.sections;
        const imageUploaded = await uploadFile();

        const sectionElement = {
          content: null,
          banners: [],
          components: [
            {
              title: values.title,
              type: values.type,
              text: values.text,
              content: values.content,
              displayImage: imageUploaded ? imageUploaded.data.uri : null,
              order: 0,
              configs: additionalConfigurationTable,
            },
          ],
          navigations: [],
          shelves: [],
          tipbars: [],
          order: order ? +order : 0,
        };
        switch (position) {
          case 'header':
            if (order) {
              sectionsHeader[order] = sectionElement;
            } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
              sectionsHeader[0] = sectionElement;
            } else {
              sectionElement.order = sectionsHeader.length;
              sectionsHeader.push(sectionElement);
            }
            break;
          case 'body':
            if (order) {
              sectionsBody[order] = sectionElement;
            } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
              sectionsBody[0] = sectionElement;
            } else {
              sectionElement.order = sectionsBody.length;
              sectionsBody.push(sectionElement);
            }
            break;
          case 'footer':
            if (order) {
              sectionsFooter[order] = sectionElement;
            } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
              sectionsFooter[0] = sectionElement;
            } else {
              sectionElement.order = sectionsFooter.length;
              sectionsFooter.push(sectionElement);
            }
            break;

          default:
            break;
        }

        setPayload({
          ...payload,
          header: { sections: sectionsHeader },
          body: { sections: sectionsBody },
          footer: { sections: sectionsFooter },
        });

        setShowModalSuccess(true);
        setElement('');
        setPosition('');
      } catch (err) {
        setMessageModalError('Não foi possível fazer o upload da imagem.');
        setShowModalError(true);
      }
    },
  });

  const save = (e: any) => {
    formik.handleSubmit();
    e.preventDefault();
  };

  const setFormikValue = (value, input) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  const setInputStyle = (value: string, type: string) => {
    if (
      value?.length === 0 &&
      type === 'title' &&
      (formik.touched.title || !!order)
    ) {
      return 'error';
    }
    if (value?.length >= 100 && type === 'text') {
      return 'error';
    }
    if (value?.length === 0 && type === 'type' && formik.touched.type) {
      return 'error';
    }

    return 'normal';
  };

  const uploadFile = async () => {
    let response: { data: { uri: string } };
    if (!filetoUpload) return null;
    if (!displayImage || filetoUpload?.file?.name !== displayImage) {
      response = await postBannerMediaPageUploadFileService(
        filetoUpload.formData,
      );
    } else {
      response = {
        data: {
          uri: displayImage,
        },
      };
    }
    return response;
  };

  return (
    <>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{messageModalError}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalSuccess}
          onClose={() => {
            setShowModalSuccess(false);
            history.goBack();
          }}
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">
              Novo Componente Generíco foi cadastrado com sucesso
            </p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => {
              setShowModalSuccess(false);
              history.goBack();
            }}
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>

      <form onSubmit={save} ref={formRef}>
        <S.Container>
          <S.Form>
            <S.Column>
              <InfraGrid className="inputs-page" style={{ paddingInline: 0 }}>
                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="4">
                  <InfraTextField
                    label="Título"
                    placeholder="Digite o Título"
                    name="title"
                    message={formik.errors.title}
                    value={formik.values.title}
                    inputStyle={setInputStyle(formik.values.title, 'title')}
                    onChange={formik.handleChange}
                  />
                </InfraCol>

                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="4">
                  <InfraSelect
                    name="type"
                    label="Tipo"
                    placeholder="Selecione"
                    error={formik.errors.type}
                    value={formik.values.type}
                    items={typeOptions}
                    handlerClick={event => {
                      formik.setFieldValue('type', event.value);
                    }}
                  />
                </InfraCol>
              </InfraGrid>

              <InfraGrid
                className="inputs-page"
                style={{ paddingInline: 0, marginTop: 14 }}
              >
                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="4">
                  <S.TextArea>
                    <div className="label-input">
                      <label>Texto</label>
                      <InfraTextarea
                        label="Texto"
                        name="text"
                        placeholder="Digite o Texto"
                        value={formik.values.text}
                        onChange={formik.handleChange}
                        message={formik.errors.text}
                        inputStyle={setInputStyle(formik.values.text, 'text')}
                      />
                      <span className="error-target">{formik.errors.text}</span>
                    </div>
                  </S.TextArea>
                </InfraCol>

                <InfraCol xxl="6" xl="4" lg="4" md="4" xs="4">
                  <S.TextArea>
                    <div className="label-input">
                      <label>HTML</label>
                      <InfraTextarea
                        label="HTML"
                        name="content"
                        placeholder="Digite o HTML"
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        message={formik.errors.content}
                        inputStyle={setInputStyle(
                          formik.values.content,
                          'content',
                        )}
                      />
                    </div>
                  </S.TextArea>
                </InfraCol>
              </InfraGrid>
            </S.Column>

            <S.Column>
              <InfraGrid>
                <InfraCol xxl="10" xl="10" lg="8" md="8" xs="4">
                  <div className="upload-section">
                    <InfraUpload
                      direction="horizontal"
                      uploadMessageDefault="Upload da imagem"
                      uploadMessageSuccess="Arraste e solte seu arquivo aqui para realizar o upload"
                      uploadMessageReject="Arquivo não suportado"
                      uploadMessageRejectMaxFiles="Excedeu a quantidade máxima de arquivos."
                      uploadMessageRejectTypeInvalid="Tipo de arquivo inválido"
                      description="Apenas 1 arquivo no tamanho máximo de 10MB e nos formatos JPG, JPEG, GIF, APNG, PNG, AVIF, SVG, WEBP."
                      filesTypesAccept="image/jpeg, image/png, image/gif, image/avif, image/svg+xml, image/webp"
                      onUpload={async ([file]) => {
                        formik.setFieldValue('displayImage', 'filled');
                        const formData = new FormData();

                        const bodyRequest = {
                          canonical: kebabcaseForFileName(file.name),
                          context: 'content',
                          contentType: file.type,
                          contentLanguage: 'en-us',
                          cacheControl: 'no-cache',
                          file,
                        };

                        for (const key in bodyRequest) {
                          if (
                            Object.prototype.hasOwnProperty.call(
                              bodyRequest,
                              key,
                            )
                          ) {
                            formData.append(key, bodyRequest[key]);
                          }
                        }

                        file.uploaded = false;
                        file.progress = 0;
                        setFileToUpload({
                          file,
                          formData,
                        });
                      }}
                      // Number in bytes of MB
                      maxSize={10000000}
                      uploadMessageRejectMaxSize="Tamanho máximo do arquivo de 10MB excedido."
                      uploadedList={
                        filetoUpload?.file ? [filetoUpload?.file] : []
                      }
                      renderCustomButtonUpload={() => false}
                      handleDeleteUploadledFiles={() => {
                        formik.setFieldValue('displayImage', null);
                        setFileToUpload(null);
                      }}
                    />
                  </div>
                </InfraCol>
              </InfraGrid>
            </S.Column>
          </S.Form>

          <InfraGrid
            className="inputs-page"
            style={{ paddingInline: 0, marginTop: 14 }}
          />
        </S.Container>

        <Accordion
          title="Configurações Adicionais"
          tableData={additionalConfigurationTable}
          setTableData={data => setAdditionalConfigurationTable(data)}
          hasTitleComponentWithIconPlus="Cadastrar nova configuração"
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '32px',
          }}
        >
          <S.Button type="submit" iconRight size="large" onClick={save}>
            Salvar
          </S.Button>
        </div>
      </form>
    </>
  );
};
