import {
  InfraModal,
  InfraIcons,
  InfraGrid,
  InfraCol,
  InfraTextField,
  InfraSelect,
  InfraTable,
  InfraTableColumn,
  InfraButton,
} from '@infralabs/design-system';
import { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import Accordion, {
  AddicionalSettingsTableDataProps,
} from '../../components/AdditionalSettings';
import * as style from './style';
import { FormValues, PageValues } from './Type';
import { Colors } from '../../theme';
import { MockTypeBanner, MockInputStyles, MockPage, idPage } from './Mocks';
import { updatePageService } from '../../Services/Pages';
import { DataMedia } from '../CreateMedia';
import { deleteBannerMediaPageUploadFileService } from '../../Services/Uploads';
import { ContentContext } from '../../providers/bannersProviders';

export default function CreationAndAssociationBanners({ locationUrl = '' }) {
  const history = useHistory();
  const formRef = useRef(null);
  const [page] = useState<PageValues>(MockPage);
  const [inputStyle, setInputStyle] = useState(MockInputStyles);
  const [mockITypes, setMockITypes] = useState(MockTypeBanner);
  const [tableBanners, setTableBanners] = useState<any>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageModalError, setMessageModalError] = useState('');
  const [bannerName, setBannerName] = useState<string>();
  const [bannerType, setBannerType] = useState<string>();
  const [mediaToDelete, setMediaToDelete] = useState<DataMedia>();
  const { contentState, setContentState } = useContext(ContentContext);

  useEffect(() => {
    if (locationUrl) {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  const typeBanner = {
    Carousel: 0,
    Hero: 1,
    Splitted: 2,
  };

  const formik = useFormik({
    initialValues: {
      name: contentState.name,
      bannerType: contentState.bannerType,
      medias: contentState.medias,
      configs: contentState.configs,
    },
    validate: (values: FormValues) => {
      setContentState(values);
      setBannerName(values.name);
      setBannerType(values.bannerType);
      const errors: FormikErrors<FormValues> = {};
      const inputStyle = {
        name: 'normal',
        bannerType: 'normal',
      };
      if (!values.name) {
        errors.name = 'O Nome do banner deve ser especificado.';
        inputStyle.name = 'error';
      }
      if (values.name && values.name.length > 50) {
        errors.name =
          'O tamanho máximo do nome do banner deve ser de 50 caracteres, incluindo espaços.';
        inputStyle.name = 'error';
      }
      if (!values.bannerType) {
        errors.bannerType = 'O Tipo do banner deve ser especificado.';
        inputStyle.bannerType = 'error';
      }
      setInputStyle(inputStyle);
      return errors;
    },
    onSubmit: values => {
      if (values.medias && values.medias.length === 0) {
        if (
          values.bannerType === mockITypes[1].label ||
          values.bannerType === mockITypes[2].label
        ) {
          setMessageModalError(
            `Um banner do tipo “${values.bannerType}” precisa ter pelo menos duas mídias vinculadas.`,
          );
        } else {
          setMessageModalError(
            `Um banner do tipo “${values.bannerType}” precisa ter pelo menos uma midia vinculada.`,
          );
        }
        setShowModalError(true);
      } else if (values.bannerType) {
        if (
          values.bannerType === mockITypes[0].label &&
          values.medias.length > 1
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo “${mockITypes[0].label}” deve possuir somente uma mídia vinculada.`,
          );
        } else if (
          values.bannerType === mockITypes[1].label &&
          values.medias.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo ${mockITypes[1].label} ou ${mockITypes[2].label} deve possuir pelo menos duas mídias vinculadas.`,
          );
        } else if (
          values.bannerType === mockITypes[2].label &&
          values.medias.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo ${mockITypes[1].label} ou ${mockITypes[2].label} deve possuir pelo menos duas mídias vinculadas.`,
          );
        } else {
          const pageId = idPage;
          const pageComputed = computePageForAPI(page);
          updatePageService(pageId, pageComputed)
            .then(response => {
              if (response.status === 200 || response.status === 201) {
                setShowModalSuccess(true);
                resetValuesForm();
              } else {
                setShowModalError(true);
                setMessageModalError(
                  'Aconteceu algum problema, tente novamente.',
                );
              }
            })
            .catch(() => {
              setShowModalError(true);
              setMessageModalError(
                'Aconteceu algum problema, tente novamente.',
              );
            });
        }
      }
    },
  });

  const resetValuesForm = () => {
    formik.values.name = '';
    formik.values.bannerType = '';
    const types = mockITypes.map(mocks => {
      const test = { ...mocks, selected: false };
      return test;
    });
    setMockITypes(types);
    formik.resetForm();
    setBannerName('');
    setBannerType('');
    setTableBanners([]);
    setContentState({
      name: '',
      bannerType: '',
      medias: [],
      configs: [],
    });
  };

  const computePageForAPI = page => {
    const addOrderAndChangeTypeToBannerType = {
      ...formik.values,
      order: 0,
      bannerType: typeBanner[formik.values.bannerType],
    };

    if (page.header.sections[0].banners.length > 0) {
      page.header.sections[0].banners = [];
    }
    page.header.sections[0].banners.push(addOrderAndChangeTypeToBannerType);

    return page;
  };

  const backTolastPage = () => {
    history.push('/');
  };

  const goToRegisterMidia = () => {
    history.push({
      pathname: '/create-banner-midia',
      state: { tableBanners, bannerName, bannerType },
    });
  };

  const renderEditIcon = values => {
    return (
      <div style={{ padding: '2px' }} onClick={() => editMidia(values)}>
        <InfraIcons name="PencilSimple" size={14} />
      </div>
    );
  };

  const renderDeleteIcon = values => {
    return (
      <div
        style={{ padding: '2px' }}
        onClick={() => openModaldeleteMidia(values)}
      >
        <InfraIcons name="TrashSimple" size={14} />
      </div>
    );
  };

  const addOrderInData = useCallback(
    (value = tableBanners) => {
      const sortedMedia = setMediaOrder(value);
      formik.values.medias = sortedMedia;
      setContentState({
        name: contentState.name,
        bannerType: contentState.bannerType,
        medias: sortedMedia,
        configs: contentState.configs,
      });
      setTableBanners(sortedMedia);
    },
    [tableBanners],
  );

  const deleteBannerMidia = () => {
    const tableMediasFiltered = tableBanners.filter(banner => {
      if (banner.title !== mediaToDelete.title) {
        return banner;
      }
    });

    if (
      mediaToDelete.fileImage &&
      mediaToDelete.fileImage.length > 0 &&
      mediaToDelete.fileImage
    ) {
      deleteBannerMediaPageUploadFileService(mediaToDelete.fileImage[0].fileId);
    }

    if (mediaToDelete.fileVideo && mediaToDelete.fileVideo.length > 0) {
      deleteBannerMediaPageUploadFileService(mediaToDelete.fileVideo[0].fileId);
    }
    formik.values.medias = tableMediasFiltered;
    setTableBanners(tableMediasFiltered);
    setShowModalDelete(false);
    setContentState({
      name: contentState.name,
      bannerType: contentState.bannerType,
      medias: tableMediasFiltered,
      configs: contentState.configs,
    });
    addOrderInData(tableMediasFiltered);
  };

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const editMidia = (values: AddicionalSettingsTableDataProps) => {
    const index = tableBanners.findIndex(
      (data: AddicionalSettingsTableDataProps) => data === values,
    );
    history.push({
      pathname: '/create-banner-midia',
      state: {
        indexEdit: index,
        isEdit: true,
        bannerName,
        bannerType,
        tableBanners,
      },
    });
  };

  const openModaldeleteMidia = value => {
    setMediaToDelete(value);
    setShowModalDelete(true);
  };

  const setFormikValue = (value, input) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  const setMediaOrder = medias => {
    return medias.map((media, index) => {
      media.order = index;
      return media;
    });
  };

  useEffect(() => {
    const sortedMedia = setMediaOrder(contentState.medias);
    formik.values.name = contentState.name;
    formik.values.bannerType = contentState.bannerType;
    formik.values.medias = sortedMedia;
    formik.values.configs = contentState.configs;
    setTableBanners(sortedMedia);
  }, []);

  const setValuesConfig = value => {
    formik.values.configs = value;
    setContentState({
      name: contentState.name,
      bannerType: contentState.bannerType,
      medias: contentState.medias,
      configs: value,
    });
  };

  return (
    <>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalSuccess}
          onClose={() => setShowModalSuccess(false)}
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">Informações salvas com sucesso</p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalSuccess(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </style.Modal>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalDelete}
          onClose={() => setShowModalDelete(false)}
        >
          <div className="content">
            <InfraIcons name="Info" color={Colors.information_100} size={54} />
            <p className="description">
              Você realmente deseja excluir os dados?
            </p>
          </div>
          <div className="button-modal">
            <InfraButton
              style={{
                color: Colors.information_100,
                borderColor: Colors.information_100,
              }}
              iconRight
              fullWidth
              outline
              size="medium"
              onClick={() => setShowModalDelete(false)}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{ background: Colors.information_100 }}
              iconRight
              fullWidth
              size="medium"
              onClick={() => deleteBannerMidia()}
            >
              Sim
            </InfraButton>
          </div>
        </InfraModal>
      </style.Modal>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{messageModalError}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </style.Modal>
      <style.Wrapper>
        <InfraGrid className="header">
          <InfraCol xxl="12" xl="8" lg="8" md="8" xs="4">
            <h1 className="title">Criação e Associação de Banners</h1>
            <div className="back-page" onClick={backTolastPage}>
              <InfraIcons
                name="ArrowUUpLeft"
                color={Colors.information_100}
                size={16}
              />
              <p className="text">Voltar</p>
            </div>
          </InfraCol>
        </InfraGrid>
        <InfraGrid>
          <InfraCol xxl="12" xl="12" lg="8" md="8" xs="4">
            <h2 className="subtitle">Informações do banner</h2>
          </InfraCol>
        </InfraGrid>
        <form onSubmit={save} ref={formRef}>
          <InfraGrid className="inputs-page">
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <InfraTextField
                label="Nome"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                message={formik.errors.name}
                inputStyle={inputStyle.name}
                placeholder="Nome"
              />
            </InfraCol>
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <label className="input-label">Tipo</label>
              <InfraSelect
                name="bannerType"
                placeholder="Selecione"
                handlerClick={event =>
                  setFormikValue(event.value, 'bannerType')
                }
                items={mockITypes}
              />
              <p className="input-error">{formik.errors.bannerType}</p>
            </InfraCol>
          </InfraGrid>
          <InfraGrid style={{ marginTop: '30px' }}>
            <InfraCol xxl="10" xl="6" lg="5" md="4" xs="1">
              <h2 className="subtitle">Mídias</h2>
            </InfraCol>
            <InfraCol
              xxl="2"
              xl="2"
              lg="3"
              md="4"
              xs="3"
              className="buttons_subtitles"
            >
              <style.Button
                iconRight
                size="medium"
                icon="Plus"
                onClick={() => goToRegisterMidia()}
                type="submit"
              >
                Adicionar Mídia
              </style.Button>
            </InfraCol>
          </InfraGrid>
          <InfraGrid>
            <InfraCol xxl="12" xl="8" lg="8" md="8" xs="4" className="table">
              <InfraTable
                value={tableBanners}
                draggable="true"
                dndOnChange={addOrderInData}
                messageEmptyData="Dados não encontrados"
              >
                <InfraTableColumn field="title" header="Titulo" />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="edit"
                  body={renderEditIcon}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="delete"
                  body={renderDeleteIcon}
                />
              </InfraTable>
            </InfraCol>
          </InfraGrid>
          <InfraGrid className="container_adictional_config">
            <InfraCol xxl="12" xl="8" lg="8" md="8" xs="4">
              <Accordion
                title="Configurações Adicionais"
                tableData={contentState.configs}
                setTableData={value => setValuesConfig(value)}
                hasTitleComponentWithIconPlus="Cadastrar nova configuração"
              />
            </InfraCol>
          </InfraGrid>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <style.Button
              type="submit"
              iconRight
              size="medium"
              icon="Check"
              onClick={save}
            >
              Salvar
            </style.Button>
          </div>
        </form>
      </style.Wrapper>
    </>
  );
}
