import { useMemo } from 'react';
import { useTenant } from '../../Contexts/TenantContext';
import useAuthMocked from './useAuthMocked';

const useHome = () => {
  useAuthMocked();
  const { setChannel, channel, channels } = useTenant();
  const formattedChannels = useMemo(() => {
    return channels.map(currentChannel => ({
      ...currentChannel,
      selected: currentChannel.value === channel,
    }));
  }, [channels, channel]);

  return { channels: formattedChannels, setChannel };
};

export default useHome;
