import * as Yup from 'yup';

export const createPageValidationsSchema = Yup.object().shape({
  title: Yup.string()
    .max(
      150,
      'O tamanho máximo do título da página, deve ser de 150 caracteres, incluindo espaços.',
    )
    .required('Favor definir um título para a página.'),
  canonical: Yup.string()
    .matches(
      /^[a-z0-9-/]*$/,
      'A URL da página não deve possuir espaços em branco, letras maiúsculas, acentos ou caracteres especiais (exceto hífen).',
    )
    .max(64, 'O tamanho máximo da URL da página, deve ser de 64 caracteres.')
    .required('Favor definir uma URL para a página.'),
  header: Yup.string()
    .max(47, 'O atributo Cabeçalho não pode conter mais de 47 caracteres.')
    .nullable(),
  description: Yup.string().nullable(),
  identifier: Yup.string().when('opt', {
    is: opt => opt && opt !== 'null',
    then: Yup.string()
      .nullable()
      .required(
        'Favor preencher o identificador da  marca ou categoria ou sku.',
      ),
    otherwise: Yup.string().nullable(),
  }),
});
