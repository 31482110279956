import React from 'react';
import { InfraDatepicker } from '@infralabs/design-system';
import * as s from './styles';

type Props = Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'value'> & {
  name: string;
  maskDate: string;
  label: string;
  error?: string;
  showTimeSelect?: boolean;
  value?: string;
  onChange?: (e: Date) => void;
};

export function InfraDatePicker({
  name,
  maskDate = 'dd/MM/yyyy',
  label,
  error,
  showTimeSelect = false,
  onChange,
  value,
  ...props
}: Props) {
  return (
    <s.Container>
      <label htmlFor={name}>{label}</label>
      <InfraDatepicker
        id={name}
        name={name}
        label="Data início"
        maskDate={maskDate}
        placeholder="Nome"
        autoComplete="off"
        showTimeSelect={showTimeSelect}
        onChange={onChange}
        value={value ? new Date(value) : undefined}
        {...props}
      />
      {error && <span className="error-message">{error}</span>}
    </s.Container>
  );
}
