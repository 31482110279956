import {
  InfraBreadcrumbs,
  InfraCol,
  InfraDatepicker,
  InfraGrid,
  InfraIcons,
  InfraLabel,
  InfraSelect,
  InfraSwitch,
  InfraTextField,
} from '@infralabs/design-system';
import { useFormik } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { RadioButton } from '../../../../components';
import Accordion from '../../../../components/AdditionalSettings';
import { useNavigationContext } from '../../../../Contexts/NavigationItemContext';
import { uuid } from '../../../../Helper/uuid';
import { Colors } from '../../../../theme';
import ModalAlert, { ModalAlertHandle } from '../../components/modalAlert';
import { breadCrumbRouteLevel1 } from '../../config';
import Upload, { UploadHandle } from '../components/Upload';
import { BackLink } from '../style';
import { Button, Container, RadioGroup, Wrapper } from './style';
import { URLParams } from './types';
import { validationSchema } from './validation';

type ITextFieldTypePropsInputStyle = 'error' | 'normal' | 'success' | 'warning'; // TODO: tipagem correta para o campo, até extrair o valor do DS ITextFieldTypeProps.inputStyle

const targetItemsSelectOptions = [
  {
    disabled: true,
    label: 'Selecione',
    value: '',
  },
  {
    label: '_self',
    value: '_self',
  },
  {
    label: '_blank',
    value: '_blank',
  },
  {
    label: '_parent',
    value: '_parent',
  },
  {
    label: '_top',
    value: '_top',
  },
];

const inputSize = 'small';

const findItemById = (item, itemId) => {
  if (itemId === 'add') {
    return { id: uuid() };
  }

  if (item?.id === itemId) {
    return item;
  }

  if (item?.items?.length) {
    for (const child of item?.items) {
      const found = findItemById(child, itemId);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

export default function NewNavigationItem() {
  const history = useHistory();
  const uploadRef = useRef<UploadHandle>(null);
  const modalAlertRef = useRef<ModalAlertHandle>(null);
  const { navigationId, itemId } = useParams<URLParams>();
  const [state, dispatch] = useNavigationContext();
  const [showDatePickers, setShowDatePickers] = useState(false);

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const getData = useCallback(() => {
    return findItemById(state, itemId);
  }, [itemId]);

  const data = getData();

  useEffect(() => {
    setShowDatePickers(
      !(
        (!data.dateStart && !data.dateEnd) ||
        (data.dateStart === '1970-01-01T00:00:00.000Z' &&
          data.dateEnd === '2999-12-31T00:00:00.000Z')
      ),
    );
  }, []);

  const getNextOrderPosition = () => {
    if (!state?.items?.length) {
      return 0;
    }
    return (
      state.items?.reduce(
        (order, item) => Math.max(order, item.order ?? 0),
        -1,
      ) + 1
    );
  };

  const backTolastPage = (e = null) => {
    if (e) {
      e.preventDefault();
    }
    history.push(`/navigations/${navigationId}`);
  };

  if (!data?.id) {
    // TODO: prever no futuro um formato de alerta para o usuário antes de redorecioná-lo, mas sem travar a tela.
    backTolastPage();
    return null;
  }

  const formRef = useRef(null);
  let optInitValue = '';
  let identifierInitValue = null;
  let dateStart = null;
  let dateEnd = null;
  if (data.brandId) {
    optInitValue = 'brandId';
    identifierInitValue = data.brandId;
  }
  if (data.categoryId) {
    optInitValue = 'categoryId';
    identifierInitValue = data.categoryId;
  }
  if (data.skuId) {
    optInitValue = 'skuId';
    identifierInitValue = data.skuId;
  }
  if (data.pageId) {
    optInitValue = 'pageId';
    identifierInitValue = data.pageId;
  }
  if (data.href) {
    optInitValue = 'href';
  }
  if (
    (!data.dateStart && !data.dateEnd) ||
    (data.dateStart === '1970-01-01T00:00:00.000Z' &&
      data.dateEnd === '2999-12-31T00:00:00.000Z')
  ) {
    dateStart = new Date(
      today.getTime() - today.getTimezoneOffset() * 60000,
    ).toISOString();
    dateEnd = new Date(
      tomorrow.getTime() - tomorrow.getTimezoneOffset() * 60000,
    ).toISOString();
  } else {
    dateStart = data.dateStart;
    dateEnd = data.dateEnd;
  }
  const formik = useFormik({
    initialValues: {
      ...data,
      opt: optInitValue,
      identifier: identifierInitValue,
      dateStart,
      dateEnd,
    },
    validationSchema,
    onSubmit: async formData => {
      const {
        id,
        brandId,
        categoryId,
        href,
        items,
        order,
        pageId,
        skuId,
        target,
        text,
        dateStart,
        dateEnd,
      } = formData;
      formik.validateForm();

      if (!formik.isValid) {
        return;
      }
      let file;
      let imageUrl = '';
      if (uploadRef.current?.hasFile()) {
        file = await uploadRef.current?.uploadFile();
        if (!file?.data?.uri && file?.message) {
          modalAlertRef.current.setContent(
            `Falha ao realizar upload: ${file.message}`,
          );
          modalAlertRef.current.openModal({
            icon: 'error',
          });
          return;
        }
        imageUrl = file?.data?.uri;
      }

      const { opt, identifier, ...rest } = formData;
      let targetOption = '_self';
      if (opt === 'href') targetOption = target;
      if (opt === '') targetOption = null;

      const payload = {
        ...rest,
        configs: data.configs || [],
        imageUrl,
        href: opt === 'href' ? href : null,
        target: targetOption,
        text: text || '',
        order: order === undefined ? getNextOrderPosition() : order,
        items: items || [],
        brandId: opt === 'brandId' ? String(identifier) : null,
        categoryId: opt === 'categoryId' ? String(identifier) : null,
        pageId: opt === 'pageId' ? String(identifier) : null,
        skuId: opt === 'skuId' ? String(identifier) : null,
        dateStart: showDatePickers ? dateStart : '1970-01-01T00:00:00.000Z',
        dateEnd: showDatePickers ? dateEnd : '2999-12-31T00:00:00.000Z',
      };

      dispatch({
        type: itemId === 'add' ? 'addItem' : 'updateDeepTreeItem',
        payload,
      });

      Object.assign(data, payload);
      backTolastPage();
    },
  });

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const setInputStyle = (
    value: string,
    type: string,
  ): ITextFieldTypePropsInputStyle => {
    if (!value) {
      return;
    }
    if (
      (value.length >= 50 && type === 'text') ||
      (value.length === 0 && type === 'text' && formik.touched.text)
    ) {
      return 'error';
    }
    if (value.length >= 100 && type === 'href') {
      return 'error';
    }
  };

  return (
    <form onSubmit={save} ref={formRef}>
      <Container>
        <InfraBreadcrumbs
          links={breadCrumbRouteLevel1(history).concat([
            {
              disabled: true,
              href: '#',
              text: `Item: ${
                navigationId === 'add' ? 'Novo Item' : state?.title ?? ''
              }`,
            },
          ])}
        />
        <h1>Criar Novo Item</h1>
        <div>
          <BackLink href="#" onClick={backTolastPage} className="back-page">
            <InfraIcons
              name="ArrowUUpLeft"
              weight="bold"
              color={Colors.information_100}
              size={16}
            />
            <span className="text">Voltar</span>
          </BackLink>
        </div>
        <Wrapper>
          <InfraGrid>
            <InfraCol xs="12" md="4">
              <InfraTextField
                placeholder="Seu texto"
                height={inputSize}
                name="text"
                label="Título"
                value={formik.values.text}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputStyle={setInputStyle(formik.values.text, 'text')}
              />
              <span className="error-message-field">{formik.errors.text}</span>
            </InfraCol>

            <InfraCol xs="12" md="8">
              <RadioGroup>
                <RadioButton
                  label="Marca"
                  name="opt"
                  value="brandId"
                  onChange={e => {
                    formik.handleChange(e);
                    formik.setFieldValue('identifier', '');
                  }}
                  checked={formik.values.opt === 'brandId'}
                />
                <RadioButton
                  label="Categoria"
                  name="opt"
                  value="categoryId"
                  onChange={e => {
                    formik.handleChange(e);
                    formik.setFieldValue('identifier', '');
                  }}
                  checked={formik.values.opt === 'categoryId'}
                />
                <RadioButton
                  label="Sku"
                  name="opt"
                  value="skuId"
                  onChange={e => {
                    formik.handleChange(e);
                    formik.setFieldValue('identifier', '');
                  }}
                  checked={formik.values.opt === 'skuId'}
                />
                <RadioButton
                  label="Página"
                  name="opt"
                  value="pageId"
                  onChange={e => {
                    formik.handleChange(e);
                    formik.setFieldValue('identifier', '');
                  }}
                  checked={formik.values.opt === 'pageId'}
                />
                <RadioButton
                  label="Link"
                  name="opt"
                  value="href"
                  onChange={e => {
                    formik.handleChange(e);
                    formik.setFieldValue('target', null);
                  }}
                  checked={formik.values.opt === 'href'}
                />
                <RadioButton
                  label="Nenhum"
                  name="opt"
                  value=""
                  onChange={formik.handleChange}
                  checked={formik.values.opt === ''}
                />
              </RadioGroup>
            </InfraCol>

            <InfraCol xs="12" style={{ display: 'flex' }}>
              <InfraLabel theme="light">Período de vigência</InfraLabel>
              <InfraSwitch
                checked={showDatePickers}
                onChange={e => {
                  setShowDatePickers(!showDatePickers);
                }}
              />
            </InfraCol>
            {showDatePickers && (
              <InfraCol xs="12" style={{ display: 'flex', gap: '1.25rem' }}>
                <div style={{ width: '25%' }}>
                  <InfraLabel theme="light">Data de ativação</InfraLabel>
                  <InfraDatepicker
                    name="dateStart"
                    height={inputSize}
                    shouldCloseOnSelect
                    maskDate="dd.MM.yyyy, HH:mm"
                    theme="light"
                    setHours
                    placeholder="Data Inicial"
                    value={new Date(formik.values.dateStart)}
                    onChange={e => {
                      formik.setFieldValue(
                        'dateStart',
                        new Date(
                          e.getTime() - e.getTimezoneOffset() * 60000,
                        ).toISOString(),
                      );
                    }}
                  />

                  <span className="error-message-field">
                    {formik.errors.dateStart}
                  </span>
                </div>
                <div style={{ width: '25%' }}>
                  <InfraLabel theme="light">Data de desativação</InfraLabel>
                  <InfraDatepicker
                    name="dateEnd"
                    height={inputSize}
                    shouldCloseOnSelect
                    maskDate="dd.MM.yyyy, HH:mm"
                    theme="light"
                    setHours
                    placeholder="Data Final"
                    value={new Date(formik.values.dateEnd)}
                    onChange={e => {
                      formik.setFieldValue(
                        'dateEnd',
                        new Date(
                          e.getTime() - e.getTimezoneOffset() * 60000,
                        ).toISOString(),
                      );
                    }}
                  />

                  <span className="error-message-field">
                    {formik.errors.dateEnd}
                  </span>
                </div>
              </InfraCol>
            )}

            {['brandId', 'categoryId', 'skuId', 'pageId'].includes(
              formik.values.opt,
            ) && (
              <InfraCol xs="12" md="4">
                <InfraTextField
                  type={formik.values.opt === 'pageId' ? 'text' : 'number'}
                  placeholder="Seu texto"
                  height={inputSize}
                  name="identifier"
                  label="Identificador"
                  value={formik.values.identifier}
                  onChange={e => {
                    if (!e.target.value) {
                      formik.setFieldValue('target', '');
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  inputStyle={setInputStyle(
                    formik.values.identifier,
                    'identifier',
                  )}
                />
                <span className="error-message-field">
                  {formik.errors.identifier}
                </span>
              </InfraCol>
            )}

            {formik.values.opt === 'href' && (
              <>
                <InfraCol xs="12" md="4">
                  <InfraTextField
                    placeholder="Seu texto"
                    height={inputSize}
                    name="href"
                    label="Link"
                    value={formik.values.href}
                    onChange={e => {
                      if (!e.target.value) {
                        formik.setFieldValue('target', '');
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    inputStyle={setInputStyle(formik.values.href, 'href')}
                  />
                  <span className="error-message-field">
                    {formik.errors.href}
                  </span>
                </InfraCol>

                <InfraCol xs="12" md="4">
                  <InfraLabel theme="light">Target</InfraLabel>
                  <InfraSelect
                    size={inputSize}
                    placeholder="Selecione"
                    items={targetItemsSelectOptions.map(item => {
                      return {
                        ...item,
                        selected: item.value === formik.values.target,
                      };
                    })} // TODO: customização aqui pois o componente select não está programado corretamente para receber um initial Value. Precisa ser ajustado posteiormente o componente para remover esse .map()
                    handlerClick={e => {
                      formik.setFieldValue('target', e.value);
                    }}
                    disable={!formik.values.href}
                  />
                  <span className="error-message-field">
                    {formik.errors.target}
                  </span>
                </InfraCol>
              </>
            )}

            <InfraCol xs="12">
              <Upload
                existentFile={formik.values.imageUrl}
                maxSize={10000000}
                ref={uploadRef}
              />
            </InfraCol>
          </InfraGrid>
        </Wrapper>
        <Accordion
          title="Configurações Adicionais"
          tableData={data.configs || []}
          setTableData={newConfigs => {
            if (itemId === 'add') {
              dispatch({
                type: 'addItem',
                payload: {
                  ...data,
                  configs: newConfigs,
                },
              });
              history.push(`/navigations/${navigationId}/${data.id}`);
              return;
            }

            dispatch({
              type: 'updateDeepTreeItem',
              payload: {
                ...data,
                configs: newConfigs,
              },
            });
          }}
        />

        <InfraCol xs={12} style={{ textAlign: 'center' }}>
          <Button
            type="submit"
            icon="Check"
            iconRight
            size="medium"
            style={{ background: '#1f2c3f' }}
            disabled={!formik.isValid}
          >
            Salvar
          </Button>
        </InfraCol>
      </Container>
      <ModalAlert
        ref={modalAlertRef}
        showConfirmButton
        textConfirmButton="OK"
      />
    </form>
  );
}
