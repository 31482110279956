import styled from 'styled-components';

export const ContainerUpload = styled.div`
  position: relative;

  .loading-box {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #ffffffeb;
    text-align: center;

    .icon-warning {
      > div {
        margin: 0 auto;
      }
    }
  }

  &.status-uploading {
    .loading-box {
      visibility: visible;
    }
  }
`;
