import styled from 'styled-components';
import { InfraButton } from '@infralabs/design-system';
import { Spacings, Shadows, Colors, Typography as Ty } from '../../../theme';

export const Container = styled.main`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2.5rem 0rem;
`;

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
`;

export const Form = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  width: 100%;

  .label-input {
    display: flex;
    flex-direction: column;

    label {
      font-size: ${Spacings.spacing_14};
      font-family: ${Ty.h1.fontFamily};
      padding-right: ${Spacings.spacing_2};
      color: ${Colors.shade_100};
      margin-bottom: ${Spacings.spacing_5};
    }
  }

  .label-input-radio {
    display: flex;
    flex-direction: column;

    label {
      font-size: ${Spacings.spacing_14};
      font-family: ${Ty.h1.fontFamily};
      padding-right: ${Spacings.spacing_2};
      color: ${Colors.shade_100};
      margin-top: ${Spacings.spacing_12};
    }
  }
`;

export const ColumnDivider = styled.div`
  border: 0.5px solid ${Colors.neutral_200};
  margin: 0 20px 0 20px;
`;

export const FieldsTitle = styled.h6`
  color: ${Colors.neutral_900};
  font-weight: 600;
  font-size: ${Ty.h6.fontSize};
  font-family: ${Ty.h6.fontFamily};
`;

export const Button = styled(InfraButton)`
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const InputCanonical = styled.div`
  position: relative;

  .bar {
    position: absolute;
    font-size: 17px;
    display: block;
    left: 21px;
    top: 46px;
    z-index: 9;
    color: #414f61;
  }
`;
