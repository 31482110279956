import { Switch, Route, BrowserRouter } from 'react-router-dom';
import Home from '../Pages/Home';
import SkuOrderingDetails from '../Pages/SkuOrderingDetails';
import CreationAssociationBanners from '../Pages/CreationAndAssociationBanners';
import CreateMidia from '../Pages/CreateMedia';
import { PageRoutes } from '../Pages/Page/routes';
import { ContentProvider } from '../providers/bannersProviders';
import { NavigationRoutes } from '../Pages/Navigation/routes';

export default function Routes({ basePath = 'content', location = '' }) {
  return (
    <BrowserRouter basename={basePath}>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Home locationUrl={location} />}
        />
        <Route
          exact
          path="/sku-ordering-details"
          component={() => <SkuOrderingDetails locationUrl={location} />}
        />
        <Route
          path="/navigations"
          component={() => <NavigationRoutes locationUrl={location} />}
        />
        <Route
          path="/pages"
          component={() => <PageRoutes locationUrl={location} />}
        />

        <ContentProvider>
          <Route
            exact
            path="/creation-and-association-banners"
            component={() => (
              <CreationAssociationBanners locationUrl={location} />
            )}
          />
          <Route
            exact
            path="/create-banner-midia"
            component={() => <CreateMidia locationUrl={location} />}
          />
        </ContentProvider>
      </Switch>
    </BrowserRouter>
  );
}
