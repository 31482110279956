import styled from 'styled-components';
import { Colors, Typography, Shadows } from '../../theme';

export const Wrapper = styled.div`
  background: #fafafc;
  height: 100vh;

  p {
    margin: 0;
  }

  .header {
    margin: 0 0 33px 0;
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title {
      ${Typography.h2}
      color: ${Colors.neutral_600};
      margin: 0;
    }
    .back-page {
      cursor: pointer;
      display: flex;
      align-items: center;
      .text {
        margin: 0 0 0 9px;
        ${Typography.body_small}
        color: ${Colors.information_100};
        font-weight: 700;
      }
    }
  }

  .content {
    height: calc(100vh - 157px);
    box-shadow: ${Shadows.shadow_sm};
    background: #fff;
    padding: 45px;
    border-radius: 8px;
    .content-steps {
      margin: 45px 0 0 0;
      .box {
        box-shadow: ${Shadows.shadow_md};
        padding-top: 40px;
        padding-bottom: 40px;
        margin: 26px 0 0 0;
        background: #fafafc;
        .group-button {
          display: flex;
          align-items: center;
          justify-content: right;
        }
      }
    }
  }
`;
