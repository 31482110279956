import axios, { AxiosError } from 'axios';

const baseURL = `${window.IFC_VARIABLES.baseURL}/content/admin`;
const baseURLCatalog = `${window.IFC_VARIABLES.baseURL}/catalog/admin`;

export const getTenant = () => window?.IFC_VARIABLES?.headers?.['x-tenant-id'];

export const getChannel = () =>
  window?.IFC_VARIABLES?.headers?.['x-channel-id'];

export const getSession = () =>
  window?.IFC_VARIABLES?.headers?.['x-session-id'];

export const getCustomer = () =>
  window?.IFC_VARIABLES?.headers?.['x-customer-id'];

export const getApiKey = () => window?.IFC_VARIABLES?.headers?.['api-key'];

const configHeaders = () => {
  return {
    'api-key': localStorage.getItem('@infra-content:apiKey') || getApiKey(),
    'X-Tenant-Id':
      localStorage.getItem('@infra-content:tenantId') || getTenant(),
    'X-Channel-Id':
      localStorage.getItem('@infra-content:channelId') || getChannel(),
    'X-Session-Id':
      localStorage.getItem('@infra-content:sessionId') || getSession(),
    'X-Customer-Id':
      localStorage.getItem('@infra-content:customerId') || getCustomer(),
    'Content-Version':
      localStorage.getItem('@infra-content:contentVersion') ||
      process.env.HEADER_CONTENT_VERSION,
  };
};

const API = axios.create({
  baseURL,
  headers: configHeaders(),
});

const API_WITH_VALIDATION_STATAUS = axios.create({
  validateStatus(status) {
    return status < 500; // Resolve only if the status code is less than 500
  },
  baseURL,
});
API.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

const API_CATALOG = axios.create({
  baseURL: baseURLCatalog,
  headers: configHeaders(),
});
API_CATALOG.interceptors.response.use(
  response => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

const API_UPLOAD_FILES = axios.create({
  baseURL: process.env.BASE_URL_CONTENT_MEDIA,
});

export {
  API,
  API_CATALOG,
  API_UPLOAD_FILES,
  API_WITH_VALIDATION_STATAUS,
  configHeaders,
};
