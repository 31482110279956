import { useEffect, useRef, useState } from 'react';
import {
  InfraTextField,
  InfraSelect,
  InfraModal,
  InfraButton,
} from '@infralabs/design-system';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import * as S from './style';

export function ButtonsMedia({
  showModalButtonsMidia,
  setShowModalButtonsMidia,
  buttonsMediaList,
  setButtonsMediaList,
  buttonDataEdit,
  editIndex,
}: any) {
  const formRef = useRef(null);
  const [dataButton, setDataButton] = useState({
    href: '',
    target: '',
    text: '',
  });

  const validations = Yup.object({
    buttonText: Yup.string()
      .max(
        50,
        'O tamanho máximo do Texto do botão , deve ser de 50 caracteres, incluindo espaços.',
      )
      .required('O Texto do botão deve ser especificado.'),
    buttonHref: Yup.string()
      .max(
        500,
        'O tamanho máximo do Link, deve ser de 500 caracteres, incluindo espaços.',
      )
      .required('O Link do botão deve ser especificado.'),
    buttonTarget: Yup.string().when('buttonsHref', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().required('O Target da mídia deve ser especificado.'),
    }),
  });

  useEffect(() => {
    if (buttonDataEdit && editIndex >= 0) {
      setDataButton({ ...buttonDataEdit });
    } else {
      setDataButton({
        href: '',
        target: '',
        text: '',
      });
    }
  }, [buttonDataEdit, editIndex]);

  useEffect(() => {
    if (!showModalButtonsMidia) {
      formik.resetForm({
        values: {
          ...formik.values,
          buttonText: '',
          buttonHref: '',
          buttonTarget: '',
        },
      });
    }
  }, [showModalButtonsMidia]);

  const formik = useFormik({
    initialValues: {
      buttonText: dataButton.text,
      buttonHref: dataButton.href,
      buttonTarget: dataButton.target,
    },
    enableReinitialize: true,
    validationSchema: validations,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: values => {
      if (typeof editIndex === 'number') {
        buttonsMediaList[editIndex] = {
          text: values.buttonText,
          href: values.buttonHref,
          target: values.buttonTarget,
        };
        setButtonsMediaList(buttonsMediaList);
      } else {
        setButtonsMediaList([
          ...buttonsMediaList,
          {
            text: values.buttonText,
            href: values.buttonHref,
            target: values.buttonTarget,
          },
        ]);
      }

      formik.resetForm({
        values: {
          ...values,
          buttonText: '',
          buttonHref: '',
          buttonTarget: '',
        },
      });
      setDataButton({
        href: '',
        target: '',
        text: '',
      });
      setShowModalButtonsMidia(false);
    },
  });

  const mockItemsSelect = [
    {
      label: '_self',
      value: '_self',
    },
    {
      label: '_blank',
      value: '_blank',
    },
    {
      label: '_parent',
      value: '_parent',
    },
    {
      label: '_top',
      value: '_top',
    },
  ];

  const setInputStyle = (value: string, type: string) => {
    if (
      (value?.length >= 50 && type === 'buttonText') ||
      (value?.length === 0 &&
        type === 'buttonText' &&
        formik.touched.buttonText)
    ) {
      return 'error';
    }
    if (
      (value?.length >= 100 && type === 'buttonHref') ||
      (value?.length === 0 &&
        type === 'buttonHref' &&
        formik.touched.buttonHref)
    ) {
      return 'error';
    }

    return 'normal';
  };

  const setFormikValue = (value: string, input: string) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <div>
      <InfraModal
        title="Botões de Mídia"
        show={showModalButtonsMidia}
        onClose={() => setShowModalButtonsMidia(false)}
      >
        <form onSubmit={save} ref={formRef}>
          <S.Divider />

          <InfraTextField
            placeholder=""
            name="buttonText"
            label="Texto"
            value={formik.values.buttonText}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputStyle={setInputStyle(formik.values.buttonText, 'buttonText')}
            message={formik.errors.buttonText}
          />
          <S.Divider />

          <InfraTextField
            placeholder=""
            name="buttonHref"
            label="Link"
            value={formik.values.buttonHref}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputStyle={setInputStyle(formik.values.buttonHref, 'buttonHref')}
            message={formik.errors.buttonHref}
          />
          <S.Divider />

          <div className="label-input">
            <label>Target</label>
            <InfraSelect
              name="target"
              placeholder={formik.values.buttonTarget || 'Selecione'}
              items={mockItemsSelect}
              handlerClick={event =>
                setFormikValue(event.value, 'buttonTarget')
              }
              disable={formik.values.buttonHref?.length === 0}
            />
            <span className="error-target">{formik.errors.buttonTarget}</span>
          </div>
          <S.Divider />

          <InfraButton
            style={{ background: '#414f62', marginTop: '2rem' }}
            fullWidth
            type="button"
            icon="admin"
            iconRigth
            outline={false}
            disabled={
              formik.values.buttonText?.length === 0 ||
              formik.values.buttonHref?.length === 0 ||
              formik.values.buttonTarget?.length === 0
            }
            onClick={save}
          >
            Salvar
          </InfraButton>
        </form>
      </InfraModal>
    </div>
  );
}
