import styled from 'styled-components';
import { Spacings, Shadows, Colors } from '../../theme';

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
  margin-top: ${Spacings.spacing_32};
`;

export const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  line-height: 1.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  padding-right: 0.125rem;
`;
