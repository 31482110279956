import {
  InfraModal,
  InfraIcons,
  InfraGrid,
  InfraCol,
  InfraTextField,
  InfraTable,
  InfraTableColumn,
  InfraButton,
} from '@infralabs/design-system';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import Accordion, {
  AddicionalSettingsTableDataProps,
} from '../../../../../components/AdditionalSettings';
import * as S from './style';
import { FormValues } from './Type';
import { Colors } from '../../../../../theme';
import { MockTypeBanner, MockInputStyles, getBannerTypeLabel } from './Mocks';
import { DataMedia } from '../../../../CreateMedia';
import { deleteBannerMediaPageUploadFileService } from '../../../../../Services/Uploads';
import { usePage } from '../../../../../hooks/usePage';
import { Divider } from '../../../../../components/Divider';
import {
  InfraDatePicker,
  InfraSelect,
} from '../../../../../components/design-system';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';

export function AddBanner() {
  const { order } = useParams<AddNewPageElementParams>();
  const { bannerState, setBannerState, payload, setPayload, position, pageId } =
    usePage();
  const history = useHistory();
  const formRef = useRef(null);
  const [inputStyle, setInputStyle] = useState(MockInputStyles);
  const [mockITypes] = useState(MockTypeBanner);
  const [tableBanners, setTableBanners] = useState<any>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageModalError, setMessageModalError] = useState('');
  const [bannerName, setBannerName] = useState<string>();
  const [bannerType, setBannerType] = useState<string>();
  const [mediaToDelete, setMediaToDelete] = useState<DataMedia>();

  const handleUpdatePayload = ({
    bannerType,
    configs,
    medias,
    name,
    dateStart,
    dateEnd,
  }: FormValues) => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;
    let typeBanner = 0;
    if (bannerType === 'Hero') typeBanner = 1;
    if (bannerType === 'Splitted') typeBanner = 2;
    if (bannerType === 'Column') typeBanner = 3;
    const formattedMedias = medias.map(media => {
      let { buttons } = media;
      if (buttons && buttons.length === 0) {
        buttons = null;
      }
      return { ...media, buttons };
    });
    const sectionElement = {
      content: null,
      banners: [
        {
          bannerType: typeBanner,
          configs,
          medias: formattedMedias,
          name,
          order: 0,
          dateStart,
          dateEnd,
        },
      ],
      components: [],
      navigations: [],
      shelves: [],
      tipbars: [],
      order: order ? +order : 0,
    };

    switch (position) {
      case 'header':
        if (order) {
          sectionsHeader[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
          sectionsHeader[0] = sectionElement;
        } else {
          sectionElement.order = sectionsHeader.length;
          sectionsHeader.push(sectionElement);
        }
        break;
      case 'body':
        if (order) {
          sectionsBody[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
          sectionsBody[0] = sectionElement;
        } else {
          sectionElement.order = sectionsBody.length;
          sectionsBody.push(sectionElement);
        }
        break;
      case 'footer':
        if (order) {
          sectionsFooter[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
          sectionsFooter[0] = sectionElement;
        } else {
          sectionElement.order = sectionsFooter.length;
          sectionsFooter.push(sectionElement);
        }
        break;

      default:
        break;
    }

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });
    // resetValuesForm();
  };

  function backToLastPage() {
    if (pageId) {
      history.push(`/pages/edit/${pageId}`);
    } else {
      history.push('/pages/create');
    }
  }

  const goToRegisterMidia = () => {
    history.push({
      pathname: '/pages/new-element/banner/add-media',
      state: { tableBanners, bannerName, bannerType },
    });
  };

  const formik = useFormik({
    initialValues: {
      name: bannerState.name,
      bannerType: getBannerTypeLabel(bannerState.bannerType),
      dateStart: bannerState.dateStart,
      dateEnd: bannerState.dateEnd,
      medias: bannerState.medias,
      configs: bannerState.configs,
    },
    validate: (values: FormValues) => {
      setBannerState(values);
      setBannerName(values.name);
      setBannerType(values.bannerType);
      const errors: FormikErrors<FormValues> = {};
      const inputStyle = {
        name: 'normal',
        bannerType: 'normal',
      };
      if (!values.name) {
        errors.name = 'O Nome do banner deve ser especificado.';
        inputStyle.name = 'error';
      }
      if (values.name && values.name.length > 50) {
        errors.name =
          'O tamanho máximo do nome do banner deve ser de 50 caracteres, incluindo espaços.';
        inputStyle.name = 'error';
      }
      if (!values.bannerType) {
        errors.bannerType = 'O Tipo do banner deve ser especificado.';
        inputStyle.bannerType = 'error';
      }
      setInputStyle(inputStyle);
      return errors;
    },
    onSubmit: (values: FormValues) => {
      if (values.dateStart && values.dateEnd) {
        const dateStart = new Date(values.dateStart);
        const dateEnd = new Date(values.dateEnd);
        if (dateEnd < dateStart) {
          setShowModalError(true);
          setMessageModalError(
            `A data de fim da vigência deve ser posterior à data de início da vigência.`,
          );
          return;
        }
      }
      if (values.medias && values.medias.length === 0) {
        if (
          values.bannerType === mockITypes[1].label ||
          values.bannerType === mockITypes[2].label
        ) {
          setMessageModalError(
            `Um banner do tipo “${values.bannerType}” precisa ter pelo menos duas mídias vinculadas.`,
          );
        } else {
          setMessageModalError(
            `Um banner do tipo “${values.bannerType}” precisa ter pelo menos uma midia vinculada.`,
          );
        }
        setShowModalError(true);
      } else if (values.bannerType) {
        if (
          values.bannerType === mockITypes[0].label &&
          values.medias.length > 1
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo “${mockITypes[0].label}” deve possuir somente uma mídia vinculada.`,
          );
        } else if (
          values.bannerType === mockITypes[1].label &&
          values.medias.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo ${mockITypes[1].label} ou ${mockITypes[2].label} deve possuir pelo menos duas mídias vinculadas.`,
          );
        } else if (
          values.bannerType === mockITypes[2].label &&
          values.medias.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo ${mockITypes[1].label} ou ${mockITypes[2].label} deve possuir pelo menos duas mídias vinculadas.`,
          );
        } else if (
          values.bannerType === mockITypes[3].label &&
          values.medias.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um banner do tipo ${mockITypes[1].label} ou ${mockITypes[2].label} ou ${mockITypes[3].label} deve possuir pelo menos duas mídias vinculadas.`,
          );
        } else {
          handleUpdatePayload(values);
          backToLastPage();
        }
      }
    },
  });

  const renderEditIcon = values => {
    return (
      <div style={{ padding: '2px' }} onClick={() => editMidia(values)}>
        <InfraIcons name="PencilSimple" size={14} />
      </div>
    );
  };

  const renderDeleteIcon = values => {
    return (
      <div
        style={{ padding: '2px' }}
        onClick={() => openModaldeleteMidia(values)}
      >
        <InfraIcons name="TrashSimple" size={14} />
      </div>
    );
  };

  const addOrderInData = useCallback(
    (value = tableBanners) => {
      const sortedMedia = setMediaOrder(value);
      formik.values.medias = sortedMedia;
      setBannerState({
        name: bannerState.name,
        bannerType: bannerState.bannerType,
        medias: sortedMedia,
        configs: bannerState.configs,
        dateStart: bannerState.dateStart,
        dateEnd: bannerState.dateEnd,
      });
      setTableBanners(sortedMedia);
    },
    [tableBanners],
  );

  const deleteBannerMidia = () => {
    const tableMediasFiltered = tableBanners.filter(banner => {
      if (banner.title !== mediaToDelete.title) {
        return banner;
      }
    });

    if (
      mediaToDelete.fileImage &&
      mediaToDelete.fileImage.length > 0 &&
      mediaToDelete.fileImage
    ) {
      deleteBannerMediaPageUploadFileService(mediaToDelete.fileImage[0].fileId);
    }

    if (mediaToDelete.fileVideo && mediaToDelete.fileVideo.length > 0) {
      deleteBannerMediaPageUploadFileService(mediaToDelete.fileVideo[0].fileId);
    }
    formik.values.medias = tableMediasFiltered;
    setTableBanners(tableMediasFiltered);
    setShowModalDelete(false);
    setBannerState({
      name: bannerState.name,
      bannerType: bannerState.bannerType,
      medias: tableMediasFiltered,
      configs: bannerState.configs,
      dateStart: bannerState.dateStart,
      dateEnd: bannerState.dateEnd,
    });
    addOrderInData(tableMediasFiltered);
  };

  const save = (e: any) => {
    formik.handleSubmit();
    e.preventDefault();
  };

  const editMidia = (values: AddicionalSettingsTableDataProps) => {
    const index = tableBanners.findIndex(
      (data: AddicionalSettingsTableDataProps) => data === values,
    );

    history.push({
      pathname: `/pages/new-element/banner/edit-media/${order || 0}/${index}`,
      state: {
        indexEdit: index,
        isEdit: true,
        bannerName,
        bannerType,
        tableBanners,
      },
    });
  };

  const openModaldeleteMidia = value => {
    setMediaToDelete(value);
    setShowModalDelete(true);
  };

  const setFormikValue = (value, input) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  const setMediaOrder = medias => {
    return medias.map((media, index) => {
      media.order = index;
      return media;
    });
  };

  useEffect(() => {
    const sortedMedia = setMediaOrder(bannerState.medias);
    formik.values.name = bannerState.name;
    formik.values.bannerType = getBannerTypeLabel(bannerState.bannerType);
    formik.values.medias = sortedMedia;
    formik.values.configs = bannerState.configs;
    setTableBanners(sortedMedia);

    // keep setTimeout so the function runs after the DOM is rendered, otherwise it won't work
    setTimeout(() => {
      const handles = document.querySelectorAll<HTMLElement>(
        'td[draggable="true"]',
      );
      handles.forEach(handle => {
        handle.style.cursor = 'move';
      });
    }, 0);
  }, []);

  const setValuesConfig = value => {
    formik.values.configs = value;
    setBannerState({
      name: bannerState.name,
      bannerType: bannerState.bannerType,
      medias: bannerState.medias,
      configs: value,
      dateStart: bannerState.dateStart,
      dateEnd: bannerState.dateEnd,
    });
  };

  return (
    <>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalSuccess}
          onClose={() => setShowModalSuccess(false)}
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">Informações salvas com sucesso</p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalSuccess(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalDelete}
          onClose={() => setShowModalDelete(false)}
        >
          <div className="content">
            <InfraIcons name="Info" color={Colors.information_100} size={54} />
            <p className="description">
              Você realmente deseja excluir os dados?
            </p>
          </div>
          <div className="button-modal">
            <InfraButton
              style={{
                color: Colors.information_100,
                borderColor: Colors.information_100,
              }}
              iconRight
              fullWidth
              outline
              size="medium"
              onClick={() => setShowModalDelete(false)}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{ background: Colors.information_100 }}
              iconRight
              fullWidth
              size="medium"
              onClick={() => deleteBannerMidia()}
            >
              Sim
            </InfraButton>
          </div>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{messageModalError}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <form onSubmit={save} ref={formRef}>
        <S.Wrapper>
          <InfraGrid className="inputs-page" style={{ paddingInline: 0 }}>
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <InfraTextField
                label="Nome"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                message={formik.errors.name}
                inputStyle={inputStyle.name}
                placeholder="Nome"
              />
            </InfraCol>
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <InfraSelect
                label="Tipo"
                name="bannerType"
                placeholder="Selecione"
                handlerClick={event => {
                  setFormikValue(event.value, 'bannerType');
                }}
                items={mockITypes.map(item => {
                  return {
                    ...item,
                    selected: item.label === formik.values.bannerType,
                  };
                })}
                error={formik.errors.bannerType}
              />
            </InfraCol>
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <InfraDatePicker
                label="Data início"
                maskDate="dd/MM/yyyy HH:mm"
                name="dateStart"
                value={formik.values.dateStart}
                onChange={e => {
                  const date = new Date(e);
                  const dateStart = date.toISOString();
                  formik.setFieldValue('dateStart', dateStart);
                  setBannerState(state => ({ ...state, dateStart }));
                }}
                error={formik.errors.dateStart}
                placeholder="Data início"
                showTimeSelect
              />
            </InfraCol>
            <InfraCol xxl="4" xl="2" lg="2" md="4" xs="2">
              <InfraDatePicker
                label="Data fim"
                maskDate="dd/MM/yyyy HH:mm"
                name="dateEnd"
                value={formik.values.dateEnd}
                onChange={e => {
                  const date = new Date(e);
                  const dateEnd = date.toISOString();
                  formik.setFieldValue('dateEnd', dateEnd);
                  setBannerState(state => ({ ...state, dateEnd }));
                }}
                error={formik.errors.dateEnd}
                placeholder="Data fim"
                showTimeSelect
              />
            </InfraCol>
          </InfraGrid>
          <Divider style={{ marginTop: '32px' }} />
          <InfraGrid style={{ marginTop: '30px', paddingInline: 0 }}>
            <InfraCol xxl="10" xl="6" lg="5" md="4" xs="1" />
            <InfraCol
              xxl="2"
              xl="2"
              lg="3"
              md="4"
              xs="3"
              className="buttons_subtitles"
            >
              <S.Button
                iconRight
                icon="Plus"
                onClick={() => goToRegisterMidia()}
                type="submit"
              >
                Cadastrar nova mídia
              </S.Button>
            </InfraCol>
          </InfraGrid>
          <InfraGrid style={{ paddingInline: 0 }}>
            <InfraCol xxl="12" xl="8" lg="8" md="8" xs="4" className="table">
              <InfraTable
                value={tableBanners}
                draggable="true"
                dndOnChange={addOrderInData}
                messageEmptyData="Dados não encontrados"
              >
                <InfraTableColumn
                  field="title"
                  header="Titulo"
                  style={{
                    cursor: 'default',
                  }}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="edit"
                  body={renderEditIcon}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="delete"
                  body={renderDeleteIcon}
                />
              </InfraTable>
            </InfraCol>
          </InfraGrid>
        </S.Wrapper>
        <Accordion
          title="Configurações Adicionais"
          tableData={bannerState.configs}
          setTableData={value => setValuesConfig(value)}
          hasTitleComponentWithIconPlus="Cadastrar nova configuração"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '32px',
          }}
        >
          <S.Button
            type="submit"
            iconRight
            size="medium"
            icon="Check"
            onClick={save}
          >
            Salvar
          </S.Button>
        </div>
      </form>
    </>
  );
}
