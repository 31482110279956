import { useHistory } from 'react-router-dom';
import {
  InfraGrid,
  InfraButton,
  InfraIcons,
  InfraTag,
} from '@infralabs/design-system';

import * as style from './style';
import { Colors } from '../../theme';

type PageHeaderProps = {
  title: string;
  path?: string;
  backPath?: string;
  buttonText?: string;
  tag?: string;
};

export default function PageHeader({
  title,
  path,
  backPath,
  buttonText,
  tag,
}: PageHeaderProps) {
  const history = useHistory();

  const backToLastPage = () => {
    if (backPath) history.push(`${backPath}`);
    else history.goBack();
  };

  return (
    <style.PageHeader>
      <InfraGrid className="header">
        <style.Column>
          <style.TitleWrapper>
            <style.Title className="title">{title}</style.Title>
            {tag && (
              <style.TitleTag>
                <InfraTag name={tag} color="success" />
              </style.TitleTag>
            )}
          </style.TitleWrapper>

          <style.BackPage onClick={backToLastPage}>
            <InfraIcons
              name="ArrowUUpLeft"
              weight="bold"
              color={Colors.information_100}
              size={16}
            />
            <p className="text">Voltar</p>
          </style.BackPage>
        </style.Column>

        {path && (
          <style.Column>
            <div className="button">
              <InfraButton
                icon="Plus"
                iconRight
                size="medium"
                outline
                onClick={() => history.push(`${path}`)}
              >
                {buttonText}
              </InfraButton>
            </div>
          </style.Column>
        )}
      </InfraGrid>
    </style.PageHeader>
  );
}
