import { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import { InfraTextField, InfraCol } from '@infralabs/design-system';
import { useNavigationContext } from '../../../../Contexts/NavigationItemContext';
import { validationSchema } from '../validation';
import { FormHandle } from '../types.d';
import { Form as StyledForm } from '../style';

type FormProps = {
  isEditing: boolean;
};

const Form = forwardRef<FormHandle, FormProps>((data, ref) => {
  const [state, dispatch] = useNavigationContext();

  const removeNestedProperty = arrayNested => {
    if (arrayNested !== undefined) {
      return arrayNested.map(obj => {
        if (obj.items) {
          removeNestedProperty(obj.items);
        }
        delete obj.segment;
        return obj;
      });
    }
  };

  const newState = {
    ...state,
    items: removeNestedProperty(state.items),
  };

  const formik = useFormik({
    initialValues: {
      title: state.title || '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ title }) => {
      const data = {
        title: title || null,
      };

      dispatch({
        type: 'setData',
        payload: {
          ...newState,
          ...data,
        },
      });

      // formik.resetForm({
      //   values: {
      //     title: "",
      //   }
      // })

      // history.push({
      //   pathname: "/navigations",
      // })
    },
  });

  useImperativeHandle(ref, () => ({
    isValid: () => {
      return formik.isValid;
    },
    validateForm: async () => {
      return formik.validateForm();
    },
  }));

  const onSubmit = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
        <InfraTextField
          style={{ width: '250px' }}
          height="medium"
          name="title"
          inputStyle={formik.errors.title ? 'error' : 'normal'}
          label="Título"
          value={formik.values.title}
          onChange={formik.handleChange}
          message={formik.errors.title}
          placeholder="Título para o item de navegação"
          type="text"
          onBlur={() => {
            formik.handleSubmit();
          }}
        />
      </InfraCol>
    </StyledForm>
  );
});

export default Form;
