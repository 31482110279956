import * as Yup from 'yup';

export const validationSchema = Yup.object({
  text: Yup.string()
    .required('O Texto do item de navegação deve ser especificado.')
    .max(
      50,
      'O tamanho máximo do Texto do item de navegação, deve ser de 50 caracteres, incluindo espaços.',
    ),
  opt: Yup.string(),
  identifier: Yup.mixed()
    .when('opt', {
      is: (val: string) =>
        ['brandId', 'categoryId', 'skuId', 'pageId'].includes(val),
      then: Yup.mixed().required('Campo obrigatório').nullable(),
    })
    .nullable(),
  href: Yup.string()
    .when('opt', {
      is: 'href',
      then: Yup.string()
        .max(
          500,
          'O tamanho máximo do Texto do item de navegação, deve ser de 500 caracteres',
        )
        .required('Campo obrigatório')
        .nullable(),
    })
    .nullable(),
  target: Yup.string()
    .when('href', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string()
        .required('O Target do item de navegação deve ser especificado.')
        .nullable(),
    })
    .nullable(),
  dateStart: Yup.date().required('A data de ativação deve ser preenchida.'),
  dateEnd: Yup.date()
    .required('A data de desativação deve ser preenchida.')
    .when(
      'dateStart',
      (dateStart, yup) =>
        dateStart &&
        yup.min(
          dateStart,
          'data de desativação deve ser posterior à data de ativação',
        ),
    ),
});
