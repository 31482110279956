const Typography = {
  display_01: {
    fontSize: '4.5rem',
    lineHeight: '5.125rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  display_02: {
    fontSize: '3.75rem',
    lineHeight: '4.5rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  body_large: {
    fontSize: '1rem',
    lineHeight: '2.75rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  body_small: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  caption_large: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  caption_small: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  text_footer: {
    fontSize: '0.6875rem',
    lineHeight: '1rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h1: {
    fontSize: '3rem',
    lineHeight: '3.5rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h1_small: {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h2: {
    fontSize: '2.125rem',
    lineHeight: '2.5rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h2_small: {
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h3: {
    fontSize: '1.5rem',
    lineHeight: '1.875rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h3_small: {
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h4: {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h4_small: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h5: {
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h5_small: {
    fontSize: '0.875rem',
    lineHeight: '1.625rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h6: {
    fontSize: '0.875rem',
    lineHeight: '1.625rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },

  h6_small: {
    fontSize: '0.75rem',
    lineHeight: '1.125rem',
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  },
};

export default Typography;
