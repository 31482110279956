import { useEffect } from 'react';

const isLocal = process.env.ENVIRONMENT === 'local';
const tenantId =
  localStorage.getItem('@infra-content:tenantId') || '61940ba2e689060011f69be1';
const channelId =
  localStorage.getItem('@infra-content:channelId') ||
  'b6ff3536-cf3e-4e32-9c12-feed42683b3a';
const sessionId =
  localStorage.getItem('@infra-content:sessionId') ||
  process.env.HEADER_SESSION_ID;
const customerId =
  localStorage.getItem('@infra-content:customerId') ||
  process.env.HEADER_CUSTOMER_ID;
const version =
  localStorage.getItem('@infra-content:contentVersion') || 'default';

const useAuthMocked = () => {
  useEffect(() => {
    if (!isLocal) return;
    localStorage.setItem('@infra-content:tenantId', tenantId);
    localStorage.setItem('@infra-content:channelId', channelId);
    localStorage.setItem('@infra-content:sessionId', sessionId);
    localStorage.setItem('@infra-content:customerId', customerId);
    localStorage.setItem('@infra-content:contentVersion', version);
  }, []);

  return { tenantId };
};

export default useAuthMocked;
