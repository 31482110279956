import { useEffect, useState } from 'react';
import { API } from '../Services/api';

const useFetchChannels = (tenant?: any) => {
  const [config, setConfig] = useState({
    isLoading: true,
    error: false,
    channels: [],
  });

  useEffect(() => {
    const fetchAllChanels = async () => {
      try {
        setConfig({
          isLoading: true,
          channels: [],
          error: false,
        });

        const { data } = await API.get<{
          data: { name: string; channelId: string }[];
        }>('channel/all-channels', {
          params: {
            active: true,
          },
        });

        const formattedChannels = data.data.map(
          ({ name: label, channelId: value }) => ({
            label,
            value,
          }),
        );

        setConfig({
          isLoading: false,
          error: false,
          channels: formattedChannels,
        });
      } catch {
        setConfig({
          isLoading: false,
          error: true,
          channels: [],
        });
      }
    };

    fetchAllChanels();
  }, [tenant]);

  return config;
};

export default useFetchChannels;
