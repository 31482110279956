import styled from 'styled-components';
import { Spacings, Colors, Typography as Ty } from '../../../theme';

export const BackLink = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-bottom: 10px;
  display: inline-block;
  .text {
    margin: 0 0 0 9px;
    ${Ty.body_small}
    color: ${Colors.information_100};
    font-weight: 700;
  }
`;

export const Container = styled.main`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2.5rem 0rem;

  &.details-status-saving {
    &:before {
      content: '';
      background: rgb(255 255 255 / 60%);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  h1 {
    margin: 2.5rem 0rem;
  }

  h2 {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }

  .rst__toolbarButton button {
    padding: 3px 5px 0;

    svg {
      width: 13px;
      height: 13px;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;

  padding: 0.875rem 0;
  display: flex;
  justify-content: space-between;
`;

export const GroupButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const CommonAlertMessage = styled.p`
  font-size: ${Spacings.spacing_12};
  font-family: ${Ty.h1.fontFamily};
  padding: ${Spacings.spacing_30} 0;
  color: ${Colors.shade_100};
`;

export const TreeItemNotSet = styled.span`
  font-style: italic;
  color: ${Colors.shade_100_opacity_light};
  font-weight: normal;
`;
