export const MockTypeTipbar = [
  {
    label: 'Simple',
    value: 'Simple',
  },
  {
    label: 'Carousel',
    value: 'Carousel',
  },
];

export const MockInputStyles = {
  title: 'normal',
  tipbarType: 'normal',
};
