import {
  InfraButton,
  InfraCol,
  InfraGrid,
  InfraIcons,
  InfraModal,
  InfraTable,
  InfraTableColumn,
  InfraTextField,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PageHeader, Pagination } from '../../../components';
import useDebounce from '../../../hooks/useDebounce';
import { Colors } from '../../../theme';
import * as style from './style';

import { useTenant } from '../../../Contexts/TenantContext';
import {
  deletePageService,
  getAllPageByTitleService,
  getAllPageService,
} from '../../../Services/Pages';
import { usePage } from '../../../hooks/usePage';
import { ResponseGetAllPages } from './type';

type Pagination = {
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
};

function ListPage() {
  const { channel } = useTenant();
  const history = useHistory();
  const { clear, setPayload, setPageId } = usePage();
  const [pageNameSelected, setPageNameSelected] = useState<EventTarget>();
  const [loading, setLoading] = useState<Boolean>(true);
  const [showModalDeletePage, setShowModalDeletePage] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [pageToDelete, setPageToDelete] = useState();

  const [pages, setPages] = useState<any[]>([]);
  const [paging, setPaging] = useState<Pagination>({
    pageNumber: 0,
    pageSize: 1,
    totalElements: 0,
    totalPages: 1,
  });

  const [pageSize, setPageSize] = useState(10);
  const [pageNumberParam, setPageNumberParam] = useState(1);

  const debounceSearchPageName = useDebounce(pageNameSelected, 500);

  useEffect(() => {
    clear();
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllPageService(pageSize, pageNumberParam)
      .then((response: ResponseGetAllPages) => {
        setPages(response.data.page);
        setPaging(response.meta.pagination);
      })
      .catch(() => {
        setShowModalError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageSize, pageNumberParam, channel]);

  useEffect(() => {
    if (debounceSearchPageName) {
      if (debounceSearchPageName.toString().length >= 3) {
        setLoading(true);
        getAllPageByTitleService(debounceSearchPageName)
          .then((response: ResponseGetAllPages) => {
            setPages(response.data.page);
          })
          .catch(error => {
            setShowModalError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      getAllPageService(pageSize, pageNumberParam)
        .then((response: ResponseGetAllPages) => {
          setPages(response.data.page);
        })
        .catch(() => {
          setShowModalError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debounceSearchPageName, pageSize]);

  const buttonActionEdit = value => {
    return (
      <InfraIcons
        name="Pencil"
        size={15}
        onClick={() => {
          const {
            edit,
            breadcrumbs,
            pageId: idPage,
            organization,
            totalRecords,
            ...pageContent
          } = value;
          setPageId(idPage);
          setPayload(pageContent);
          history.push(`/pages/edit/${idPage}`);
        }}
      />
    );
  };

  const buttonActionDelete = value => {
    return (
      <InfraIcons
        name="Trash"
        size={15}
        onClick={() => openModalDeletePage(value)}
      />
    );
  };

  const deletePage = () => {
    deletePageService(pageToDelete);
    setShowModalDeletePage(false);
    setLoading(true);
    getAllPageService(pageSize, pageNumberParam)
      .then((response: ResponseGetAllPages) => {
        // TODO
        // - quando eu deleto uma pagina, o response da API retorna a lista de
        //   paginas com a pagina deletada, é necessario que ele retorne com a
        //   lista atualizada e o page removido
      })
      .catch(() => {
        setShowModalError(true);
      })
      .finally(() => {
        setLoading(false);
      });
    const pagefiltered = pages.filter(page => {
      if (page.pageId !== pageToDelete) {
        return page;
      }
    });
    setPages(pagefiltered);
  };

  const openModalDeletePage = page => {
    setPageToDelete(page.pageId);
    setShowModalDeletePage(true);
  };

  const handlePagination = (event, value) => {
    if (event) {
      setPaging({ ...paging, pageNumber: value });
      setPageNumberParam(value);
    }
  };

  const handlePageSize = pageSize => {
    setPageSize(pageSize);
    setPaging({ ...paging, pageNumber: 1 });
  };

  return (
    <style.Container>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalDeletePage}
          onClose={() => setShowModalDeletePage(false)}
        >
          <div className="content">
            <InfraIcons name="Info" color={Colors.information_100} size={54} />
            <p className="description">
              Você realmente deseja excluir os dados?
            </p>
          </div>
          <div className="button-modal">
            <InfraButton
              style={{
                color: Colors.information_100,
                borderColor: Colors.information_100,
              }}
              iconRight
              fullWidth
              outline
              size="medium"
              onClick={() => setShowModalDeletePage(false)}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{ background: Colors.information_100 }}
              iconRight
              fullWidth
              size="medium"
              onClick={() => deletePage()}
            >
              Sim
            </InfraButton>
          </div>
        </InfraModal>
      </style.Modal>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">
              Aconteceu algum problema, tente novamente.
            </p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </style.Modal>

      <PageHeader title="Listagem de Páginas" />
      <style.GridFlex>
        <style.GridItem width="70%">
          <InfraTextField
            autoComplete="off"
            height="medium"
            icon="MagnifyingGlass"
            id="1"
            inputStyle="normal"
            onChange={event => setPageNameSelected(event.target.value)}
            placeholder="Pesquisa por título da página"
            position="start"
            type="text"
          />
        </style.GridItem>
        <style.GridItem width="28%">
          <InfraButton
            className="button"
            icon="Plus"
            iconRight
            fullWidth
            onClick={() => history.push('/pages/create')}
            size="medium"
          >
            Cadastrar nova página
          </InfraButton>
        </style.GridItem>
      </style.GridFlex>
      <InfraGrid style={{ paddingInline: 0 }} className="table-container">
        <InfraCol xxl="12" xl="12" lg="8" md="8" xs="4">
          <InfraTable
            value={pages && pages.length > 0 ? pages : []}
            loading={loading}
            messageEmptyData={
              loading ? 'Carregando informações' : 'Nenhum registro encontrado.'
            }
          >
            <InfraTableColumn
              align="left"
              field="title"
              header="Título da página"
              style={{
                cursor: 'default',
              }}
            />
            <InfraTableColumn
              align="left"
              field="canonical"
              header="Canonical"
              style={{
                cursor: 'default',
              }}
            />
            <InfraTableColumn
              field="action"
              header=""
              body={buttonActionEdit}
              padding="checkbox"
            />
            <InfraTableColumn
              field="action"
              header=""
              body={buttonActionDelete}
              padding="checkbox"
            />
          </InfraTable>
        </InfraCol>
      </InfraGrid>
      <div>
        <Pagination
          onChangePageSize={handlePageSize}
          onChangePage={handlePagination}
          pageNumber={paging?.pageNumber}
          totalPages={paging?.totalPages}
        />
      </div>
    </style.Container>
  );
}

export default ListPage;
