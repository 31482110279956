import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  InfraButton,
  InfraCol,
  InfraGrid,
  InfraTextarea,
  InfraTextField,
  InfraIcons,
} from '@infralabs/design-system';

import { PageHeader, RadioButton, CommonAccordion } from '../../../components';
import Accordion from '../../../components/AdditionalSettings';
import ModalAlertDialog from '../../../components/ModalAlertDialog';

import { createPageService, updatePageService } from '../../../Services/Pages';

import * as S from './style';
import { usePage } from '../../../hooks/usePage';
import { ElementsTable } from './components/ElementsTable';
import { createPageValidationsSchema } from './validations';
import { Position } from '../../../Contexts/PageContext';

type CreatePageParams = {
  page_id?: string;
};

export default function CreatePage() {
  const { page_id } = useParams<CreatePageParams>();
  const {
    payload,
    setPayload,
    setElement,
    setPosition,
    setBannerState,
    setTipbarState,
  } = usePage();
  const [initialValues] = useState(() => {
    return {
      title: payload.title,
      canonical: payload.canonical.replace(/^[/]/, ''),
      brandId: payload.brandId,
      categoryId: payload.categoryId,
      skuId: payload.skuId,
      sellerId: payload.sellerId,
      collectionId: payload.collectionId,
      identifier:
        payload.brandId ||
        payload.categoryId ||
        payload.skuId ||
        payload.sellerId ||
        payload.collectionId,
      header: payload.seo?.header,
      description: payload.seo?.description,
      content: payload.seo?.content,
      opt:
        (payload.brandId && 'brandId') ||
        (payload.categoryId && 'categoryId') ||
        (payload.skuId && 'skuId') ||
        (payload.sellerId && 'sellerId') ||
        (payload.collectionId && 'collectionId') ||
        'null',
      configs: payload.configs,
    };
  });
  const [additionalConfigurationTable, setAdditionalConfigurationTable] =
    useState(payload.configs);
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [modalAlertValues, setModalAlertValues] = useState({
    type: '',
    message: '',
    buttonTitle: '',
  });
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);

  const history = useHistory();

  const formik = useFormik({
    initialValues,
    validationSchema: createPageValidationsSchema,
    onSubmit: async ({
      title,
      canonical,
      identifier,
      header,
      description,
      content,
      opt,
    }) => {
      setIsDisabledSaveButton(true);
      const data = {
        title,
        canonical: `/${canonical}`,
        brandId: opt === 'brandId' ? identifier : null,
        skus: [],
        categoryId: opt === 'categoryId' ? identifier : null,
        skuId: opt === 'skuId' ? identifier : null,
        sellerId: opt === 'sellerId' ? identifier : null,
        collectionId: opt === 'collectionId' ? identifier : null,
        header: payload.header,
        body: payload.body,
        footer: payload.footer,
        seo: {
          header,
          description,
          content,
        },
        configs: additionalConfigurationTable,
      };
      setPayload(data);

      try {
        if (page_id) {
          await updatePageService(page_id, data);
          setShowModalDialog(true);
        } else {
          await createPageService(data);
          setShowModalDialog(true);
        }
        setModalAlertValues({
          type: 'success',
          message: 'Dados salvos com sucesso!',
          buttonTitle: 'Ok',
        });
      } catch (e) {
        if (e.isAxiosError) {
          let message =
            'Não foi possível cadastrar uma nova página. Tente novamente mais tarde.';
          if (
            e.response.data.message ===
            'Canonical already exists. Please define a unique canonical to page'
          ) {
            message =
              'URL já existe. Por favor, defina uma URL único para a página.';
          }
          if (
            e.response.data.message ===
            'Title already exists. Please define a unique title to page'
          ) {
            message =
              'Título já existe. por favor, defina um título único para a página.';
          }
          setModalAlertValues({
            type: 'error',
            message,
            buttonTitle: 'Ok',
          });
          setShowModalDialog(true);
        }
      }
      setIsDisabledSaveButton(false);
    },
  });

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const setInputStyle = (value: string, type: string) => {
    if (
      (value?.length > 150 && type === 'title') ||
      (value?.length === 0 && type === 'title' && formik.touched.title)
    ) {
      return 'error';
    }
    if (
      (!value?.match(/^[a-z0-9-/]*$/) &&
        type === 'canonical' &&
        formik.touched.canonical) ||
      (value?.length >= 64 && type === 'canonical') ||
      (value?.length === 0 && type === 'canonical' && formik.touched.canonical)
    ) {
      return 'error';
    }
    if (value?.length >= 47 && type === 'header') {
      return 'error';
    }
    if (value?.length >= 143 && type === 'description') {
      return 'error';
    }
    if ((!value || value?.length === 0) && type === 'identifier') {
      return 'error';
    }
    return 'normal';
  };

  const showTable = (tablePosition: Position) => {
    let result = false;
    const { sections } = payload[tablePosition];
    if (sections.length === 0) return false;
    sections.forEach(section => {
      if (
        (typeof section.banners === 'object' && section.banners.length > 0) ||
        (typeof section.components === 'object' &&
          section.components.length > 0) ||
        (typeof section.navigations === 'object' &&
          section.navigations.length > 0) ||
        (typeof section.shelves === 'object' && section.shelves.length > 0) ||
        (typeof section.tipbars === 'object' && section.tipbars.length > 0) ||
        section.content
      ) {
        result = true;
      }
    });
    return result;
  };

  return (
    <>
      <S.Container>
        <PageHeader
          title={page_id ? 'Editar Página' : 'Cadastro de Página'}
          backPath="/pages/list"
        />

        <form onSubmit={save}>
          <S.Wrapper>
            <S.Form>
              <S.Column>
                <S.FieldsTitle>Atributos básicos</S.FieldsTitle>
                <InfraGrid style={{ paddingInline: 0 }}>
                  <InfraCol xxl="6" xl="4" lg="4" md="4" xs="4">
                    <InfraTextField
                      placeholder="Digite o título"
                      name="title"
                      label="Título"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        formik.handleBlur(e);
                        setPayload({ ...payload, title: e.target.value });
                      }}
                      inputStyle={setInputStyle(formik.values.title, 'title')}
                      message={formik.errors.title}
                    />
                  </InfraCol>

                  <InfraCol xxl="6" xl="4" lg="4" md="2" xs="2">
                    <S.InputCanonical>
                      <InfraTextField
                        placeholder="Digite a URL"
                        name="canonical"
                        label="URL"
                        value={formik.values.canonical}
                        onChange={formik.handleChange}
                        onBlur={e => {
                          formik.handleBlur(e);
                          setPayload({ ...payload, canonical: e.target.value });
                        }}
                        inputStyle={setInputStyle(
                          formik.values.canonical,
                          'canonical',
                        )}
                        message={formik.errors.canonical}
                      />
                      {formik.values.canonical.length > 0 && (
                        <span className="bar">/</span>
                      )}
                    </S.InputCanonical>
                  </InfraCol>
                </InfraGrid>

                <InfraGrid style={{ paddingInline: 0, marginTop: 14 }}>
                  <InfraCol xxl="6" xl="4" lg="4" md="2" xs="2">
                    <div className="label-input-radio">
                      <label>Tipo de Identificador</label>
                    </div>
                    <S.RadioGroup>
                      <RadioButton
                        label="Marca"
                        name="opt"
                        value="brandId"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'brandId'}
                      />

                      <RadioButton
                        label="Categoria"
                        name="opt"
                        value="categoryId"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'categoryId'}
                      />

                      <RadioButton
                        label="SKU"
                        name="opt"
                        value="skuId"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'skuId'}
                      />

                      <RadioButton
                        label="Seller"
                        name="opt"
                        value="sellerId"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'sellerId'}
                      />

                      <RadioButton
                        label="Coleção"
                        name="opt"
                        value="collectionId"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'collectionId'}
                      />

                      <RadioButton
                        label="Nenhum"
                        name="opt"
                        value="null"
                        onChange={formik.handleChange}
                        checked={formik.values.opt === 'null'}
                        onClick={() => formik.setFieldValue('identifier', '')}
                      />
                    </S.RadioGroup>
                  </InfraCol>
                </InfraGrid>

                <InfraGrid style={{ paddingInline: 0, marginTop: 14 }}>
                  <InfraCol xxl="6" xl="4" lg="4" md="2" xs="2">
                    <InfraTextField
                      placeholder="Digite identificador"
                      name="identifier"
                      label="Identificador"
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        formik.handleBlur(e);
                        if (formik.values.opt) {
                          setPayload({
                            ...payload,
                            [formik.values.opt]: e.target.value,
                          });
                        }
                      }}
                      inputStyle={setInputStyle(
                        formik.values.identifier,
                        'identifier',
                      )}
                      message={formik.errors.identifier}
                      disabled={
                        !formik.values.opt || formik.values.opt === 'null'
                      }
                    />
                  </InfraCol>
                </InfraGrid>
              </S.Column>

              <S.ColumnDivider />

              <S.Column>
                <S.FieldsTitle>SEO</S.FieldsTitle>

                <InfraGrid style={{ paddingInline: 0 }}>
                  <InfraCol xxl="12" xl="12" lg="12" md="12" xs="12">
                    <InfraTextField
                      placeholder="Digite cabeçalho"
                      name="header"
                      label="Cabeçalho"
                      value={formik.values.header}
                      onChange={formik.handleChange}
                      onBlur={e => {
                        formik.handleBlur(e);
                        setPayload({
                          ...payload,
                          seo: { ...payload.seo, header: e.target.value },
                        });
                      }}
                      inputStyle={setInputStyle(formik.values.header, 'header')}
                      message={formik.errors.header}
                    />
                  </InfraCol>
                </InfraGrid>
                <InfraGrid style={{ paddingInline: 0, marginTop: 14 }}>
                  <InfraCol xxl="12" xl="10" lg="8" md="2" xs="2">
                    <div className="label-input">
                      <label>Descrição</label>
                      <InfraTextarea
                        placeholder="Digite descrição"
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        message={formik.errors.description}
                        setMessageError={!!formik.errors.description}
                        onBlur={e => {
                          formik.handleBlur(e);
                          setPayload({
                            ...payload,
                            seo: {
                              ...payload.seo,
                              description: e.target.value,
                            },
                          });
                        }}
                        height="small"
                      />
                    </div>
                  </InfraCol>
                </InfraGrid>
              </S.Column>
            </S.Form>
          </S.Wrapper>

          <div style={{ marginTop: 32 }} />

          <CommonAccordion
            isActive
            title="Elementos"
            headerRight={
              <InfraButton
                icon="Plus"
                iconRight
                size="small"
                outline
                style={{ border: 0 }}
                onClick={() => {
                  history.push('/pages/new-element');
                  setPosition('');
                  setElement('');
                  setBannerState({
                    name: '',
                    bannerType: '',
                    medias: [],
                    configs: [],
                    dateStart: null,
                    dateEnd: null,
                  });
                  setTipbarState({
                    title: '',
                    tipbarType: '',
                    items: [],
                    configs: [],
                    dateStart: null,
                    dateEnd: null,
                  });
                }}
              >
                Cadastrar novo elemento
              </InfraButton>
            }
          >
            {showTable('header') || showTable('body') || showTable('footer') ? (
              <>
                {showTable('header') && <ElementsTable position="header" />}
                {showTable('body') && <ElementsTable position="body" />}
                {showTable('footer') && <ElementsTable position="footer" />}
              </>
            ) : (
              <p className="not-data">
                <InfraIcons name="Info" size={16} className="info-icon" />
                Não há dados! Por favor, adicione elemento clicando no “
                <InfraIcons name="Plus" size={15} className="info-icon" />”
              </p>
            )}
          </CommonAccordion>

          <Accordion
            title="Configurações Adicionais"
            tableData={additionalConfigurationTable}
            setTableData={data => {
              setAdditionalConfigurationTable(data);
              setPayload({ ...payload, configs: data });
            }}
            hasTitleComponentWithIconPlus="Cadastrar nova configuração"
          />

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <S.Button
              type="submit"
              iconRight
              size="large"
              onClick={save}
              disabled={isDisabledSaveButton}
            >
              Salvar
            </S.Button>
          </div>
        </form>
      </S.Container>

      <ModalAlertDialog
        show={showModalDialog}
        message={modalAlertValues.message}
        type={modalAlertValues.type}
        buttonTitle={modalAlertValues.buttonTitle}
        onClose={() => {
          setShowModalDialog(false);
          if (modalAlertValues.type === 'success') history.push('/pages/list');
        }}
      />
    </>
  );
}
