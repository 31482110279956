import styled from 'styled-components';
import { Colors, Typography } from '../../../theme';

export const Container = styled.div`
  label {
    display: block;
    font-size: 0.875rem;
    line-height: 1.75rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding-right: 0.125rem;
  }

  .error-message {
    display: block;
    ${Typography.caption_small}
    color: ${Colors.error_300}
  }
`;
