import { TreeItemNotSet } from '../style';

export const mapObject2TreeViewData = items => {
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const today = new Date(Date.now() - tzoffset).toISOString();
  return items?.map(i => {
    const { ...data } = i;
    const expired = i.dateEnd < today;
    return {
      title: i?.title || i?.text || (
        <TreeItemNotSet>- not set -</TreeItemNotSet>
      ),
      expanded: true,
      data,
      expired,
      children: mapObject2TreeViewData(i.items || []),
    };
  });
};

export const remapTreeViewData2Object = items => {
  return items?.map(i => {
    const { children, data, ...rest } = i;
    return {
      ...data,
      items: remapTreeViewData2Object(i.children || []),
    };
  });
};

export const fixOrderByArrayOrder = items => {
  return items?.map((i, index) => {
    return {
      ...i,
      data: {
        ...i.data,
        order: index,
      },
      children: fixOrderByArrayOrder(i.children || []),
    };
  });
};
