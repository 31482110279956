import { ReactNode, MouseEventHandler, useState } from 'react';
import { InfraIcons } from '@infralabs/design-system';

import * as S from './style';

export type CommonAccordionProps = {
  title: string;
  headerRight?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  iconName?: string;
  isActive?: boolean;
  children: ReactNode;
};

export default function CommonAccordion({
  title = 'Title',
  headerRight,
  onClick,
  iconName = 'Plus',
  isActive = false,
  children,
}: CommonAccordionProps) {
  const [active, setActive] = useState(isActive);

  return (
    <S.Wrapper>
      <S.Heading>
        <S.Title>{title}</S.Title>
        <S.HeadingContent>
          <S.HeaderRight>{headerRight}</S.HeaderRight>

          {active ? (
            <InfraIcons
              name="CaretUp"
              size={16}
              onClick={() => setActive(!active)}
              className="caret-icons"
            />
          ) : (
            <InfraIcons
              name="CaretDown"
              size={16}
              onClick={() => setActive(!active)}
              className="caret-icons"
            />
          )}

          {onClick && (
            <InfraIcons
              name={iconName}
              size={15}
              onClick={onClick}
              className="plus-icon"
            />
          )}
        </S.HeadingContent>
      </S.Heading>

      {active && <S.BodyAccordion>{children}</S.BodyAccordion>}
    </S.Wrapper>
  );
}
