const Colors = {
  warning_50: '#ffeead',
  warning_100: '#ffe686',
  warning_200: '#ffe06d',
  warning_300: '#ffdb53',
  warning_400: '#fdd540',

  success_50: '#91f0c3',
  success_100: '#45e398',
  success_200: '#17be6f',
  success_300: '#089f57',
  success_400: '#008847',

  error_50: '#ffdcdf',
  error_100: '#fa878f',
  error_200: '#f76894',
  error_300: '#e11d2b',
  error_400: '#bf0013',

  information_50: '#ccccff',
  information_100: '#7676ff',
  information_200: '#4f4ffb',
  information_300: '#1b1bff',
  information_400: '#0000c7',

  neutral_50: '#eff1f7',
  neutral_100: '#e7ecf2',
  neutral_200: '#dde5ef',
  neutral_300: '#c6d3de',
  neutral_400: '#879bb4',
  neutral_500: '#506782',
  neutral_600: '#414f62',
  neutral_700: '#2f3c4e',
  neutral_700_opacity: 'rgba(47, 60,78, 0.66)',
  neutral_800: '#1f2c3f',
  neutral_900: '#0f1826',

  neutral_warn_50: '#fafafc',
  neutral_warn_100: '#f5f5f5',
  neutral_warn_200: '#e9e9e9',
  neutral_warn_300: '#ceced2',
  neutral_warn_400: '#9c9c9c',

  shade_0: '#ffffff',
  shade_100: '#000000',
  shade_100_opacity: 'rgba(0, 0, 0, 0.66)',
  shade_100_opacity_light: 'rgba(0, 0, 0, 0.11)',
};

export default Colors;
