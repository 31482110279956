import { AllHTMLAttributes } from 'react';
import { Container } from './style';

export function Divider(props: AllHTMLAttributes<HTMLDivElement>) {
  return (
    <Container>
      <div className="divider" {...props} />
    </Container>
  );
}
