import { useState, useRef, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  InfraModal,
  InfraCol,
  InfraGrid,
  InfraTextField,
  InfraTable,
  InfraTableColumn,
  InfraIcons,
  InfraButton,
} from '@infralabs/design-system';
import { useFormik, FormikErrors } from 'formik';
import { FormValues } from './Type';
import {
  InfraDatePicker,
  InfraSelect,
} from '../../../../../components/design-system';
import Accordion, {
  AddicionalSettingsTableDataProps,
} from '../../../../../components/AdditionalSettings';

import { typeOptions } from './options';
import { DataMedia } from '../../../../CreateMedia';
import { Colors } from '../../../../../theme';
import * as S from './style';

import { MockTypeTipbar, MockInputStyles } from './Mocks';

import { usePage } from '../../../../../hooks/usePage';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';

export const AddTipbar = () => {
  const { order } = useParams<AddNewPageElementParams>();
  const {
    tipbarState,
    setTipbarState,
    payload,
    setPayload,
    position,
    pageId,
    setElement,
    setPosition,
  } = usePage();
  const history = useHistory();
  const formRef = useRef(null);
  const [inputStyle, setInputStyle] = useState(MockInputStyles);
  const [mockITypes] = useState(MockTypeTipbar);
  const [tableTipbars, setTableTipbars] = useState<any>([]);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [tipbarName, setTipbarName] = useState<string>();
  const [tipbarType, setTipbarType] = useState<string>();
  const [showModalError, setShowModalError] = useState(false);
  const [mediasTipbar] = useState([]);
  const [messageModalError, setMessageModalError] = useState('');
  const [mediaToDelete, setMediaToDelete] = useState<DataMedia>();

  const formik = useFormik({
    initialValues: {
      title: tipbarState.title,
      tipbarType: tipbarState.tipbarType,
      dateStart: tipbarState.dateStart,
      dateEnd: tipbarState.dateEnd,
      items: tipbarState.items,
      configs: tipbarState.configs,
    },
    validate: (values: FormValues) => {
      setTipbarState(values);
      setTipbarName(values.title);
      setTipbarType(values.tipbarType);
      const errors: FormikErrors<FormValues> = {};
      const inputStyle = {
        title: 'normal',
        tipbarType: 'normal',
      };
      if (!values.title) {
        errors.title = 'O Nome do tipbar deve ser especificado.';
        inputStyle.title = 'error';
      }
      if (values.title && values.title.length > 150) {
        errors.title =
          'O tamanho máximo do nome do tipbar deve ser de 150 caracteres, incluindo espaços.';
        inputStyle.title = 'error';
      }
      if (!values.tipbarType) {
        errors.tipbarType = 'O Tipo do tipbar deve ser especificado.';
        inputStyle.tipbarType = 'error';
      }
      setInputStyle(inputStyle);
      return errors;
    },
    onSubmit: (values: FormValues) => {
      if (values.dateStart && values.dateEnd) {
        const dateStart = new Date(values.dateStart);
        const dateEnd = new Date(values.dateEnd);
        if (dateEnd < dateStart) {
          setShowModalError(true);
          setMessageModalError(
            `A data de fim da vigência deve ser posterior à data de início da vigência.`,
          );
          return;
        }
      }
      if (values.items && values.items.length === 0) {
        if (values.tipbarType === mockITypes[1].label) {
          setMessageModalError(
            `Um tipbar do tipo “${values.tipbarType}” precisa ter pelo menos dois itens vinculados.`,
          );
        } else {
          setMessageModalError(
            `Um tipbar do tipo “${values.tipbarType}” precisa ter pelo menos um item vinculado.`,
          );
        }
        setShowModalError(true);
      } else if (values.tipbarType) {
        if (
          values.tipbarType === mockITypes[0].label &&
          values.items.length > 1
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um tipbar do tipo “${mockITypes[0].label}” deve possuir somente um item vinculado.`,
          );
        } else if (
          values.tipbarType === mockITypes[1].label &&
          values.items.length < 2
        ) {
          setShowModalError(true);
          setMessageModalError(
            `Um tipbar do tipo ${mockITypes[1].label} deve possuir pelo menos dois itens vinculados.`,
          );
        } else {
          handleUpdatePayload(values);
          backToLastPage();
        }
      }
    },
  });

  function backToLastPage() {
    if (pageId) {
      history.push(`/pages/edit/${pageId}`);
    } else {
      history.push('/pages/create');
    }
  }

  const goToRegisterMidia = () => {
    history.push({
      pathname: '/pages/new-element/tipbar/add-item',
      state: { tableTipbars, tipbarName, tipbarType },
    });
  };

  function getTipbarTypeNumber(tipbarType) {
    if (tipbarType === 'Simple') return 0;
    if (tipbarType === 'Carousel') return 1;
  }

  function getTipbarTypeName(tipbarType) {
    if (typeof tipbarType === 'string') return tipbarType;
    if (tipbarType === 0) return 'Simple';
    if (tipbarType === 1) return 'Carousel';
  }

  const setValuesConfig = value => {
    formik.values.configs = value;
    setTipbarState({
      title: tipbarState.title,
      tipbarType: tipbarState.tipbarType,
      items: tipbarState.items,
      configs: value,
      dateStart: tipbarState.dateStart,
      dateEnd: tipbarState.dateEnd,
    });
  };

  const handleUpdatePayload = ({
    tipbarType,
    configs,
    items,
    title,
    dateStart,
    dateEnd,
  }: FormValues) => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;

    const sectionElement = {
      content: null,
      banners: [],
      components: [],
      navigations: [],
      shelves: [],
      tipbars: [
        {
          tipbarType: getTipbarTypeNumber(tipbarType),
          configs: configs || [],
          items,
          title,
          order: 0,
          dateStart,
          dateEnd,
        },
      ],
      order: order ? +order : 0,
    };

    switch (position) {
      case 'header':
        if (order) {
          sectionsHeader[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
          sectionsHeader[0] = sectionElement;
        } else {
          sectionElement.order = sectionsHeader.length;
          sectionsHeader.push(sectionElement);
        }
        break;
      case 'body':
        if (order) {
          sectionsBody[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
          sectionsBody[0] = sectionElement;
        } else {
          sectionElement.order = sectionsBody.length;
          sectionsBody.push(sectionElement);
        }
        break;
      case 'footer':
        if (order) {
          sectionsFooter[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
          sectionsFooter[0] = sectionElement;
        } else {
          sectionElement.order = sectionsFooter.length;
          sectionsFooter.push(sectionElement);
        }
        break;

      default:
        break;
    }

    setTipbarState({
      title: '',
      tipbarType: '',
      items: [],
      configs: [],
      dateStart: null,
      dateEnd: null,
    });

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });

    // resetValuesForm();
  };

  const openModaldeleteMidia = value => {
    setMediaToDelete(value);
    setShowModalDelete(true);
  };

  const save = (e: any) => {
    formik.handleSubmit();
    e.preventDefault();
  };

  const editMedia = (values: AddicionalSettingsTableDataProps) => {
    const index = tableTipbars.findIndex(
      (data: AddicionalSettingsTableDataProps) => data === values,
    );
    history.push({
      pathname: '/pages/new-element/tipbar/add-item',
      state: {
        indexEdit: index,
        isEdit: true,
        tipbarName,
        tipbarType,
        tableTipbars,
      },
    });
  };

  const renderEditIcon = values => {
    return (
      <div style={{ padding: '2px' }} onClick={() => editMedia(values)}>
        <InfraIcons name="PencilSimple" size={14} />
      </div>
    );
  };

  const deleteTipbarItem = () => {
    const tableItemsFiltered = tableTipbars.filter(tipbar => {
      if (tipbar.order !== mediaToDelete.order) {
        return tipbar;
      }
    });

    formik.values.items = tableItemsFiltered;
    setTableTipbars(tableItemsFiltered);
    setShowModalDelete(false);
    setTipbarState({
      title: tipbarState.title,
      tipbarType: tipbarState.tipbarType,
      items: tableItemsFiltered,
      configs: tipbarState.configs,
      dateStart: tipbarState.dateStart,
      dateEnd: tipbarState.dateEnd,
    });
    addOrderInData(tableItemsFiltered);
  };

  const renderDeleteIcon = values => {
    return (
      <div
        style={{ padding: '2px' }}
        onClick={() => openModaldeleteMidia(values)}
      >
        <InfraIcons name="TrashSimple" size={14} />
      </div>
    );
  };

  const setFormikValue = (value, input) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  const setMediaOrder = items => {
    return items.map((media, index) => {
      media.order = index;
      return media;
    });
  };

  useEffect(() => {
    const sortedMedia = setMediaOrder(tipbarState.items);
    formik.values.title = tipbarState.title;
    formik.values.tipbarType = getTipbarTypeName(tipbarState.tipbarType);
    formik.values.items = sortedMedia;
    formik.values.configs = tipbarState.configs;
    setTableTipbars(sortedMedia);

    // keep setTimeout so the function runs after the DOM is rendered, otherwise it won't work
    setTimeout(() => {
      const handles = document.querySelectorAll<HTMLElement>(
        'td[draggable="true"]',
      );
      handles.forEach(handle => {
        handle.style.cursor = 'move';
      });
    }, 0);
  }, []);

  const addOrderInData = useCallback(
    (value = mediasTipbar) => {
      const sortedItems = setMediaOrder(value);
      formik.values.items = sortedItems;
      setTipbarState({
        title: tipbarState.title,
        tipbarType: tipbarState.tipbarType,
        items: sortedItems,
        configs: tipbarState.configs,
        dateStart: tipbarState.dateStart,
        dateEnd: tipbarState.dateEnd,
      });
      setTableTipbars(sortedItems);
    },
    [tableTipbars],
  );

  return (
    <>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalDelete}
          onClose={() => setShowModalDelete(false)}
        >
          <div className="content">
            <InfraIcons name="Info" color={Colors.information_100} size={54} />
            <p className="description">
              Você realmente deseja excluir os dados?
            </p>
          </div>
          <div className="button-modal">
            <InfraButton
              style={{
                color: Colors.information_100,
                borderColor: Colors.information_100,
              }}
              iconRight
              fullWidth
              outline
              size="medium"
              onClick={() => setShowModalDelete(false)}
            >
              Cancelar
            </InfraButton>
            <InfraButton
              style={{ background: Colors.information_100 }}
              iconRight
              fullWidth
              size="medium"
              onClick={() => deleteTipbarItem()}
            >
              Sim
            </InfraButton>
          </div>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{messageModalError}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <form onSubmit={save} ref={formRef}>
        <S.Container>
          <InfraGrid className="inputs-page" style={{ paddingInline: 0 }}>
            <InfraCol xxl="3" xl="3" lg="3" md="3" xs="3">
              <InfraTextField
                label="Título"
                placeholder="Nome do elemento"
                name="title"
                message={formik.errors.title}
                value={formik.values.title}
                inputStyle={inputStyle.title}
                onChange={formik.handleChange}
              />
            </InfraCol>
            <InfraCol xxl="3" xl="3" lg="3" md="3" xs="3">
              <InfraSelect
                name="tipbarType"
                label="Tipo"
                placeholder="Selecione"
                error={formik.errors.tipbarType}
                items={typeOptions.map(item => {
                  return {
                    ...item,
                    selected:
                      item.value ===
                      getTipbarTypeName(formik.values.tipbarType),
                  };
                })}
                handlerClick={event => {
                  setFormikValue(event.value, 'tipbarType');
                }}
              />
            </InfraCol>
            <InfraCol xxl="3" xl="3" lg="3" md="3" xs="3">
              <InfraDatePicker
                label="Data início"
                maskDate="dd/MM/yyyy HH:mm"
                name="dateStart"
                value={formik.values.dateStart}
                onChange={e => {
                  const date = new Date(e);
                  const dateStart = date.toISOString();
                  formik.setFieldValue('dateStart', dateStart);
                  setTipbarState(state => ({ ...state, dateStart }));
                }}
                error={formik.errors.dateStart}
                placeholder="Data início"
                showTimeSelect
              />
            </InfraCol>
            <InfraCol xxl="3" xl="3" lg="3" md="3" xs="3">
              <InfraDatePicker
                label="Data fim"
                maskDate="dd/MM/yyyy HH:mm"
                name="dateEnd"
                value={formik.values.dateEnd}
                onChange={e => {
                  const date = new Date(e);
                  const dateEnd = date.toISOString();
                  formik.setFieldValue('dateEnd', dateEnd);
                  setTipbarState(state => ({ ...state, dateEnd }));
                }}
                error={formik.errors.dateEnd}
                placeholder="Data fim"
                showTimeSelect
              />
            </InfraCol>
            {/* <InfraButton onClick={() => handleSubmit()} type="button">
        submit
      </InfraButton> */}
          </InfraGrid>

          <InfraGrid style={{ marginTop: '30px', paddingInline: 0 }}>
            <InfraCol xs="12" className="buttons_subtitles">
              <S.WrapperButton>
                <S.Button
                  iconRight
                  icon="Plus"
                  onClick={() => goToRegisterMidia()}
                  type="submit"
                >
                  Cadastrar novo item
                </S.Button>
              </S.WrapperButton>
            </InfraCol>
          </InfraGrid>

          <InfraGrid style={{ paddingInline: 0 }}>
            <InfraCol xxl="12" xl="8" lg="8" md="8" xs="4" className="table">
              <InfraTable
                value={tipbarState.items}
                draggable="true"
                dndOnChange={addOrderInData}
                messageEmptyData="Dados não encontrados"
              >
                <InfraTableColumn
                  field="text"
                  header="Nome"
                  style={{
                    cursor: 'default',
                  }}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="edit"
                  body={renderEditIcon}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="delete"
                  body={renderDeleteIcon}
                />
              </InfraTable>
            </InfraCol>
          </InfraGrid>
        </S.Container>
        <Accordion
          title="Configurações Adicionais"
          tableData={tipbarState.configs}
          setTableData={value => setValuesConfig(value)}
          hasTitleComponentWithIconPlus="Cadastrar nova configuração"
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '32px',
          }}
        >
          <S.Button
            type="submit"
            iconRight
            size="medium"
            icon="Check"
            onClick={save}
          >
            Salvar
          </S.Button>
        </div>
      </form>
    </>
  );
};
