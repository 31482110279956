import { useState, useRef } from 'react';
import {
  InfraModal,
  InfraIcons,
  InfraGrid,
  InfraCol,
  InfraButton,
  InfraTextarea,
} from '@infralabs/design-system';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { usePage } from '../../../../../hooks/usePage';

import { Colors } from '../../../../../theme';
import * as style from './style';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';

const validationHtml = Yup.object({
  html: Yup.string().required('O conteúdo HTML deve ser preenchido'),
});

export function AddHtml() {
  const { order } = useParams<AddNewPageElementParams>();
  const formRef = useRef(null);
  const history = useHistory();
  const { payload, setPayload, position, setElement, setPosition } = usePage();

  const [showModalError, setShowModalError] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [messageModalError, setMessageModalError] = useState('');

  const formik = useFormik({
    initialValues: {
      html:
        position && order ? payload[position]?.sections[order]?.content : '',
    },
    validationSchema: validationHtml,
    onSubmit: values => {
      const sectionsHeader = payload.header.sections;
      const sectionsBody = payload.body.sections;
      const sectionsFooter = payload.footer.sections;
      const sectionElement = {
        content: values.html,
        banners: [],
        components: [],
        navigations: [],
        shelves: [],
        tipbars: [],
        order: order ? +order : 0,
      };

      switch (position) {
        case 'header':
          if (order) {
            sectionsHeader[order] = sectionElement;
          } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
            sectionsHeader[0] = sectionElement;
          } else {
            sectionElement.order = sectionsHeader.length;
            sectionsHeader.push(sectionElement);
          }
          break;
        case 'body':
          if (order) {
            sectionsBody[order] = sectionElement;
          } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
            sectionsBody[0] = sectionElement;
          } else {
            sectionElement.order = sectionsBody.length;
            sectionsBody.push(sectionElement);
          }
          break;
        case 'footer':
          if (order) {
            sectionsFooter[order] = sectionElement;
          } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
            sectionsFooter[0] = sectionElement;
          } else {
            sectionElement.order = sectionsFooter.length;
            sectionsFooter.push(sectionElement);
          }
          break;

        default:
          break;
      }

      setPayload({
        ...payload,
        header: { sections: sectionsHeader },
        body: { sections: sectionsBody },
        footer: { sections: sectionsFooter },
      });

      setShowModalSuccess(true);
      setElement('');
      setPosition('');
    },
  });

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const setInputStyle = (value: string, type: string) => {
    if (value?.length === 0 && type === 'html' && formik.touched.html) {
      return 'error';
    }

    return 'normal';
  };

  return (
    <>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalSuccess}
          onClose={() => {
            setShowModalSuccess(false);
            history.goBack();
          }}
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">
              Novo elemento foi cadastrado com sucesso
            </p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => {
              setShowModalSuccess(false);
              history.goBack();
            }}
          >
            OK
          </InfraButton>
        </InfraModal>
      </style.Modal>
      <style.Modal>
        <InfraModal
          theme="light"
          show={showModalError}
          onClose={() => setShowModalError(false)}
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{messageModalError}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() => setShowModalError(false)}
          >
            OK
          </InfraButton>
        </InfraModal>
      </style.Modal>
      <style.Wrapper>
        <form onSubmit={save} ref={formRef}>
          <InfraGrid className="inputs-page" style={{ paddingInline: 0 }}>
            <InfraCol xxl="12" xl="12" lg="12">
              <style.Label>
                <label>HTML</label>
                <InfraTextarea
                  label="HTML"
                  name="html"
                  placeholder=""
                  value={formik.values.html}
                  onChange={formik.handleChange}
                  message={formik.errors.html}
                  inputStyle={setInputStyle(formik.values.html, 'html')}
                />
              </style.Label>
            </InfraCol>
          </InfraGrid>
        </form>
      </style.Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '4rem',
        }}
      >
        <style.Button type="submit" iconRight size="large" onClick={save}>
          Salvar
        </style.Button>
      </div>
    </>
  );
}
