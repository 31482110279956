import { useHistory } from 'react-router-dom';

export const breadCrumbRouteLevel1 = (history: typeof useHistory) => {
  const getPath = (pathname: string, fullPath = true) => {
    const history = useHistory();

    let url = history.createHref({ pathname, search: '' });

    if (!fullPath) {
      url = url.replace('/content', '');
    }

    return url;
  };

  return [
    {
      disabled: false,
      href: getPath('/', false),
      text: 'Página inicial',
    },
    {
      href: getPath('/'),
      text: 'Conteúdo',
    },
    {
      href: getPath('/navigations'),
      text: 'Listagem de Componentes de Navegação',
    },
  ];
};
