import { useEffect, useState } from 'react';
import { InfraPagination, InfraTypography } from '@infralabs/design-system';

import * as S from './style';

type PaginationProps = {
  pageNumber: number;
  totalPages: number;
  onChangePageSize: (pageSize: number | any) => void;
  onChangePage: (event: any, page: number | any) => void;
};

const paginationOption = [
  {
    label: '10',
    value: 10,
    selected: true,
  },
  {
    label: '20',
    value: 20,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '40',
    value: 40,
  },
  {
    label: '50',
    value: 50,
  },
];

export default function Pagination({
  pageNumber,
  totalPages,
  onChangePageSize,
  onChangePage,
}: PaginationProps) {
  const [page, setPage] = useState(pageNumber);
  const [count, setCount] = useState(totalPages);

  useEffect(() => {
    setPage(pageNumber + 1);
    setCount(totalPages);
  }, [pageNumber, totalPages]);

  const handlePageSize = e => {
    onChangePageSize(e.target.value);
  };

  return (
    <S.Pagination>
      <S.PaginationSize>
        <InfraTypography variant="h5" weight="bold">
          Linhas por página:
        </InfraTypography>

        <S.SelectPageSize onChange={e => handlePageSize(e)}>
          {paginationOption.map(item => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))}
        </S.SelectPageSize>
      </S.PaginationSize>

      <div>
        <InfraPagination
          color="neutral"
          onChange={(event, value) => onChangePage(event, value)}
          defaultPage={1}
          count={count}
          page={page}
          customtheme="light"
          shape="rounded"
          arrowfilled={false}
        />
      </div>
    </S.Pagination>
  );
}
