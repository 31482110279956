import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { InfraGrid, InfraCol, InfraTextField } from '@infralabs/design-system';
import { usePage } from '../../../../../hooks/usePage';
import * as S from './style';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';
import { getAllCollections } from '../../../../../Services/Pages/collections';
import { SelectList } from './types';
import { InfraSelect } from '../../../../../components/design-system';
import Accordion from '../../../../../components/AdditionalSettings';

const shelftTypes = [
  { label: 'Estática', value: 0, selected: false },
  { label: 'Carrousel', value: 1, selected: false },
  { label: 'Grid', value: 2, selected: false },
] as SelectList[];

export function AddShelf() {
  const { order } = useParams<AddNewPageElementParams>();
  const history = useHistory();
  const { position, payload, setPayload, setElement, setPosition } = usePage();
  const [collections, setCollections] = useState<SelectList[]>([]);

  const formik = useFormik({
    initialValues: {
      name: payload[position]?.sections[order]?.shelves[0]?.name || '',
      shelfType:
        typeof payload[position]?.sections[order]?.shelves[0]?.shelfType !==
        'number'
          ? ''
          : payload[position]?.sections[order]?.shelves[0]?.shelfType,
      configs: payload[position]?.sections[order]?.shelves[0]?.configs || [],
      collectionId:
        payload[position]?.sections[order]?.shelves[0]?.collectionId || '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required('O Nome da prateleira deve ser especificado.')
        .max(
          100,
          'O tamanho máximo do nome do componente genérico, deve ser de 100 caracteres, incluindo espaços.',
        ),
      shelfType: Yup.string().required(
        'O Tipo da prateleira deve ser especificado.',
      ),
      configs: Yup.array().nullable(),
      collectionId: Yup.string().required(
        'A Coleção da prateleira deve ser especificada.',
      ),
    }),
    onSubmit: (e: any) => {
      const { name, shelfType, configs, collectionId } = e;
      handleUpdatePayload(name, shelfType, configs, collectionId);
      setElement('');
      setPosition('');
      history.goBack();
    },
  });

  const handleCollections = async () => {
    const { data: allCollections } = await getAllCollections();
    const formattedCollections = allCollections.data.collection.map(element => {
      return {
        label: element.name,
        value: element.collectionId,
        selected: false,
      };
    });
    setCollections(formattedCollections);
  };

  useEffect(() => {
    handleCollections();
  }, []);

  const handleUpdatePayload = (name, shelfType, configs, collectionId) => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;
    const sectionElement = {
      content: null,
      banners: [],
      components: [],
      navigations: [],
      shelves: [
        {
          name,
          shelfType,
          configs,
          collectionId,
          order: 0,
        },
      ],
      tipbars: [],
      order: order ? +order : 0,
    };

    switch (position) {
      case 'header':
        if (order) {
          sectionsHeader[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
          sectionsHeader[0] = sectionElement;
        } else {
          sectionElement.order = sectionsHeader.length;
          sectionsHeader.push(sectionElement);
        }
        break;
      case 'body':
        if (order) {
          sectionsBody[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
          sectionsBody[0] = sectionElement;
        } else {
          sectionElement.order = sectionsBody.length;
          sectionsBody.push(sectionElement);
        }
        break;
      case 'footer':
        if (order) {
          sectionsFooter[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
          sectionsFooter[0] = sectionElement;
        } else {
          sectionElement.order = sectionsFooter.length;
          sectionsFooter.push(sectionElement);
        }
        break;

      default:
        break;
    }

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.Container>
        <InfraGrid className="inputs-page" style={{ paddingInline: 0 }}>
          <InfraCol xxl="4" xl="4" lg="4" md="6">
            <InfraTextField
              label="Nome"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              message={formik.errors.name}
              inputStyle={formik.errors.name ? 'error' : 'normal'}
              placeholder="Nome"
            />
          </InfraCol>

          <InfraCol xxl="4" xl="4" lg="4" md="6">
            <InfraSelect
              name="shelfType"
              placeholder="Selecione"
              label="Tipo"
              items={shelftTypes.map(item => {
                return {
                  ...item,
                  selected: item.value === formik.values.shelfType,
                };
              })}
              handlerClick={e => {
                formik.setFieldValue('shelfType', e.value);
              }}
              error={formik.errors.shelfType}
            />
          </InfraCol>
          <InfraCol xxl="4" xl="4" lg="4" md="6">
            <InfraSelect
              name="collectionId"
              placeholder="Selecione"
              label="Coleção"
              items={collections.map(item => {
                return {
                  ...item,
                  selected: item.value === formik.values.collectionId,
                };
              })}
              handlerClick={e => {
                formik.setFieldValue('collectionId', e.value);
              }}
              error={formik.errors.collectionId}
            />
          </InfraCol>
        </InfraGrid>
      </S.Container>

      <Accordion
        title="Configurações Adicionais"
        tableData={formik.values.configs}
        setTableData={value => formik.setFieldValue('configs', value)}
        hasTitleComponentWithIconPlus="Cadastrar nova configuração"
      />

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
        <S.Button type="submit" iconRight size="medium" icon="Check">
          Salvar
        </S.Button>
      </div>
    </form>
  );
}
