import { AxiosResponse } from 'axios';
import { API } from '../api';
import { NavigationsResponse } from '../../Pages/Page/AddNewPageElement/components/AddNavigation/types';

export const createPageService = async values => {
  return API.post('/pages', values);
};

export const updatePageService = async (pageId, values) => {
  return API.put(`/pages/${pageId}`, values);
};

export const getAllPageService = (pageSize, pageNumberParam) => {
  return API.get(
    `/pages?pageSize=${pageSize}&pageNumber=${pageNumberParam - 1}`,
  )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

export const getAllPageByTitleService = value => {
  return API.get(`/pages?title=${value}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

export const getPageByIdService = pageId => {
  return API.get(`/pages/${pageId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};

export const deletePageService = id => {
  API.delete(`/pages/${id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};

export const getAllNavigations = () => {
  return API.get(`/navigations`)
    .then((response: AxiosResponse<{ data: Array<NavigationsResponse> }>) => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
};
