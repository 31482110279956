import {
  SkuScorePutRequest,
  SkuScoreResponse,
  SkuScoresByBrand,
  saveSkuScoresByBrand,
  saveSkuScoresByCategory,
} from './Types';
import { API_CONTENT } from '../api-content';

export function getSkuScoreByIdService(skuId: string) {
  return API_CONTENT.get<SkuScoreResponse>(`/sku-scores/${skuId}`);
}

export function getSkuScoresByBrand(brandId: string) {
  return API_CONTENT.get<SkuScoresByBrand>(
    `/sku-scores?brandId=${brandId}&pageSize=1000&pageNumber=0`,
  );
}

export function getSkuScoresByCategory(brandId: string) {
  return API_CONTENT.get<SkuScoresByBrand>(
    `/sku-scores?categoryId=${brandId}&pageSize=1000&pageNumber=0`,
  );
}

export function putSkuScoresByBrand(
  brandId: string,
  request: saveSkuScoresByBrand,
) {
  return API_CONTENT.put(`/sku-scores/brand/${brandId}`, request);
}

export function putSkuScoresByCategory(
  categoryId: string,
  request: saveSkuScoresByCategory,
) {
  return API_CONTENT.put(`/sku-scores/category/${categoryId}`, request);
}

export function putSkuScoreService(skuId: string, request: SkuScorePutRequest) {
  return API_CONTENT.put(`/sku-scores/${skuId}`, request);
}
