import React, { useEffect, useState } from 'react';
import { InfraTable, InfraTableColumn } from '@infralabs/design-system';
import { Position } from '../../../../Contexts/PageContext';
import { usePage } from '../../../../hooks/usePage';
import { TableValue } from './types';
import { SectionOption } from '../types';
import {
  buttonActionDelete,
  buttonActionEdit,
  getElementTitle,
  getPositionTitle,
} from './table.helpers';

type ElementsTableProps = {
  position: Position;
};

export function ElementsTable({ position }: ElementsTableProps) {
  const { payload, setPayload } = usePage();
  const [tableValues, setTableValues] = useState<TableValue[]>([]);

  useEffect(() => {
    const loadTableValues = () => {
      const { sections } = payload[position];
      const result = sections.map(section => {
        if (section.banners.length > 0) {
          return {
            ...section.banners[0],
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('banner'),
            elementDescription: section.banners[0]?.name,
            element: 'banner',
            position,
          };
        }
        if (section.navigations.length > 0) {
          return {
            ...section.navigations[0],
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('navigation'),
            elementDescription: section.navigations[0]?.title,
            element: 'navigation',
            position,
          };
        }
        if (section.components.length > 0) {
          return {
            ...section.components[0],
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('generic-component'),
            elementDescription: section.components[0]?.title,
            element: 'generic-component',
            position,
          };
        }
        if (section.tipbars.length > 0) {
          return {
            ...section.tipbars[0],
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('tipbar'),
            elementDescription: section.tipbars[0].title,
            element: 'tipbar',
            position,
          };
        }
        if (section.shelves.length > 0) {
          return {
            ...section.shelves[0],
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('shelf'),
            elementDescription: section.shelves[0].name,
            element: 'shelf',
            position,
          };
        }
        if (section.content) {
          return {
            positionTitle: getPositionTitle(position),
            elementTitle: getElementTitle('content'),
            elementDescription: section.content,
            element: 'content',
            position,
          };
        }
        return null;
      });
      if (result[0] === null) result.shift();
      setTableValues(result);
    };
    loadTableValues();

    // keep setTimeout so the function runs after the DOM is rendered, otherwise it won't work
    setTimeout(() => {
      const handles = document.querySelectorAll<HTMLElement>(
        'td[draggable="true"]',
      );
      handles.forEach(handle => {
        handle.style.cursor = 'move';
      });
    }, 0);
  }, [payload]);

  return (
    <div style={{ marginBottom: '32px' }}>
      <InfraTable
        value={tableValues}
        draggable="true"
        style={{ cursor: 'move', background: '#ff0000' }}
        dndOnChange={(records: TableValue[]) => {
          const positionItems: SectionOption[] = records.map(
            (record, order) => {
              const {
                positionTitle,
                elementTitle,
                elementDescription,
                element,
                position,
                ...rest
              } = record;
              const result = {
                banners: [],
                components: [],
                navigations: [],
                shelves: [],
                tipbars: [],
                order,
                content: null,
              };

              if (element === 'banner') {
                result.banners[0] = rest;
              }
              if (element === 'navigation') {
                result.navigations[0] = rest;
              }
              if (element === 'tipbar') {
                result.tipbars[0] = rest;
              }
              if (element === 'content') {
                result.content = elementDescription;
              }
              if (element === 'shelf') {
                result.shelves[0] = rest;
              }
              if (element === 'generic-component') {
                result.components[0] = rest;
              }
              return result;
            },
          );
          const header = { sections: [] };
          const body = { sections: [] };
          const footer = { sections: [] };
          if (position === 'header') {
            header.sections = positionItems;
            body.sections = payload.body.sections;
            footer.sections = payload.footer.sections;
          }
          if (position === 'body') {
            header.sections = payload.header.sections;
            body.sections = positionItems;
            footer.sections = payload.footer.sections;
          }
          if (position === 'footer') {
            header.sections = payload.header.sections;
            body.sections = payload.body.sections;
            footer.sections = positionItems;
          }

          setPayload({ ...payload, header, body, footer });
        }}
        messageEmptyData="Não há elementos cadastrados"
      >
        <InfraTableColumn
          field="positionTitle"
          header="Posição"
          style={{
            cursor: 'default',
          }}
        />
        <InfraTableColumn
          field="elementTitle"
          header="Elemento"
          style={{
            cursor: 'default',
          }}
        />
        <InfraTableColumn
          field="elementDescription"
          header="Descrição"
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '250px',
            whiteSpace: 'nowrap',
            cursor: 'default',
          }}
        />
        <InfraTableColumn
          field="action"
          header=""
          body={buttonActionEdit}
          padding="checkbox"
          style={{
            cursor: 'pointer',
          }}
        />
        <InfraTableColumn
          field="action"
          header=""
          body={buttonActionDelete}
          padding="checkbox"
          style={{
            cursor: 'pointer',
          }}
        />
      </InfraTable>
    </div>
  );
}
