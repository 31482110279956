import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  API,
  API_CATALOG,
  API_UPLOAD_FILES,
  API_WITH_VALIDATION_STATAUS,
} from '../Services/api';
import { API_CONTENT } from '../Services/Content/api-content';
import { API_CATALOG as API_CATALOG2 } from '../Services/Catalog/api-catalog';
import ModalAlertDialog from '../components/ModalAlertDialog';
import useFetchChannels from './useFetchChannels';

export interface Tenant {
  authToken: string;
  store: {
    name: string;
    id: string;
    slug: string;
  };
  sellerId: string;
  userRoles: string[];
  isHireAppModalVisible: boolean;
}

export interface Channel {
  data: {
    tenantId: string;
    channels: string[];
  };
}

interface TenantContextData {
  tenant?: Tenant;
  channel?: string;
  channels?: { label: string; value: string }[];
  setChannel?: (channel: string) => void;
}

interface TenantProviderProps {
  props: any;
}

const APIS = [
  API,
  API_CATALOG,
  API_UPLOAD_FILES,
  API_WITH_VALIDATION_STATAUS,
  API_CONTENT,
  API_CATALOG2,
];

const TenantContext = createContext<TenantContextData>({} as TenantContextData);
const PATH_CONTENT = '/content';
export const TenantProvider: React.FC<TenantProviderProps> = ({
  props,
  children,
}) => {
  const [tenant, setTenant] = useState<Tenant>();
  const [channel, setChannel] = useState<string>(
    localStorage.getItem('@infra-content:channelId'),
  );

  const [showModalError, setShowModalError] = useState(false);
  const { channels, isLoading, error } = useFetchChannels(tenant);
  const history = useHistory();
  const location = useLocation();

  function handleChangeTenent(objProps: Tenant) {
    const tenantId = objProps.store.id;

    tenantId
      ? localStorage.setItem('@infra-content:tenantId', tenantId)
      : localStorage.removeItem('@infra-content:tenantId');

    APIS.forEach(api => {
      api.defaults.headers['X-Tenant-Id'] = tenantId;
    });

    setTenant(objProps);
  }

  const handleChangeChannel = newChannel => {
    newChannel
      ? localStorage.setItem('@infra-content:channelId', newChannel)
      : localStorage.removeItem('@infra-content:channelId');

    APIS.forEach(api => {
      api.defaults.headers['X-Channel-Id'] = newChannel;
    });

    setChannel(newChannel);
  };

  useEffect(() => {
    if (!props?.tokenIdSubject) return;

    const subscription = props.tokenIdSubject.subscribe((objProps: Tenant) => {
      if (!objProps.store.id) return;
      handleChangeTenent(objProps);
    });
    return () => subscription.unsubscribe();
  }, [props.tokenIdSubject]);

  useEffect(() => {
    if (isLoading) return;
    if (location.pathname === PATH_CONTENT) return;
    if (!channel && channels.length)
      return handleChangeChannel(channels[0].value);

    if (!channel || !channels.length) return history.push(PATH_CONTENT);

    const channelAlreadySelected = channels.some(
      currentChannel => currentChannel.value === channel,
    );

    if (!channelAlreadySelected) return history.push(PATH_CONTENT);
  }, [location.pathname, isLoading, channels, channel]);

  useEffect(() => {
    setShowModalError(error);
  }, [error]);

  return (
    <TenantContext.Provider
      value={{ tenant, channel, channels, setChannel: handleChangeChannel }}
    >
      {children}
      <ModalAlertDialog
        show={showModalError}
        message="Não foi possível encontrar um sales channel atribuído à esta loja"
        type="error"
        onClose={() => setShowModalError(false)}
      />
    </TenantContext.Provider>
  );
};

export function useTenant() {
  const context = useContext(TenantContext);
  return context;
}
