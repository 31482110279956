import {
  InfraTextField,
  InfraTable,
  InfraTableColumn,
} from '@infralabs/design-system';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import * as S from './style';
import ModalAlertDialog from '../../../components/ModalAlertDialog';
import debounce from '../../../Helper/debounce';
import {
  getSkuScoresByCategory,
  putSkuScoresByCategory,
} from '../../../Services/Content/sku-scores';
import {
  SkuScorePutRequest,
  SkuScorePutResponseError,
  saveSkuScoresByCategory,
} from '../../../Services/Content/sku-scores/Types';
import skusMock from '../skusMock';
import { useTenant } from '../../../Contexts/TenantContext';

export default function OrderingByCategory() {
  const { channel } = useTenant();
  const [categoryId, setCategoryId] = useState<string>();
  const [skuList, setSkuList] = useState([]);
  const [skuOrder, setSkuOrder] = useState([]);
  const [categorySearchError, setCategorySearchError] = useState('');
  const [showModalDialog, setShowModalDialog] = useState(false);
  const [modalAlertValues, setModalAlertValues] = useState({
    type: '',
    message: '',
    buttonTitle: '',
  });

  async function saveSkuScoreByCategory() {
    try {
      if (skuOrder.length === 0) throw new Error('empty skus');
      const payload: saveSkuScoresByCategory = { skus: skuOrder };
      await putSkuScoresByCategory(categoryId, payload);
      setModalAlertValues({
        type: 'success',
        message: 'Dados salvos com sucesso!',
        buttonTitle: 'Ok',
      });
      setShowModalDialog(true);
      setSkuOrder([]);
    } catch (e) {
      if (e.message === 'empty skus') {
        const message = 'Não há alterações a serem salvas';
        setModalAlertValues({
          type: 'information',
          message,
          buttonTitle: 'OK',
        });
        setShowModalDialog(true);
      } else if (e.isAxiosError) {
        const error = e as AxiosError<SkuScorePutResponseError>;
        let message =
          'Favor definir valores diferentes para scores de skus na mesma ';
        if (error.response.status === 400) {
          if (error.response.data.message.includes('category')) {
            message = message += 'categoria';
          }
          if (error.response.data.message.includes('brand')) {
            message = message += 'marca';
          }
          setModalAlertValues({
            type: 'information',
            message,
            buttonTitle: 'Definir valores',
          });
        } else if (error.response.status > 299) {
          setModalAlertValues({
            type: 'warning',
            message: error.response.data.message,
            buttonTitle: 'OK',
          });
        }
        setShowModalDialog(true);
      }
    }
  }

  async function getSkuScoreByCategoryId(value: string) {
    try {
      const skusByBrand = await getSkuScoresByCategory(value);
      setSkuList(skusByBrand.data.data);
      setSkuOrder([]);
    } catch (e) {
      console.log(e);
      if (e.isAxiosError) {
        const error = e as AxiosError<SkuScorePutResponseError>;
        const message = error.response.data?.message;
        if (error.response.status === 400) {
          setModalAlertValues({
            type: 'information',
            message,
            buttonTitle: 'Definir valores',
          });
          setShowModalDialog(true);
        }
      }
    }
  }

  function changeOrder(data) {
    const newSkuOrder = [];
    data.forEach(element => {
      newSkuOrder.push(element.skuId);
    });
    setSkuOrder(newSkuOrder);
  }

  const searchSkuByCategoryId = debounce((value: string) => {
    setSkuList([]);
    getSkuScoreByCategoryId(value);
  }, 400);

  useEffect(() => {
    // keep setTimeout so the function runs after the DOM is rendered, otherwise it won't work
    setTimeout(() => {
      const handles = document.querySelectorAll<HTMLElement>(
        'td[draggable="true"]',
      );
      handles.forEach(handle => {
        handle.style.cursor = 'move';
      });
    }, 200);
  }, [skuList]);

  useEffect(() => {
    if (categoryId) searchSkuByCategoryId(categoryId);
  }, [categoryId, channel]);

  return (
    <>
      <S.Wrapper>
        <S.SearchHeader>
          <S.InputWrapper>
            <InfraTextField
              icon="MagnifyingGlass"
              position="start"
              placeholder="Buscar por um identificador de categoria..."
              onChange={e => setCategoryId(e.target.value)}
              inputStyle={categorySearchError ? 'error' : 'normal'}
              message={categorySearchError}
              iconRight
            />
          </S.InputWrapper>
          <S.Button
            iconRight
            icon="Check"
            size="large"
            onClick={() => saveSkuScoreByCategory()}
          >
            Salvar
          </S.Button>
        </S.SearchHeader>

        {categoryId && !categorySearchError && (
          <InfraTable
            value={skuList}
            draggable="true"
            dndOnChange={e => changeOrder(e)}
            messageEmptyData="Dados não encontrados"
          >
            <InfraTableColumn
              field="skuId"
              header="SKU ID"
              style={{
                cursor: 'default',
              }}
            />
            <InfraTableColumn
              field="skuName"
              header="Nome de SKU"
              style={{
                cursor: 'default',
              }}
            />
          </InfraTable>
        )}
      </S.Wrapper>

      <ModalAlertDialog
        show={showModalDialog}
        message={modalAlertValues.message}
        type={modalAlertValues.type}
        buttonTitle={modalAlertValues.buttonTitle}
        onClose={() => setShowModalDialog(false)}
      />
    </>
  );
}
