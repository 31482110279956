import React from 'react';
import ReactDOM from 'react-dom';
import 'regenerator-runtime';
import singleSpaReact from 'single-spa-react';
import App from './Pages/App';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  domElementGetter:
    process.env.ENVIRONMENT === 'local'
      ? undefined
      : () => document.getElementById('mfe_div_connector'),
  rootComponent: App,
  errorBoundary() {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
export { App };
