import styled from 'styled-components';
import { InfraTag } from '@infralabs/design-system';
import { Colors, Typography, Shadows, Spacings } from '../../theme';

export const PageHeader = styled.div`
  .header {
    margin: 0 0 33px 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
`;

export const Column = styled.div`
  width: 100%;
  align-items: center;

  .button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 1024px) {
      justify-content: flex-start;
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  ${Typography.h2}
  color: ${Colors.neutral_600};
  margin: 0;

  @media (max-width: 800px) {
    ${Typography.h3}
    flex-direction: column;
  }
`;

export const TitleTag = styled.div`
  margin-left: ${Spacings.spacing_32};
`;

export const BackPage = styled.div`
  width: 5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  .text {
    margin: 0 0 0 9px;
    ${Typography.body_small}
    color: ${Colors.information_100};
    font-weight: 700;
  }
`;
