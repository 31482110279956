import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { InfraSelect } from '@infralabs/design-system';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { usePage } from '../../../../../hooks/usePage';
import { NavigationsComponent, NavigationsResponse } from './types';
import { getAllNavigations } from '../../../../../Services/Pages';
import * as S from './style';
import { verifyIfFirstSectionIsEmpty } from '../../validations';
import { AddNewPageElementParams } from '../..';

export function AddNavigation() {
  const { order } = useParams<AddNewPageElementParams>();
  const history = useHistory();
  const { position, payload, setPayload, setElement, setPosition } = usePage();
  const [navComponents, setNavComponents] = useState<NavigationsComponent[]>(
    [],
  );
  const [navTitle, setNavTitle] = useState('');

  const formik = useFormik({
    initialValues: {
      navigationComponent:
        position && order
          ? payload[position].sections[order].navigations[0].navigationId
          : '',
    },
    validationSchema: Yup.object({
      navigationComponent: Yup.string().required('Campo obrigatório'),
    }),
    onSubmit: (e: any) => {
      handleUpdatePayload(e.navigationComponent);
      setElement('');
      setPosition('');
      history.goBack();
    },
  });

  const handleNavigations = async () => {
    const navs: NavigationsComponent[] = [];
    const res: { data: NavigationsResponse[] } = await getAllNavigations();
    const { data } = res;
    data.forEach((element: NavigationsResponse) => {
      navs.push({
        label: element.title,
        value: element.navigationId,
        selected: false,
      });
    });
    setNavComponents(navs);
  };

  useEffect(() => {
    handleNavigations();
  }, []);

  const handleUpdatePayload = (navigationId: string) => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;
    const sectionElement = {
      content: null,
      banners: [],
      components: [],
      navigations: [{ navigationId, title: navTitle }],
      shelves: [],
      tipbars: [],
      order: order ? +order : 0,
    };

    switch (position) {
      case 'header':
        if (order) {
          sectionsHeader[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsHeader)) {
          sectionsHeader[0] = sectionElement;
        } else {
          sectionElement.order = sectionsHeader.length;
          sectionsHeader.push(sectionElement);
        }
        break;
      case 'body':
        if (order) {
          sectionsBody[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsBody)) {
          sectionsBody[0] = sectionElement;
        } else {
          sectionElement.order = sectionsBody.length;
          sectionsBody.push(sectionElement);
        }
        break;
      case 'footer':
        if (order) {
          sectionsFooter[order] = sectionElement;
        } else if (verifyIfFirstSectionIsEmpty(sectionsFooter)) {
          sectionsFooter[0] = sectionElement;
        } else {
          sectionElement.order = sectionsFooter.length;
          sectionsFooter.push(sectionElement);
        }
        break;

      default:
        break;
    }

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <S.Container>
        <label className="input-label">Componente de navegação</label>
        <InfraSelect
          name="navigationComponent"
          placeholder="Selecione"
          items={navComponents.map(item => {
            return {
              ...item,
              selected: item.value === formik.values.navigationComponent,
            };
          })}
          handlerClick={e => {
            formik.setFieldValue('navigationComponent', e.value);
            setNavTitle(e.label);
          }}
        />
        <p className="input-error">{formik.errors.navigationComponent}</p>
      </S.Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
        }}
      >
        <S.Button type="submit" iconRight size="medium" icon="Check">
          Salvar
        </S.Button>
      </div>
    </form>
  );
}
