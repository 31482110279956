import * as Yup from 'yup';

export const validationSchema = Yup.object({
  title: Yup.string()
    .required('O Título do componente de navegação deve ser especificado.')
    .max(
      50,
      'O tamanho máximo do título do componente de navegação, deve ser de 50 caracteres, incluindo espaços.',
    ),
});
