import { InfoResponseRequestFileProps } from '../../Pages/CreateMedia';
import { API_UPLOAD_FILES } from '../api';
import { headersCreateMedia } from '../configs';

export type UploadFileResponseError = {
  message: string;
  stack: string;
  statusCode: string;
};

export const postBannerMediaPageUploadFileService = async (
  values: FormData,
) => {
  const result = await API_UPLOAD_FILES.post<
    InfoResponseRequestFileProps | UploadFileResponseError
  >('/files', values, {
    headers: headersCreateMedia,
  })
    .then(response => response.data)
    .catch(error => {
      const { response } = error;
      const { data } = response;
      return data;
    });

  return result;
};

export const deleteBannerMediaPageUploadFileService = async (id: string) => {
  await API_UPLOAD_FILES.delete(`/files/${id}`, {
    headers: headersCreateMedia,
  });
};
