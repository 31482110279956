import { InfraGrid, InfraCol } from '@infralabs/design-system';
import { useFormik } from 'formik';

import { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PageHeader } from '../../../components';
import { AddBanner } from './components/AddBanner';
import { AddHtml } from './components/AddHtml';
import { usePage } from '../../../hooks/usePage';
import { validationSelect } from './validations';
import { elementOptions, positionOptions } from './options';
import * as S from './style';
import { AddNavigation } from './components/AddNavigation';
import { InfraSelect } from '../../../components/design-system';
import { AddTipbar } from './components/AddTipbar';
import { AddShelf } from './components/AddShelf';
import { AddGenericComponent } from './components/AddGenericComponent';

export interface AddNewPageElementParams {
  order: string;
}

export function AddNewPageElement() {
  const history = useHistory();
  const { order } = useParams<AddNewPageElementParams>();
  const { payload, setPayload, element, position, setElement, setPosition } =
    usePage();

  const formik = useFormik({
    initialValues: { element, position },
    validationSchema: validationSelect,
    onSubmit: (e: any) => {
      console.log(e);
    },
  });

  const tag = useMemo(() => {
    const tagName = elementOptions.find(op => op.value === element);
    if (tagName) return tagName.label;
  }, [element]);

  return (
    <S.Container>
      <PageHeader
        title={order ? 'Editar Elemento' : 'Cadastro de Novo Elemento'}
        tag={tag}
      />

      <S.Wrapper>
        <InfraGrid style={{ paddingInline: 0 }}>
          <InfraCol xxl="4" xl="4" lg="4" md="4" xs="4">
            <InfraSelect
              label="Elemento"
              name="element"
              placeholder="Selecione"
              value={formik.values.element}
              items={elementOptions.map(item => {
                return {
                  ...item,
                  selected: item.value === formik.values.element,
                };
              })}
              handlerClick={e => {
                formik.setFieldValue('element', e.value);
                setElement(e.value);
              }}
              error={formik.errors.element}
              disabled={!!order}
            />

            <p className="input-error">{}</p>
          </InfraCol>
          <InfraCol xxl="4" xl="4" lg="4" md="4" xs="4">
            <InfraSelect
              label="Posição"
              name="position"
              placeholder="Selecione"
              items={positionOptions.map(item => {
                return {
                  ...item,
                  selected: item.value === formik.values.position,
                };
              })}
              handlerClick={e => {
                if (order && e.value !== position) {
                  // verify if element exists in old position and remove it
                  const payloadPosition = payload[position].sections;
                  payloadPosition.splice(order, 1);
                  const positionSections = payloadPosition.map((item, i) => {
                    const formattedItem = item;
                    formattedItem.order = i;
                    return formattedItem;
                  });
                  setPayload({
                    ...payload,
                    [position]: { sections: positionSections },
                  });
                  // redirect to create new element page
                  history.replace('/pages/new-element');
                }
                formik.setFieldValue('position', e.value);
                setPosition(e.value);
              }}
              error={formik.errors.position}
            />
          </InfraCol>
        </InfraGrid>
      </S.Wrapper>

      {formik.values.element === 'banner' && <AddBanner />}
      {formik.values.element === 'navigation' && <AddNavigation />}
      {formik.values.element === 'html' && <AddHtml />}
      {formik.values.element === 'tipbar' && <AddTipbar />}
      {formik.values.element === 'content' && <AddHtml />}
      {formik.values.element === 'shelf' && <AddShelf />}
      {formik.values.element === 'generic-component' && <AddGenericComponent />}
    </S.Container>
  );
}
