import axios from 'axios';

export const API_CATALOG = axios.create({
  baseURL: `${window.IFC_VARIABLES.baseURL}/catalog/admin`,
  headers: {
    'X-Tenant-Id': window?.IFC_VARIABLES?.headers?.['x-tenant-id'],
    'X-Channel-Id': process.env.HEADER_CHANNEL_ID,
    'X-Session-Id': process.env.HEADER_SESSION_ID,
    'X-Customer-Id': process.env.HEADER_CUSTOMER_ID,
    'Content-Version': process.env.HEADER_CONTENT_VERSION,
  },
});
