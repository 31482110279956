import { InfraSelect } from '@infralabs/design-system';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import useHome from './useHome';

export default function Home({ locationUrl = '' }) {
  const { channels, setChannel } = useHome();
  const history = useHistory();

  useEffect(() => {
    if (locationUrl !== '') {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  return (
    <div>
      <h1>Configuração de Conteúdo</h1>
      <Styled.Wrapper>
        <Styled.Label>Canal de venda</Styled.Label>
        <InfraSelect
          disable={!channels.length}
          placeholder={
            !channels.length ? 'Nenhum canal de venda encontrado' : 'Selecione'
          }
          items={channels}
          handlerClick={e => {
            setChannel(e.value);
          }}
        />
      </Styled.Wrapper>
    </div>
  );
}
