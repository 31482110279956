import styled from 'styled-components';
import { Spacings, Colors, Typography } from '../../../theme';

export const Container = styled.div`
  .button {
    height: 50px;
    background: ${Colors.neutral_600};
  }
  .table-container {
    margin-top: ${Spacings.spacing_14};
  }
`;

export const Modal = styled.div`
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .icon {
    }
    .description {
      ${Typography.body_large}
      line-height: 22px;
      text-align: center;
      width: 180px;
      margin: 30px;
    }
  }
  .button-modal {
    display: flex;
    gap: 20px;
  }
`;

export const GridFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const GridItem = styled.div<{ width?: string }>`
  width: ${props => (props?.width ? props?.width : '100%')};
`;
