import { InfraSelect as InfraSelectBase } from '@infralabs/design-system';
import { FormikErrors } from 'formik';
import React from 'react';
import * as s from './styles';

type Props = React.HTMLProps<HTMLSelectElement> & {
  label: string;
  name: string;
  items: any[];
  handlerClick: React.EventHandler<any>;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
};

export function InfraSelect({
  label,
  name,
  items,
  handlerClick,
  error,
  disabled = false,
  ...props
}: Props) {
  return (
    <s.Container>
      <label>{label}</label>
      <InfraSelectBase
        name={name}
        items={items}
        handlerClick={handlerClick}
        disable={disabled}
        {...props}
      />
      {error && <span className="error-message">{error}</span>}
    </s.Container>
  );
}
