import { uuid } from '../../Helper/uuid';
import { INavigation } from '../../Pages/Navigation/navigation';
import { API_WITH_VALIDATION_STATAUS as API, configHeaders } from '../api';

interface GetAllgetAllNavigationItems {
  data: INavigation[];
  message?: string;
}

interface GetNavigationItemData {
  data: INavigation;
  message?: string;
}

const removeFrontendFields = (item, level = 0) => {
  if (item.hasOwnProperty('id')) {
    delete item.id;
  }

  if (level === 0 && item.hasOwnProperty('navigationId')) {
    delete item.navigationId;
  }

  if (item?.items) {
    for (const key in item.items) {
      if ({}.hasOwnProperty.call(item.items, key)) {
        item.items[key] = removeFrontendFields(item.items[key], level + 1);
      }
    }
  }

  return item;
};

const formatResponse = data => {
  return {
    ...(data?.data || {}),
    items: data?.data?.items?.map(item => ({
      ...item,
      id: uuid(),
    })),
    id: data?.data?.navigationId,
  } as INavigation;
};

/**
 * NOTE: Para facilitar e manter o controle dos itens no front-end, vamos manipular o retorno da API
 * e o envio inserindo e removendo um atributo com nome 'id', já que ele não existe na API
 */

export const getAllNavigationItems = async (): Promise<INavigation[]> => {
  const { data } = await API.get<GetAllgetAllNavigationItems>('/navigations', {
    headers: configHeaders(),
  });

  if (data?.message) {
    throw data;
  }

  return data.data.map(
    dataItem =>
      ({
        ...dataItem,
        id: dataItem.navigationId,
      } as INavigation),
  );
};

export const getNavigationItem = async (id: string): Promise<INavigation> => {
  const { data } = await API.get<GetNavigationItemData>(`/navigations/${id}`, {
    headers: configHeaders(),
  });
  if (data?.message) {
    throw data;
  }
  return formatResponse(data);
};

export const saveNavigationItem = async (
  id: string,
  dataToSave: INavigation,
): Promise<INavigation> => {
  const { data } = await API.put<GetNavigationItemData>(
    `/navigations/${id}`,
    removeFrontendFields(dataToSave),
    { headers: configHeaders() },
  );

  if (data?.message) {
    throw data;
  }

  return formatResponse(data);
};

export const addNavigationItem = async (
  dataToSave: INavigation,
): Promise<INavigation> => {
  const { data } = await API.post<GetNavigationItemData>(
    `/navigations`,
    removeFrontendFields(dataToSave),
    { headers: configHeaders() },
  );

  if (data?.message) {
    throw data;
  }

  return formatResponse(data);
};

export const deleteNavigationItem = async (id: string): Promise<any> => {
  const { data } = await API.delete(`/navigations/${id}`, {
    headers: configHeaders(),
  });
  return data;
};
