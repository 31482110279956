import styled from 'styled-components';

import { Colors, Shadows, Spacings, Typography } from '../../theme';

export const Wrapper = styled.div`
  margin-bottom: ${Spacings.spacing_40};
`;

export const Heading = styled.div`
  position: relative;
  top: ${Spacings.spacing_10};
  padding: ${Spacings.spacing_16};
  background-color: ${Colors.shade_0};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  font-size: medium;
  font-weight: bold;
`;

export const HeadingContent = styled.div`
  display: flex;
  align-items: center;

  .caret-icons {
    cursor: pointer;
  }

  .plus-icon {
    cursor: pointer;
  }
`;

export const HeaderRight = styled.div``;

export const BodyAccordion = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};

  .not-data {
    text-align: center;

    .info-icon {
      margin-right: ${Spacings.spacing_3};
      margin-bottom: -2px;
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-family: ${Typography.h4.fontFamily};
  font-size: ${Typography.h4.fontSize};
  color: ${Colors.neutral_600};
`;
