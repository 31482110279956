import Accordion from '../../../../components/AdditionalSettings';
import { useNavigationContext } from '../../../../Contexts/NavigationItemContext';

export default function Configs() {
  const [{ configs }, dispatch] = useNavigationContext();

  return (
    <Accordion
      title="Configurações Adicionais"
      tableData={configs}
      setTableData={newConfigs => {
        dispatch({
          type: 'setConfigs',
          payload: newConfigs,
        });
      }}
      hasTitleComponentWithIconPlus="Cadastrar nova configuração"
    />
  );
}
