import * as Yup from 'yup';
import { SectionOption } from '../CreatePage/types';

const REQUIRED = 'Campo obrigatório';

export const validationSelect = Yup.object({
  element: Yup.string().required(REQUIRED),
  position: Yup.string().required(REQUIRED),
});

export function verifyIfFirstSectionIsEmpty(sections: SectionOption[]) {
  const { content, banners, components, navigations, order, shelves, tipbars } =
    sections[0];
  return (
    !content &&
    banners.length === 0 &&
    components.length === 0 &&
    navigations.length === 0 &&
    order === 0 &&
    shelves.length === 0 &&
    tipbars.length === 0
  );
}
