import styled from 'styled-components';

import { Colors, Shadows, Spacings, Typography } from '../../theme';

export interface PlusIconWithTitleProps {
  plusWithTitle: string;
}

export const Wrapper = styled.div`
  margin-bottom: ${Spacings.spacing_40};
`;

export const Heading = styled.div`
  position: relative;
  top: ${Spacings.spacing_10};

  padding: ${Spacings.spacing_16};

  background-color: ${Colors.shade_0};

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};

  font-size: medium;
  font-weight: bold;
`;

export const HeadingContent = styled.div<PlusIconWithTitleProps>`
  display: flex;
  align-items: ${({ plusWithTitle }) => (plusWithTitle ? 'center' : 'none')};

  .caret-icons {
    cursor: pointer;
  }

  .plus-icon {
    margin-right: ${Spacings.spacing_10};
    cursor: pointer;
  }
`;

export const BodyAccordion = styled.div`
  background-color: ${Colors.shade_0};

  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};

  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};

  padding: ${Spacings.spacing_32};

  .not-data {
    text-align: center;

    .info-icon {
      margin-right: ${Spacings.spacing_3};
      margin-bottom: -2px;
    }
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-family: ${Typography.h4.fontFamily};
  font-size: ${Typography.h4.fontSize};
  color: ${Colors.neutral_600};
`;

export const Divider = styled.div`
  margin-top: ${Spacings.spacing_16};
`;

export const ContainerModalConfirmationDelete = styled.div`
  .icon-warning {
    display: flex;
    justify-content: center;
    margin-bottom: ${Spacings.spacing_16};
  }

  .title {
    display: flex;
    text-align: center;
    color: ${Colors.neutral_600};
    margin-bottom: ${Spacings.spacing_14};
  }
`;

export const ContainerButtonsModalDelete = styled.div`
  display: flex;

  .button-cancel {
    margin-right: ${Spacings.spacing_4};
    color: ${Colors.neutral_600};
    border-color: ${Colors.neutral_600};

    :hover {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_400};

      span {
        color: ${Colors.neutral_400};
      }
    }

    :active {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_100};

      span {
        color: ${Colors.neutral_100};
      }
    }

    :focus {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_400};

      span {
        color: ${Colors.neutral_400};
      }
    }

    :disabled {
      background-color: transparent;
      border: ${Spacings.spacing_2} solid ${Colors.neutral_50};

      span {
        color: ${Colors.neutral_50};
      }
    }
  }

  .button-ok {
    margin-left: ${Spacings.spacing_4};
  }
`;
