import React from 'react';
import * as S from './style';

type RadioButtonProps = {
  label: string;
  name: string;
  value: string;
  onChange: any;
  checked?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export default function RadioButton({
  label,
  name,
  value,
  onChange,
  checked,
  onClick,
}: RadioButtonProps) {
  return (
    <S.Item>
      <S.RadioButton
        checked={checked}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
      <S.RadioButtonLabel />
      <S.Label>{label}</S.Label>
    </S.Item>
  );
}
