import { isNumeric } from '../../../../../Helper/isNumeric';

export const MockTypeBanner = [
  {
    label: 'Hero',
    value: 'Hero',
  },
  {
    label: 'Carousel',
    value: 'Carousel',
  },
  {
    label: 'Splitted',
    value: 'Splitted',
  },
  {
    label: 'Column',
    value: 'Column',
  },
];

export const MockInputStyles = {
  name: 'normal',
  bannerType: 'normal',
};

export function getBannerTypeLabel(index: number | string) {
  if (isNumeric(index)) {
    switch (index) {
      case 0:
        return 'Carousel';
      case 1:
        return 'Hero';
      case 2:
        return 'Splitted';
      case 3:
        return 'Column';
    }
  } else return String(index) || '';
}
