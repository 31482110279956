import { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { NavigationProvider } from '../../Contexts/NavigationItemContext';
import DetailsNavigationCompontent from './Details';
import NewNavigationItem from './Details/NewNavigationItem';
import ListNavigationCompontent from './List';

export function NavigationRoutes({ locationUrl = '' }) {
  const history = useHistory();

  useEffect(() => {
    if (locationUrl) {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  return (
    <NavigationProvider>
      <Switch>
        <Route exact path="/navigations" component={ListNavigationCompontent} />
        <Route
          exact
          path="/navigations/:navigationId"
          component={DetailsNavigationCompontent}
        />
        <Route
          exact
          path="/navigations/:navigationId/:itemId"
          component={NewNavigationItem}
        />
      </Switch>
    </NavigationProvider>
  );
}
