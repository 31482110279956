import {
  InfraBreadcrumbs,
  InfraButton,
  InfraCol,
  InfraIcons,
  InfraTable,
  InfraTableColumn,
  InfraTextField,
} from '@infralabs/design-system';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useNavigationContext } from '../../../Contexts/NavigationItemContext';
import { useTenant } from '../../../Contexts/TenantContext';
import debounce from '../../../Helper/debounce';
import {
  deleteNavigationItem,
  getAllNavigationItems,
} from '../../../Services/Navigation';
import ModalAlert, { ModalAlertHandle } from '../components/modalAlert';
import { breadCrumbRouteLevel1 } from '../config';
import { INavigationItem } from '../navigation.d';
import ActionDelete from './actionDelete';
import { Container, Wrapper } from './style';

export default function ListNavigationCompontent() {
  const { channel } = useTenant();
  const history = useHistory();
  const location = useLocation();
  const modalDeleteRef = useRef<ModalAlertHandle>(null);
  const modalAlertRef = useRef<ModalAlertHandle>(null);
  const [navigationList, setNavigationList] = useState<INavigationItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchName, setSearchName] = useState<string>('');
  const [state, dispatch] = useNavigationContext();

  const updateList = async () => {
    setLoading(true);
    let items;
    try {
      items = await getAllNavigationItems();
    } catch (e) {
      if (e?.message) {
        modalAlertRef.current.setContent(e?.message);
        modalAlertRef.current.openModal({
          icon: 'error',
        });
        return;
      }
    }

    setNavigationList(
      items.map(item => ({
        id: item.navigationId,
        title: item.title,
        edit: true,
        delete: true,
      })),
    );
    setLoading(false);
  };
  // ---

  useEffect(() => {
    updateList();
  }, [channel]);

  const debounceSearch = useCallback(
    debounce(inputValue => {
      setSearchName(inputValue);
    }, 400),
    [],
  );

  /**
   * Filtrar lista de itens conforme o formulário de filtro disponível
   *
   * O filtro é feito apenas local, em memória e não está utilizando API pois
   * não existe motivos para chamar a API uma vez que temos aqui todos os dados
   * hoje. Não há paginação ou outro elemento de indique a necessidade de realizar
   * a requisição para filtrar na API. No futuro podemos mudar a funcionalidade
   * para a troca de texto no filtro utilizar a API.
   */
  const filteredList = useMemo(() => {
    return navigationList.filter(component =>
      component.title
        .toLocaleLowerCase()
        .includes(searchName.toLocaleLowerCase()),
    );
  }, [searchName, navigationList]);

  const handleDelete = async (item: INavigationItem) => {
    // Item é deletado e o Modal e fechado.
    const result = await deleteNavigationItem(item?.id);
    modalDeleteRef.current.closeModal();

    if (result?.message) {
      modalAlertRef.current.setContent(result?.message);
      modalAlertRef.current.openModal({
        icon: 'error',
      });
      return;
    }

    modalAlertRef.current.setContent(
      `O componente de navegação "${item?.title}" foi excluído com sucesso.`,
    );
    modalAlertRef.current.openModal({
      icon: 'success',
    });
    updateList();
  };

  return (
    <>
      <Container>
        <InfraBreadcrumbs links={breadCrumbRouteLevel1(history)} />
        <h1>Lista de Componentes de Navegação</h1>
        {loading ? ( // apresentação de loading da table não está satisfatória para essa tela, por isso temos um loading simples neste momento
          <p>Carregando dados....</p>
        ) : (
          <>
            <Wrapper>
              <InfraTextField
                style={{ width: '450px' }}
                autoComplete="off"
                height="small"
                icon="MagnifyingGlass"
                id="1"
                onChange={e => debounceSearch(e.target.value)}
                inputStyle="normal"
                placeholder="Filtrar por título"
                position="start"
                type="text"
              />
              <InfraButton
                icon="Plus"
                iconRight
                size="medium"
                style={{ background: '#1f2c3f' }}
                onClick={() => {
                  history.push('/navigations/add');
                  dispatch({ type: 'clean' });
                }}
              >
                Novo componente de navegação
              </InfraButton>
            </Wrapper>
            <InfraCol xxl="12" className="table">
              <InfraTable
                value={filteredList}
                messageEmptyData={
                  searchName
                    ? 'Nenhum item disponível para essa busca'
                    : 'Nenhum item disponível para apresentação'
                }
              >
                <InfraTableColumn field="title" header="Titulo" />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  field="edit"
                  body={(item: INavigationItem) => (
                    <InfraIcons
                      name="PencilSimple"
                      size={14}
                      onClick={() => history.push(`/navigations/${item.id}`)}
                    />
                  )}
                />
                <InfraTableColumn
                  align="center"
                  padding="checkbox"
                  header=""
                  field="delete"
                  body={(item: INavigationItem) => (
                    <ActionDelete
                      onClick={() => {
                        modalDeleteRef.current.setOperations({
                          onConfirm: async () => {
                            modalDeleteRef.current.setLoading(true);
                            await handleDelete(item);
                            modalDeleteRef.current.closeModal();
                            modalDeleteRef.current.setLoading(false);
                          },
                        });
                        modalDeleteRef.current.openModal({
                          icon: 'info',
                        });
                      }}
                    />
                  )}
                />
              </InfraTable>
            </InfraCol>
          </>
        )}
      </Container>
      <ModalAlert ref={modalDeleteRef} showConfirmButton showCancelButton>
        <span className="title">Você realmente deseja excluir os dados?</span>
      </ModalAlert>
      <ModalAlert
        ref={modalAlertRef}
        showConfirmButton
        textConfirmButton="OK"
      />
    </>
  );
}
