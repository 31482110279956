import styled from 'styled-components';
import { Spacings, Colors, Typography as Ty } from '../../theme';

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  position: relative;
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 14px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid ${Colors.neutral_400};
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: ${Colors.neutral_600};
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
  &:checked + ${Item} {
    background: ${Colors.neutral_600};
    border: 2px solid ${Colors.neutral_600};
  }
  &:checked + ${RadioButtonLabel} {
    background: ${Colors.neutral_600};
    border: 1px solid ${Colors.neutral_600};
    &::after {
      display: block;
      color: white;
      width: 12px;
      height: 12px;
      margin: 4px;
    }
  }
`;

export const Label = styled.p`
  font-size: ${Spacings.spacing_14};
  font-family: ${Ty.h6.fontFamily};
  color: ${Colors.neutral_600};
`;
