import React, { useState } from 'react';

export const ContentContext = React.createContext<any>({});

export const ContentProvider: React.FC = ({ children }) => {
  const [contentState, setContentState] = useState({
    name: '',
    bannerType: '',
    medias: [],
    configs: [],
  });
  return (
    <ContentContext.Provider value={{ contentState, setContentState }}>
      {children}
    </ContentContext.Provider>
  );
};
