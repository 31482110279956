import { InfraButton, InfraModal, InfraIcons } from '@infralabs/design-system';
import { MouseEventHandler } from 'react';
import { Colors } from '../../theme';
import * as style from './style';

type ModalAlertDialogProps = {
  message: string;
  type?: 'success' | 'error' | 'information' | 'warning' | 'neutral' | string;
  buttonTitle?: string;
  onButtonClick?: () => void;
  show: boolean;
  onClose?: MouseEventHandler<HTMLButtonElement>;
};

export default function ModalAlertDialog({
  message,
  type = 'neutral',
  buttonTitle,
  onButtonClick,
  show,
  onClose,
}: ModalAlertDialogProps) {
  function iconName(type: string) {
    switch (type) {
      case 'error':
        return 'XCircle';
      case 'information':
        return 'Info';
      case 'warning':
        return 'Warning';

      default:
        return 'CheckCircle';
    }
  }

  return (
    <style.Modal>
      <InfraModal show={show} onClose={onClose}>
        <div className="modal-content">
          <InfraIcons
            name={iconName(type)}
            color={Colors[`${type}_100`]}
            size={54}
          />
          <p className="description">{message}</p>
        </div>
        <InfraButton
          style={{
            background: Colors[`${type}_100`],
            color: Colors.shade_0,
            borderColor: Colors[`${type}_100`],
          }}
          iconRight
          fullWidth
          size="medium"
          onClick={e => {
            if (onClose) onClose(e);
            if (onButtonClick) onButtonClick();
          }}
        >
          {buttonTitle || 'Ok'}
        </InfraButton>
      </InfraModal>
    </style.Modal>
  );
}
