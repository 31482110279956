import React, {
  Reducer,
  Dispatch,
  PropsWithChildren,
  useReducer,
  createContext,
} from 'react';
import { uuid } from '../Helper/uuid';

export function CreateContext<StateType, ActionType>(
  name: string,
  reducer: Reducer<StateType, ActionType>,
  initialState: StateType,
) {
  const defaultDispatch: Dispatch<ActionType> = () => initialState; // we never actually use this
  const ctx = createContext({
    state: initialState,
    dispatch: defaultDispatch, // just to mock out the dispatch type and make it not optioanl
  });
  ctx.displayName = name;
  function Provider(props: PropsWithChildren<{}>) {
    const [state, dispatch] = useReducer<Reducer<StateType, ActionType>>(
      reducer,
      initialState,
    );
    return <ctx.Provider value={{ state, dispatch }} {...props} />;
  }
  return [ctx, Provider] as const;
}

const ensureAllItensWasID = items => {
  return items.map(item => ({
    ...item,
    id: item.id || uuid(),
    items: ensureAllItensWasID(item.items || []),
  }));
};

export const CreateGenericContext = <T extends {}, I extends {}>(
  name: string,
  rootClass: T,
  itemClass: I,
) => {
  type genericItem = I & { id: string };

  type appState = T & {
    id: string;
    items: genericItem[];
    configs: {
      key: string;
      value: string;
    }[];
  };

  const initialState = {} as appState;

  // type AppState = typeof initialState;

  type Action =
    | { type: 'clean' }
    | { type: 'setData'; payload: appState }
    | { type: 'setConfigs'; payload: appState['configs'] }
    | { type: 'setId'; payload: { id: string } }
    | { type: 'setItems'; payload: appState['items'] }
    | { type: 'addItem'; payload: genericItem }
    | { type: 'updateItem'; payload: genericItem }
    | { type: 'updateDeepTreeItem'; payload: genericItem }
    | { type: 'deleteItem'; payload: { id: string } };

  const sortItemsByOrder = items =>
    items?.sort((a, b) => {
      if (a === b) {
        return 0;
      }

      return a.order > b.order ? 1 : -1;
    });

  function reducer(state: appState, action: Action): appState {
    switch (action.type) {
      case 'clean':
        return initialState;
      case 'setData':
        return {
          ...state,
          ...action.payload,
          items: ensureAllItensWasID(sortItemsByOrder(action.payload.items)),
        };
      case 'setConfigs':
        return {
          ...state,
          configs: action.payload,
        };
      case 'setId':
        return {
          ...state,
          id: action.payload.id,
        };
      case 'addItem':
        return {
          ...state,
          items: ensureAllItensWasID(
            sortItemsByOrder([...(state?.items ?? []), action.payload]),
          ),
        };
      case 'setItems':
        return {
          ...state,
          items: ensureAllItensWasID(sortItemsByOrder(action.payload)),
        };
      case 'updateItem':
        return {
          ...state,
          items: sortItemsByOrder(
            state.items.map(item => {
              if (item.id === action.payload.id) {
                return action.payload;
              }
              return item;
            }),
          ),
        };
      case 'updateDeepTreeItem':
        let updated = false;
        const findUpdateDeepItemById = (item, newItemData) => {
          if (updated) {
            // avoid unnecessary loop for all items
            return item;
          }

          if (item?.id === newItemData?.id) {
            updated = true;
            return {
              ...item,
              ...newItemData,
            };
          }

          if (item?.items?.length) {
            for (const index in item?.items) {
              if ({}.hasOwnProperty.call(item?.items, index)) {
                item.items[index] = findUpdateDeepItemById(
                  item.items[index],
                  newItemData,
                );
              }
            }
          }

          return item;
        };

        return findUpdateDeepItemById({ ...state }, action.payload);

      case 'deleteItem':
        return {
          ...state,
          items: sortItemsByOrder(
            state.items.filter(({ id }) => id !== action.payload.id),
          ),
        };

      default:
        throw new Error();
    }
  }

  const [Ctx, CtxProvider] = CreateContext(name, reducer, initialState); // [ctx, CtxProvider]

  const useCtx = (): [typeof initialState, React.Dispatch<Action>] => {
    const theContext = React.useContext(Ctx);
    if (theContext === undefined)
      throw new Error(`No provider for ${name} context`);
    return [theContext.state, theContext.dispatch];
  };

  return {
    Ctx,
    CtxProvider,
    useCtx,
  };
};
