const Spacings = {
  spacing_less_16: '-1rem',
  spacing_0: '0rem',
  spacing_1: '0.063rem',
  spacing_2: '0.125rem',
  spacing_3: '0.188rem',
  spacing_4: '0.25rem',
  spacing_5: '0.313rem',
  spacing_6: '0.375rem',
  spacing_8: '0.5rem',
  spacing_10: '0.625rem',
  spacing_12: '0.75rem',
  spacing_13: '0.813rem',
  spacing_14: '0.875rem',
  spacing_16: '1rem',
  spacing_18: '1.125rem',
  spacing_19: '1.188rem',
  spacing_20: '1.25rem',
  spacing_22: '1.375rem',
  spacing_24: '1.5rem',
  spacing_25: '1.563rem',
  spacing_28: '1.75rem',
  spacing_30: '1.875rem',
  spacing_32: '2rem',
  spacing_36: '2.25rem',
  spacing_40: '2.5rem',
  spacing_44: '2.75rem',
  spacing_45: '2.813rem',
  spacing_48: '3rem',
  spacing_50: '3.125rem',
  spacing_55: '3.438rem',
  spacing_56: '3.5rem',
  spacing_64: '4rem',
  spacing_70: '4.375rem',
  spacing_80: '5rem',
  spacing_85: '5.313rem',
  spacing_96: '6rem',
  spacing_112: '7rem',
  spacing_115: '7.188rem',
  spacing_128: '8rem',
  spacing_130: '8.125rem',
  spacing_144: '9rem',
  spacing_160: '10rem',
  spacing_176: '11rem',
  spacing_350: '21.875rem',
};

export default Spacings;
