import { forwardRef, useState, useImperativeHandle, useMemo } from 'react';
import { InfraUpload, InfraCircularProgress } from '@infralabs/design-system';
import { postBannerMediaPageUploadFileService } from '../../../../Services/Uploads';
import { ContainerUpload } from './style';
import { WaitMessage } from '../../components/style';
import { kebabcaseForFileName } from '../../../../Helper/kebabcase';

type Props = {
  maxSize: number;
  existentFile?: string;
  onUpload?: (data: any) => void;
};

export type UploadHandle = {
  hasFile: () => boolean;
  uploadFile: () => Promise<{ url: string }>;
};

// type BodyRequest = {
//   canonical: string;
//   context: string;
//   contentType: any;
//   contentLanguage: string;
//   cacheControl: string;
//   file: File;
// }

const createNewFileFromUrl = (url: string) => {
  const fileName = url.split('/').pop();

  const file = new File([fileName], url, {
    type: `image/jpg`,
  });

  // @ts-ignore
  file.uploaded = false;
  // @ts-ignore
  file.progress = 0;
  return file;
};

const Upload = forwardRef<UploadHandle, Props>(
  ({ existentFile, maxSize, onUpload }, ref) => {
    const fileDetailsObj = existentFile
      ? useMemo(() => createNewFileFromUrl(existentFile), [existentFile])
      : false;
    const [filetoUpload, setFileToUpload] = useState<{
      file: File;
      formData: FormData;
    }>(fileDetailsObj ? { file: fileDetailsObj, formData: null } : null);
    const [status, setStatus] = useState<string>('ready');

    const uploadFile = async () => {
      setStatus('uploading');
      let response;
      if (!existentFile || filetoUpload?.file?.name !== existentFile) {
        response = await postBannerMediaPageUploadFileService(
          filetoUpload.formData,
        );
      } else {
        response = {
          data: {
            uri: existentFile,
          },
        };
      }
      if (onUpload) {
        onUpload(response);
      }
      setStatus('ready');

      return response;
    };

    useImperativeHandle(ref, () => ({
      hasFile: () => !!filetoUpload,
      uploadFile,
    }));

    return (
      <ContainerUpload className={`status-${status}`}>
        <WaitMessage className="loading-box">
          <div className="icon-warning">
            <InfraCircularProgress value={54} variant="indeterminate" />
          </div>
          <p>Realizando upload...</p>
        </WaitMessage>
        <InfraUpload
          direction="horizontal"
          uploadMessageDefault="Enviar imagem"
          uploadMessageSuccess="Arraste e solte seu arquivo aqui para realizar o upload"
          uploadMessageReject="Arquivo não suportado"
          uploadMessageRejectMaxFiles="Excedeu a quantidade máxima de arquivos."
          uploadMessageRejectTypeInvalid="Tipo de arquivo inválido"
          description="Apenas 1 arquivo no tamanho máximo de 10MB e nos formatos JPG, JPEG, GIF, APNG, PNG, AVIF, SVG, WEBP."
          filesTypesAccept="image/jpeg, image/png, image/gif, image/avif, image/svg+xml, image/webp"
          onUpload={async ([file]) => {
            const formData = new FormData();

            const bodyRequest = {
              canonical: kebabcaseForFileName(file.name),
              context: 'content',
              contentType: file.type,
              contentLanguage: 'en-us',
              cacheControl: 'no-cache',
              file,
            };

            for (const key in bodyRequest) {
              if (Object.prototype.hasOwnProperty.call(bodyRequest, key)) {
                formData.append(key, bodyRequest[key]);
              }
            }

            file.uploaded = false;
            file.progress = 0;
            setFileToUpload({
              file,
              formData,
            });
          }}
          // Number in bytes of MB
          maxSize={maxSize}
          uploadMessageRejectMaxSize="Tamanho máximo do arquivo de 10MB excedido."
          uploadedList={filetoUpload?.file ? [filetoUpload?.file] : []}
          renderCustomButtonUpload={() => false}
          handleDeleteUploadledFiles={() => {
            setFileToUpload(null);
          }}
        />
      </ContainerUpload>
    );
  },
);

export default Upload;
