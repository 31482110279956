import { InfraButton } from '@infralabs/design-system';
import styled from 'styled-components';

import { Colors, Shadows, Spacings, Typography as Ty } from '../../../../theme';

export const Container = styled.main`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2.5rem 0rem;

  h1 {
    margin: 2.5rem 0rem;
  }

  h2 {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  background-color: ${Colors.shade_0};
  box-shadow: ${Shadows.shadow_md};
  padding: ${Spacings.spacing_40} 0;

  .label-input {
    display: flex;
    flex-direction: column;

    label {
      font-size: ${Spacings.spacing_16};
      font-family: ${Ty.h1.fontFamily};
      padding-right: ${Spacings.spacing_2};
      color: ${Colors.shade_100};
      margin-bottom: ${Spacings.spacing_5};
    }
  }

  .error-message-field {
    font-size: ${Spacings.spacing_12};
    line-height: ${Spacings.spacing_18};
    font-family: ${Ty.h1.fontFamily};
    color: ${Colors.error_300};
  }

  .ds_clear_text_field {
    display: none;
  }
`;

export const Button = styled(InfraButton)`
  /* margin-top: ${Spacings.spacing_28}; */
  background: ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
  }
`;
