import styled from 'styled-components';
import { Spacings, Colors, Typography } from '../../../theme';

interface ContainerModalConfirmationDeleteProps {
  show: boolean;
}

export const ContainerModalConfirmationDelete = styled.div<ContainerModalConfirmationDeleteProps>`
  display: ${props => (props.show ? 'block' : 'none')};

  .icon-warning {
    display: flex;
    justify-content: center;
    margin-bottom: ${Spacings.spacing_16};
  }

  .title {
    display: flex;
    text-align: center;
    color: ${Colors.neutral_600};
    margin-bottom: ${Spacings.spacing_14};
  }
`;

export const AlertMessage = styled.p`
  font-family: ${Typography.body_large.fontFamily};
  font-size: ${Spacings.spacing_16};
  text-align: center;
  margin-bottom: 24px;
  line-height: 22px;
  color: ${Colors.neutral_400};
`;

export const WaitMessage = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;

  .MuiBox-root {
    height: 43px;
    width: 43px;
  }

  .MuiCircularProgress-determinate {
    display: none;
  }

  .MuiCircularProgress-indeterminate {
    margin-left: -20px;
  }
`;

export const ContainerButtonsModalDelete = styled.div`
  display: flex;

  .button-cancel {
    margin-right: ${Spacings.spacing_4};
    color: ${Colors.neutral_600};
    border-color: ${Colors.neutral_600};

    :hover {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_400};

      span {
        color: ${Colors.neutral_400};
      }
    }

    :active {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_100};

      span {
        color: ${Colors.neutral_100};
      }
    }

    :focus {
      border: ${Spacings.spacing_2} solid ${Colors.neutral_400};

      span {
        color: ${Colors.neutral_400};
      }
    }

    :disabled {
      background-color: transparent;
      border: ${Spacings.spacing_2} solid ${Colors.neutral_50};

      span {
        color: ${Colors.neutral_50};
      }
    }
  }

  .button-ok {
    margin-right: ${Spacings.spacing_4};
    color: #fff;
    border-color: ${Colors.success_100};
    background-color: ${Colors.success_100};
    font-size: ${Spacings.spacing_14};
    font-weight: 700;

    :hover {
      border: ${Spacings.spacing_2} solid ${Colors.success_200};
    }

    :disabled {
      background-color: transparent;
      border: ${Spacings.spacing_2} solid ${Colors.neutral_50};

      span {
        color: ${Colors.neutral_50};
      }
    }
  }
`;
