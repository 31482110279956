export const idPage = '2679b1ab-d0f7-4114-8f36-1308ad6c909a';

export const MockTypeBanner = [
  {
    label: 'Hero',
    value: 'Hero',
  },
  {
    label: 'Carousel',
    value: 'Carousel',
  },
  {
    label: 'Splitted',
    value: 'Splitted',
  },
];

export const MockInputStyles = {
  name: 'normal',
  bannerType: 'normal',
};

export const MockPage = {
  title: 'Página não encontrada',
  canonical: 'notfound',
  skus: [],
  brandId: null,
  categoryId: null,
  skuId: null,
  breadcrumbs: [],
  seo: {
    header: 'aasdfsddfgdfgdf',
    description: 'aasdfsdasadsda',
    content: 'lucasaaaaaaaaa',
  },
  header: {
    sections: [
      {
        content: null,
        components: [],
        order: 0,
        banners: [],
        shelves: [],
        tipbars: [
          {
            order: 0,
            title: 'Sales info',
            tipbarType: 1,
            configs: [
              {
                key: 'bgColor',
                value: '#000',
              },
              {
                key: 'fontColor',
                value: '#fff',
              },
            ],
            items: [
              {
                order: 0,
                text: 'FREE STANDARD SHIPPING ON ORDERS OVER $250',
                href: '/department/clothing',
                target: '_self',
              },
              {
                order: 1,
                text: 'NEW ARRIVALS',
                href: '/department/clothing',
                target: '_self',
              },
              {
                order: 2,
                text: 'Sign up for emails and become an AX insider',
                href: '/department/clothing',
                target: '_self',
              },
            ],
          },
        ],
        navigations: [
          {
            navigationId: '1204b0d8-75ff-4af8-bc7e-9bfe72709290',
          },
          {
            navigationId: '6b6a5a22-bc67-4787-bf2c-c74b068e49ea',
          },
        ],
      },
    ],
  },
  configs: [],
  body: {
    sections: [
      {
        order: 0,
        content: null,
        components: [],
        banners: [],
        shelves: [
          {
            shelfType: 1,
            order: 0,
            configs: [
              {
                key: 'arrows',
                value: 'true',
              },
              {
                key: 'autoPlay',
                value: 'true',
              },
              {
                key: 'autoPlaySpeed',
                value: '6000',
              },
              {
                key: 'centerMode',
                value: 'false',
              },
              {
                key: 'infinite',
                value: 'false',
              },
              {
                key: 'showDots',
                value: 'true',
              },
              {
                key: 'slidesToSlide',
                value: 'true',
              },
              {
                key: 'showCollectionName',
                value: 'true',
              },
              {
                key: 'layoutType',
                value: 'true',
              },
            ],
            collectionId: '0dd96137-30e0-4aca-81f6-63fae3152f47',
          },
        ],
        tipbars: [],
        navigations: [],
      },
    ],
  },
  footer: {
    sections: [
      {
        order: 0,
        content: null,
        components: [
          {
            title: 'Formulário de Newsletter',
            type: 'newsletter-widget',
            text: null,
            content: null,
            displayImage: null,
            order: 0,
            configs: [
              {
                key: 'bgColor',
                value: '#fff',
              },
            ],
          },
        ],
        banners: [],
        shelves: [],
        tipbars: [],
        navigations: [
          {
            navigationId: 'caacf57e-c1cb-4da4-9936-7a4fc93b27b9',
          },
          {
            navigationId: '1be93138-e8ba-4521-84c0-1e473af81bac',
          },
          {
            navigationId: '8bcc0152-015e-4a98-aa9a-1e62695a92ea',
          },
          {
            navigationId: 'c8b7bb32-bd8f-4b09-af4b-a42eeb36e38c',
          },
          {
            navigationId: 'c7ea3871-4904-40d8-bb7b-0ff7a81e69ed',
          },
          {
            navigationId: '333c7f9c-97db-41da-b958-1577f986d2a3',
          },
          {
            navigationId: '2a63e2ed-2a0e-4f29-b786-2485e50691da',
          },
        ],
      },
    ],
  },
};
