import styled from 'styled-components';
import { InfraButton } from '@infralabs/design-system';
import { Colors, Spacings } from '../../../theme';

export const Wrapper = styled.div`
  /* display: flex; */
`;

export const SearchHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${Spacings.spacing_16};
`;

export const InputWrapper = styled.div`
  flex: 1;
  margin-right: ${Spacings.spacing_16};
`;

export const Button = styled(InfraButton)`
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;
