import { PageRequestType } from './types';

export const pageInitialValues: PageRequestType = {
  title: '',
  canonical: '',
  brandId: '',
  skus: [],
  categoryId: '',
  skuId: '',
  sellerId: '',
  collectionId: '',
  header: {
    sections: [
      {
        content: null,
        components: [],
        order: 0,
        banners: [],
        shelves: [],
        tipbars: [],
        navigations: [],
      },
    ],
  },
  body: {
    sections: [
      {
        content: null,
        components: [],
        order: 0,
        banners: [],
        shelves: [],
        tipbars: [],
        navigations: [],
      },
    ],
  },
  footer: {
    sections: [
      {
        content: null,
        components: [],
        order: 0,
        banners: [],
        shelves: [],
        tipbars: [],
        navigations: [],
      },
    ],
  },
  seo: {
    header: '',
    description: '',
    content: '',
  },
  configs: [],
};
