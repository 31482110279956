import { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  InfraGrid,
  InfraCol,
  InfraTextField,
  InfraSelect,
  InfraTextarea,
  InfraTypography,
  InfraModal,
  InfraButton,
  InfraIcons,
  InfraUpload,
} from '@infralabs/design-system';
import * as S from './style';
import { Colors } from '../../../../../../theme';
import { usePage } from '../../../../../../hooks/usePage';

type FileProps = {
  path: string;
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

type UploadedFileProps = {
  name: string;
  progress: number;
  type: string;
  uploaded: boolean;
  error: boolean;
  showClose: boolean;
  fileId?: string;
};

export type InfoResponseRequestFileProps = {
  fileId: string;
  canonical: string;
  context: string;
  uri: string;
  metadata: {
    cacheControl: string;
    contentLanguage: string;
    contentType: string;
  };
  createdAt: string;
  updatedAt: string;
};

type modalProps = {
  type: 'success' | 'error';
  status: boolean;
  message: string;
};

export type DataItem = {
  href: string | null;
  target: string | null;
  text: string | null;
  order?: number;
};

interface AddTipbarItemParams {
  order: string;
  itemIndex: string;
}

export function AddTipbarItem() {
  const { order, itemIndex } = useParams<AddTipbarItemParams>();
  const history = useHistory();
  const location = useLocation();
  const { tipbarState, setTipbarState, payload, setPayload, position } =
    usePage();
  const [uploadedFileListVideo, setUploadedFileListVideo] = useState<
    UploadedFileProps[]
  >(() => {
    const { indexEdit } = location.state;
    const item = tipbarState.items[indexEdit];
    if (item) {
      if (item.videoUrl) {
        const fileId = new URL(item.videoUrl).searchParams.get('id');
        return [
          {
            name: item.videoUrl,
            progress: 100,
            type: 'video',
            uploaded: true,
            error: false,
            showClose: true,
            fileId,
          },
        ];
      }
    }
    return [];
  });
  const [infoVideo, setInfoVideo] = useState<InfoResponseRequestFileProps>();
  const [loadingStatusVideo, setLoadingStatusVideo] = useState(false);
  const [disabledUploadVideo, setDisabledUploadVideo] = useState(false);
  const [buttonStatusVideo, setButtonStatusVideo] = useState(false);
  const [showModalSuccessAndError, setShowModalSuccessAndError] =
    useState<modalProps>({
      type: 'success',
      status: false,
      message: '',
    });

  const bodyRequest: Blob | any = {
    canonical: '',
    context: 'content',
    contentType: '',
    contentLanguage: 'en-us',
    cacheControl: 'no-cache',
    file: '',
  };

  const mockItemsSelect = [
    {
      label: '_self',
      value: '_self',
    },
    {
      label: '_blank',
      value: '_blank',
    },
    {
      label: '_parent',
      value: '_parent',
    },
    {
      label: '_top',
      value: '_top',
    },
  ];

  const formRef = useRef(null);

  const validations = Yup.object({
    text: Yup.string()
      .max(
        150,
        'O tamanho máximo do Título, deve ser de 150 caracteres, incluindo espaços',
      )
      .required('O Título deve ser especificado.'),
    href: Yup.string().max(
      500,
      'O tamanho máximo do Link, deve ser de 500 caracteres, incluindo espaços',
    ),
    target: Yup.string().when('href', {
      is: (val: string) => val && val.length > 0,
      then: Yup.string().required('O Target deve ser especificado'),
    }),
  });

  const formik = useFormik({
    initialValues: {
      text: '',
      href: '',
      target: '',
      content: '',
    },
    validationSchema: validations,
    onSubmit: ({ text, href, target }) => {
      const data: DataItem = {
        href: href || null,
        target,
        text: text || null,
      };

      if (data.href === null) data.target = null;

      formik.resetForm({
        values: { text: '', content: '', href: '', target: '' },
      });

      if (location.state) {
        const { indexEdit, isEdit, ...state } = location.state;
        const tableTipbars = tipbarState.items;
        if (tableTipbars.length > 0 && isEdit) {
          const addWithOrder = {
            ...data,
            order: tableTipbars[indexEdit].order,
          };
          tableTipbars[indexEdit] = addWithOrder;

          setTipbarState({
            title: tipbarState.title,
            tipbarType: tipbarState.tipbarType,
            items: tableTipbars,
            configs: tipbarState.configs,
            dateStart: tipbarState.dateStart,
            dateEnd: tipbarState.dateEnd,
          });
          history.goBack();
        } else {
          const { items } = tipbarState;
          data.order = 0;
          items.push(data);
          setTipbarState({
            title: tipbarState.title,
            tipbarType: tipbarState.tipbarType,
            items,
            configs: tipbarState.configs,
            dateStart: tipbarState.dateStart,
            dateEnd: tipbarState.dateEnd,
          });
          history.goBack();
        }
      } else {
        history.goBack();
      }
    },
  });

  const save = (e: any) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const backToLastPage = () => {
    if (location.state) {
      const { isEdit, tableTipbars, ...state } = location.state;

      if (tableTipbars.length > 0 && isEdit) {
        history.goBack();
      } else {
        history.goBack();
      }
    } else {
      history.goBack();
    }
  };

  const setInputStyle = (value: string, type: string) => {
    if (
      (value?.length > 150 && type === 'text') ||
      (value?.length === 0 && type === 'text' && formik.touched.text)
    ) {
      return 'error';
    }
    if (value?.length >= 100 && type === 'href') {
      return 'error';
    }
    return 'normal';
  };

  const handleModalSuccessAndError = ({
    type,
    message,
    status,
  }: modalProps) => {
    setShowModalSuccessAndError({
      type,
      status,
      message,
    });
  };

  const handleUpdatePayload = (type: 'imageUrl' | 'videoUrl') => {
    const sectionsHeader = payload.header.sections;
    const sectionsBody = payload.body.sections;
    const sectionsFooter = payload.footer.sections;

    switch (position) {
      case 'header':
        if (order && itemIndex) {
          sectionsHeader[+order].tipbars[0].items[+itemIndex][type] = null;
        }
        break;
      case 'body':
        if (order && itemIndex) {
          sectionsBody[+order].tipbars[0].items[+itemIndex][type] = null;
        }
        break;
      case 'footer':
        if (order && itemIndex) {
          sectionsFooter[+order].tipbars[0].items[+itemIndex][type] = null;
        }
        break;

      default:
        break;
    }

    setPayload({
      ...payload,
      header: { sections: sectionsHeader },
      body: { sections: sectionsBody },
      footer: { sections: sectionsFooter },
    });
  };

  const setFormikValue = (value: string, input: string) => {
    formik.values[input] = value;
    formik.validateForm();
  };

  useEffect(() => {
    if (formik.values.href?.length === 0) {
      formik.values.target = '';
    }
  }, [formik.values.href, formik.values.target]);

  useEffect(() => {
    if (location?.state) {
      // take values from state
      const { indexEdit, isEdit } = location.state;
      const tableTipbars = tipbarState.items;

      if (tableTipbars.length > 0 && isEdit) {
        // add values came from banner on formik
        formik.values.text = tableTipbars[indexEdit].text || '';
        formik.values.content = tableTipbars[indexEdit].content || '';
        formik.values.target = tableTipbars[indexEdit].target || '';
        formik.values.href = tableTipbars[indexEdit].href || '';
      }
    }
  }, []);

  return (
    <>
      <S.Modal>
        <InfraModal
          theme="light"
          show={
            showModalSuccessAndError.status &&
            showModalSuccessAndError.type === 'success'
          }
          onClose={() =>
            handleModalSuccessAndError({
              type: 'success',
              status: false,
              message: showModalSuccessAndError.message,
            })
          }
        >
          <div className="content">
            <InfraIcons
              name="CheckCircle"
              color={Colors.success_100}
              size={54}
            />
            <p className="description">{showModalSuccessAndError.message}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.success_100,
              color: Colors.shade_0,
              borderColor: Colors.success_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() =>
              handleModalSuccessAndError({
                type: 'success',
                status: false,
                message: showModalSuccessAndError.message,
              })
            }
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>
      <S.Modal>
        <InfraModal
          theme="light"
          show={
            showModalSuccessAndError.status &&
            showModalSuccessAndError.type === 'error'
          }
          onClose={() =>
            handleModalSuccessAndError({
              type: 'error',
              status: false,
              message: showModalSuccessAndError.message,
            })
          }
        >
          <div className="content">
            <InfraIcons name="XCircle" color={Colors.error_100} size={54} />
            <p className="description">{showModalSuccessAndError.message}</p>
          </div>
          <InfraButton
            style={{
              background: Colors.error_100,
              color: Colors.shade_0,
              borderColor: Colors.error_100,
            }}
            iconRight
            fullWidth
            size="medium"
            onClick={() =>
              handleModalSuccessAndError({
                type: 'error',
                status: false,
                message: showModalSuccessAndError.message,
              })
            }
          >
            OK
          </InfraButton>
        </InfraModal>
      </S.Modal>

      <S.Header>
        <InfraTypography variant="h2" weight="bold">
          Cadastro de Item
        </InfraTypography>

        <S.ButtonBack>
          <S.Icon name="ArrowUUpLeft" size={16} onClick={backToLastPage} />
          <p onClick={backToLastPage}>Voltar</p>
        </S.ButtonBack>
      </S.Header>

      <S.Wrapper>
        <InfraGrid style={{ paddingInline: 0 }}>
          <InfraCol xxl="12" xl="12" lg="8" md="8" xs="4">
            <form onSubmit={save} ref={formRef}>
              <InfraGrid>
                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <InfraTextField
                    placeholder="Seu texto"
                    name="text"
                    label="Título"
                    value={formik.values.text}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputStyle={setInputStyle(formik.values.text, 'text')}
                    message={formik.errors.text}
                  />
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <InfraTextField
                    placeholder="Seu texto"
                    name="href"
                    label="Link"
                    value={formik.values.href}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputStyle={setInputStyle(formik.values.href, 'href')}
                    message={formik.errors.href}
                  />
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <div className="label-input">
                    <label>Target</label>
                    <InfraSelect
                      name="target"
                      placeholder={formik.values.target || 'Selecione'}
                      items={mockItemsSelect}
                      handlerClick={event =>
                        setFormikValue(event.value, 'target')
                      }
                      disable={formik.values.href?.length === 0}
                    />
                    <span className="error-target">{formik.errors.target}</span>
                  </div>
                </InfraCol>

                <InfraCol xxl="3" xl="2" lg="2" md="2" xs="2">
                  <S.Button
                    size="large"
                    type="submit"
                    icon="Check"
                    iconRight
                    fullWidth
                    disabled={
                      !(
                        Object.keys(formik.errors).length === 0 &&
                        formik.errors.constructor === Object
                      )
                    }
                  >
                    Salvar
                  </S.Button>
                </InfraCol>
              </InfraGrid>
            </form>
          </InfraCol>
        </InfraGrid>
      </S.Wrapper>
    </>
  );
}
