import { CreateGenericContext } from './GenericCreateContext';
import { INavigation, IItem } from '../Pages/Navigation/navigation.d';

const NavigationsContext = CreateGenericContext(
  'Navigations',
  {} as INavigation,
  {} as IItem,
);

const {
  Ctx: NavigationContext,
  CtxProvider: NavigationProvider,
  useCtx: useNavigationContext,
} = NavigationsContext;

export { NavigationContext, NavigationProvider, useNavigationContext };
