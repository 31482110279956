import styled from 'styled-components';
import { Colors, Spacings } from '../../theme';

export const Container = styled.div`
  .divider {
    height: 1px;
    background-color: ${Colors.neutral_100};
    margin: ${Spacings.spacing_16} 0;
  }
`;
