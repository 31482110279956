import {
  InfraBreadcrumbs,
  InfraButton,
  InfraIcons,
} from '@infralabs/design-system';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useNavigationContext } from '../../../Contexts/NavigationItemContext';
import {
  addNavigationItem,
  getNavigationItem,
  saveNavigationItem,
} from '../../../Services/Navigation';
import { Colors } from '../../../theme';
import ModalAlert, { ModalAlertHandle } from '../components/modalAlert';
import { breadCrumbRouteLevel1 } from '../config';
import Configs from './components/configs';
import Form from './components/form';
import { TreeView } from './components/treeView';
import { BackLink, Container, GroupButtons, Wrapper } from './style';
import { FormHandle, URLParams } from './types.d';

type Status = 'initializing' | 'loadingData' | 'saving' | 'ready';

export default function DetailsNavigationCompontent() {
  const formRef = useRef<FormHandle>(null);
  const modalAlertRef = useRef<ModalAlertHandle>(null);
  const [state, dispatch] = useNavigationContext();
  const { navigationId } = useParams<URLParams>();
  const id = navigationId;
  const isEditing = useMemo(() => {
    return navigationId !== 'add';
  }, [navigationId]);
  const [status, setStatus] = useState<Status>(
    isEditing && !state?.id ? 'initializing' : 'ready',
  );

  const history = useHistory();

  useEffect(() => {
    if (isEditing) {
      if (state.id === navigationId) {
        return;
      }
      dispatch({
        type: 'clean',
      });
      setStatus('loadingData');
      (async () => {
        let navigationItem;
        try {
          navigationItem = await getNavigationItem(navigationId);
        } catch (e) {
          if (e?.message) {
            modalAlertRef.current.setContent(e?.message);
            modalAlertRef.current.openModal({
              icon: 'error',
            });
            return;
          }
        }

        dispatch({
          type: 'setData',
          payload: {
            ...navigationItem,
            id: navigationItem?.navigationId,
          },
        });
        setStatus('ready');
      })();
      return;
    }

    dispatch({
      type: 'setData',
      payload: {
        id: '',
        title: '',
        navigationId: '',
        configs: [],
        items: [],
        ...state,
      },
    });
  }, []);

  const saveNavigation = async () => {
    let result;

    if (!state?.items?.length) {
      modalAlertRef.current.setContent(
        'Você deve inserir ao menos um item no componente de navegação para salvar.',
      );
      modalAlertRef.current.setLoading(false);
      modalAlertRef.current.openModal({
        icon: 'error',
      });
      return;
    }

    setStatus('saving');
    modalAlertRef.current.setContent('');
    modalAlertRef.current.setLoading(true);
    modalAlertRef.current.openModal({
      icon: 'success',
    });

    try {
      if (isEditing) {
        result = await saveNavigationItem(navigationId, state);
        dispatch({
          type: 'setData',
          payload: {
            id: result.id,
            title: result.title,
            navigationId: result.navigation,
            configs: result.configs,
            items: result.items,
          },
        });
      } else {
        result = await addNavigationItem(state);
      }
    } catch (e) {
      result = e;
    }

    modalAlertRef.current.setLoading(false);

    if (result?.message) {
      if (result.message === 'Text names cannot be the same.') {
        modalAlertRef.current.setContent(
          'Itens de mesmo nível não podem ter nomes iguais',
        );
      }
      if (
        result.message.includes(
          'There is already a navigation menu with the title',
        )
      ) {
        modalAlertRef.current.setContent(
          'Já existe um componente de navegação com o mesmo título',
        );
      }
      modalAlertRef.current.openModal({
        icon: 'error',
      });
    } else {
      if (!isEditing) {
        history.push(`/navigations/${result?.id}`);
      }

      modalAlertRef.current.setContent(
        `O componente de navegação "${result?.title}" foi ${
          isEditing ? 'salvo' : 'criado'
        } com sucesso.`,
      );
    }
    setStatus('ready');
  };

  if (status === 'initializing') {
    return null;
  }

  return (
    <>
      <Container className={`details-status-${status}`}>
        <InfraBreadcrumbs
          links={breadCrumbRouteLevel1(history).concat([
            {
              disabled: true,
              href: '#',
              text: `${id === 'add' ? 'Novo Item: ' : 'Item:'} ${
                state?.title ?? ''
              }`,
            },
          ])}
        />
        <h1>Detalhe do Componente de Navegação</h1>
        {status === 'loadingData' ? (
          <p>Carregando dados....</p>
        ) : (
          <>
            <div>
              <BackLink
                href="#"
                onClick={() => {
                  history.push(`/navigations`);
                }}
                className="back-page"
              >
                <InfraIcons
                  name="ArrowUUpLeft"
                  weight="bold"
                  color={Colors.information_100}
                  size={16}
                />
                <span className="text">Voltar</span>
              </BackLink>
            </div>
            <Wrapper>
              <Form ref={formRef} isEditing={isEditing} />
              <GroupButtons>
                <InfraButton
                  icon="Plus"
                  iconRight
                  size="medium"
                  color="neutral"
                  onClick={() => {
                    history.push(`/navigations/${navigationId}/add`);
                  }}
                  style={{ background: '#1f2c3f' }} // TODO: O uso dessa cor inline está errado. Porém a timagem do botão não está preparado para poder usar essa cor, que está declarada como neutral_800. É necessário realizar toda a modificação nas tipagens do DS
                >
                  Adicionar novo item
                </InfraButton>
                <InfraButton
                  icon="Check"
                  iconRight
                  size="medium"
                  color="neutral"
                  style={{ background: '#1f2c3f' }} // TODO: O uso dessa cor inline está errado. Porém a timagem do botão não está preparado para poder usar essa cor, que está declarada como neutral_800. É necessário realizar toda a modificação nas tipagens do DS
                  onClick={async () => {
                    await formRef.current.validateForm();
                    if (formRef.current.isValid()) {
                      saveNavigation();
                    }
                  }}
                >
                  Salvar
                </InfraButton>
              </GroupButtons>
            </Wrapper>
            <TreeView navigationId={navigationId} />
            <Configs />
          </>
        )}
      </Container>
      <ModalAlert
        ref={modalAlertRef}
        showConfirmButton
        textConfirmButton="OK"
      />
    </>
  );
}
