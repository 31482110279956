import styled from 'styled-components';
import { InfraButton } from '@infralabs/design-system';
import { Colors, Spacings, Shadows } from '../../../../../theme';

export const Container = styled.div`
  background-color: ${Colors.shade_0};
  border: ${Spacings.spacing_1} solid ${Colors.neutral_200};
  box-shadow: ${Shadows.shadow_sm};
  border-radius: ${Spacings.spacing_4};
  padding: ${Spacings.spacing_32};
  margin-top: ${Spacings.spacing_32};
  .input-label {
    margin-bottom: 0.25rem;
    font-size: 1rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    line-height: 1.125rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    padding-right: 0.125rem;
    color: #000000;
    padding: 0.188rem;
  }
  .input-error {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, sans-serif;
    color: #e11d2b;
  }
`;

export const Button = styled(InfraButton)`
  background: ${Colors.neutral_600};
  border: ${Spacings.spacing_2} solid ${Colors.neutral_600};

  :hover {
    background: ${Colors.neutral_500};
    border: ${Spacings.spacing_2} solid ${Colors.neutral_600};
  }
`;
