const tentantId =
  localStorage.getItem('@infra-content:tenantId') ||
  process.env.HEADER_TENANT_ID;
const channelId =
  localStorage.getItem('@infra-content:channelId') ||
  process.env.HEADER_CHANNEL_ID;

export const headersCreateMedia = {
  'X-Tenant-Id': tentantId,
  'X-Channel-Id': channelId,
};

export const headersPage = {
  'X-Tenant-Id': '61940ba2e689060011f69be1',
  'X-Channel-Id': 'b6ff3536-cf3e-4e32-9c12-feed42683b3a',
  'X-Session-Id': '132-8988845-8038354',
  'X-Customer-Id': 'Xbffc0a0-7dd6-4c53-ad44-6ae72a96ef4e',
  'Content-Version': 'default',
};
