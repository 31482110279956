export const elementOptions = [
  { label: 'Banner', value: 'banner' },
  { label: 'Conteúdo HTML', value: 'content' },
  { label: 'Componente de Navegação', value: 'navigation' },
  { label: 'Tipbar', value: 'tipbar' },
  { label: 'Prateleira', value: 'shelf' },
  { label: 'Componente Genérico', value: 'generic-component' },
];

export const positionOptions = [
  { label: 'Cabeçalho', value: 'header' },
  { label: 'Corpo', value: 'body' },
  { label: 'Rodapé', value: 'footer' },
];
