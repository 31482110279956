import styled from 'styled-components';

export const Container = styled.main`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2.5rem 0rem;

  h1 {
    margin: 2.5rem 0rem;
  }
`;

export const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;

  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-weight: bold;
    font-size: 14px;
  }
`;
