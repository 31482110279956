import { Router } from 'react-router';
import { I18nextProvider } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import { useEffect } from 'react';
import Routes from '../routes';
import { history } from '../Helper';
// import { LanguageSelect } from '../components/LanguageSelect';
import i18n from '../i18nextInit';
import SpacingDefault from '../components/SpacingDefault';
import { TenantProvider } from '../Contexts/TenantContext';

const GlobalStyle = createGlobalStyle`
    input[type="radio"] + label + p {
      margin-bottom: 0;
    }
`;

export default function App(props) {
  const { basePath } = props;

  return (
    <SpacingDefault>
      <I18nextProvider i18n={i18n}>
        {/* <LanguageSelect /> */}
        <Router history={history}>
          <TenantProvider props={props}>
            <Routes location={basePath.location} basePath={basePath} />
          </TenantProvider>
        </Router>
      </I18nextProvider>
    </SpacingDefault>
  );
}
