import { Switch, Route, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { PageContextProvider } from '../../Contexts/PageContext';
import ListPage from './ListPage';
import { AddNewPageElement } from './AddNewPageElement';
import { AddBannerMedia } from './AddNewPageElement/components/AddBanner/CreateMedia';
import { AddTipbarItem } from './AddNewPageElement/components/AddTipbar/CreateItem';
import CreatePage from './CreatePage';

export function PageRoutes({ locationUrl = '' }) {
  const history = useHistory();

  useEffect(() => {
    if (locationUrl) {
      history.push(locationUrl);
    }
  }, [locationUrl]);

  return (
    <PageContextProvider>
      <Switch>
        <Route exact path="/pages/list" component={ListPage} />
        <Route exact path="/pages/create" component={CreatePage} />
        <Route exact path="/pages/edit/:page_id" component={CreatePage} />
        <Route exact path="/pages/new-element" component={AddNewPageElement} />
        <Route
          exact
          path="/pages/new-element/banner/add-media"
          component={AddBannerMedia}
        />
        <Route
          exact
          path="/pages/new-element/banner/edit-media/:order/:mediaIndex"
          component={AddBannerMedia}
        />
        <Route
          exact
          path="/pages/new-element/tipbar/add-item"
          component={AddTipbarItem}
        />
        <Route
          exact
          path="/pages/new-element/tipbar/edit-item/:order/:mediaIndex"
          component={AddTipbarItem}
        />
        <Route
          exact
          path="/pages/edit-element/:order"
          component={AddNewPageElement}
        />
      </Switch>
    </PageContextProvider>
  );
}
